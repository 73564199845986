import React from "react";
import * as Define from "../../../Define";
import * as HSUtils from "../../../Utils/HSUtils";
import * as API from "../../../API";
import { IJoinState } from "../../../store/Login/Join";
import axios, { AxiosResponse } from "axios";
import Text from "../../Common/Text";
import { useNavigate } from "react-router-dom";

const URL_SIGNUP = Define.URL_API_DASHBOARD + "/login/signup";

const CLASS_DISABLED = "disabled";
const REGEX_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

type JoinFormState = {
    verify_name: boolean,
    verify_id: boolean,
    verify_phone: boolean,
    verify_email: boolean,

    state_id: string,
    state_name: string,
    state_phone: string,
    state_email: string,

    txt_id: string | null,
    txt_name: string | null,
    txt_phone: string | null,
    txt_email: string | null,

    token_phone: string | null,
    token_email: string | null,

    days: number[],
    sel_month: number,
    sel_day: number,
}

class Form extends React.Component<IJoinState, JoinFormState>
{
    constructor(props: IJoinState)
    {
        super(props);
        this.Submit = this.Submit.bind(this);
        this.onMonthChange = this.onMonthChange.bind(this);
        this.checkIDVerify = this.checkIDVerify.bind(this);
        this.checkNameVerify = this.checkNameVerify.bind(this);
        this.checkPhoneVerify = this.checkPhoneVerify.bind(this);
        this.checkPhoneVerifyFinal = this.checkPhoneVerifyFinal.bind(this);
        this.checkEmailVerify = this.checkEmailVerify.bind(this);
        this.checkEmailVerifyFinal = this.checkEmailVerifyFinal.bind(this);

        let email = this.props.Init?.profile.email;
        let name = this.props.Init?.profile.name;
        let picture = this.props.Init?.profile.picture;
        props.Data.email = HSUtils.isEmpty(email) ? null : email!;
        props.Data.name = HSUtils.isEmpty(name) ? null : name!;
        props.Data.picture = HSUtils.isEmpty(picture) ? null : picture!;


        this.state =
        {
            verify_id: props.Init != null,
            verify_name: false,
            verify_phone: false,
            verify_email: false,

            state_id: "중복확인 버튼을 눌러주세요",
            state_name: "유효성 확인 버튼을 눌러주세요",
            state_phone: "휴대폰 인증 버튼을 눌러주세요",
            state_email: "이메일 인증 버튼을 눌러주세요",

            txt_id: null,
            txt_name: null,
            txt_phone: null,
            txt_email: null,

            token_phone: null,
            token_email: null,

            days: [0],
            sel_month: 0,
            sel_day: 0,
        }
    }
    componentDidMount()
    {
        let birth = this.props.Init?.profile.birthday;
        if (birth?.length === 2)
        {
            this.onMonthChange(document.getElementById("sel_birth_month") as HTMLSelectElement, parseInt(birth![0]), parseInt(birth![1]));
        }
    }

    render()
    {
        return this.props == null ? <h1>잘못된 접근입니다!!</h1> : (
            <div id="page_second">
                <h2 className="join_h">회원가입</h2>
                <div id="join_form">
                    <div className="form_line">
                        <p>* 표시는 필수 기입 항목입니다.</p>
                    </div>
                    <div>
                        <input type="hidden" id="recaptcha" />
                        <input type="hidden" id="txt_token" />

                        <input type="hidden" id="id_verify" value={(this.props.Init == null).toString()} />
                        <input type="hidden" id="nick_verify" />
                        <input type="hidden" id="name_verify" />
                    </div>

                    <div className="form_line" id="area_id" style={{ display: this.props.Init == null ? "block" : "none" }}>
                        <label><span>*</span>ID</label>
                        <div style={{ display: "inline-block" }}>
                            <input type="text" id="txt_id" placeholder="아이디" defaultValue={this.props.Data.id!} />
                            <button id="btn_id_verify" onClick={this.checkIDVerify}>중복확인</button>
                            <br />
                            <a id="lbl_id_verify">{this.state.state_id}</a>
                        </div>
                    </div>

                    <div className="form_line" id="area_pw" style={{ display: this.props.Init == null ? "block" : "none" }}>
                        <label><span>*</span>비밀번호</label>
                        <input type="password" id="txt_pw" placeholder="비밀번호" defaultValue={this.props.Data.pw!} />
                    </div>

                    <div className="form_line" id="area_pw_confirm" style={{ display: this.props.Init == null ? "block" : "none" }}>
                        <label><span>*</span>비밀번호 확인</label>
                        <input type="password" id="txt_pw_confirm" placeholder="비밀번호 확인" defaultValue={this.props.Data.pw!} />
                    </div>

                    <div className="form_line">
                        <label><span>*</span>휴대폰 본인인증</label>
                        <div style={{ display: "inline-block" }}>
                            <div>
                                <input id="txt_phone" type="text" placeholder="휴대폰번호('-'포함)" maxLength={13} defaultValue={HSUtils.isEmptyString(this.state.txt_phone) ? "" : this.state.txt_phone!} />
                                <button id="btn_phone_verify" onClick={this.checkPhoneVerify}>{this.state.token_phone == null ? "휴대폰 인증" : "다시 보내기"}</button>
                            </div>
                            <div className={this.state.verify_phone ? CLASS_DISABLED : ""}
                                style={{ marginTop: "15px", display: HSUtils.isEmptyString(this.state.token_phone) ? "none" : "block" }}>

                                <input type="text" id="txt_phone_code" placeholder="인증번호" />
                                <button type="button" id="btn_phone_verify_confirm" onClick={this.checkPhoneVerifyFinal}>인증 확인</button>
                            </div>
                            <a id="lbl_phone_verify"><Text value={this.state.state_phone} single={true} /></a>
                        </div>
                    </div>

                    <div className="form_line" style={{ display: this.props.Init == null ? "block" : "none" }} >
                        <label><span>*</span>이메일</label>
                        <div style={{ display: "inline-block" }}>
                            <div>
                                <input type="email" id="txt_email" placeholder="이메일 주소" defaultValue={HSUtils.isEmptyString(this.props.Data.email) ? "" : this.props.Data.email!} />
                                <button id="btn_email_verify" onClick={this.checkEmailVerify}>{this.state.token_email == null ? "이메일 인증" : "다시 보내기"}</button>
                            </div>
                            <div className={this.state.verify_email ? CLASS_DISABLED : ""}
                                 style={{ marginTop: "15px", display: HSUtils.isEmptyString(this.state.token_email) ? "none" : "block" }}>

                                <input type="text" id="txt_email_code" placeholder="인증번호" />
                                <button type="button" id="btn_phone_verify_confirm" onClick={this.checkEmailVerifyFinal}>인증 확인</button>
                            </div>
                            <a id="lbl_email_verify"><Text value={this.state.state_email} single={true} /></a>
                        </div>
                    </div>

                    <div className="form_line">
                        <label><span>*</span>이름</label>
                        <div style={{ display: "inline-block" }}>
                            <input type="text" id="txt_name" name="name" placeholder="이름" defaultValue={this.props.Data.name!} />
                            <button id="btn_name_verify" onClick={this.checkNameVerify}>
                                유효성 확인
                            </button>
                            <br />
                            <a id="lbl_name_verify"><Text value={this.state.state_name} single={true} /></a>
                        </div>
                    </div>
                    {/*
                    <div className="form_line">
                        <label><span>*</span>닉네임</label>
                        <input type="text" id="txt_nick" name="nick" placeholder="닉네임(별명)" value={this.props.Init?.profile.nick}>
                        <button id="btn_nick_verify">중복 확인</button>
                        <br/>
                        <a id="lbl_nick_verify">중복 확인 버튼을 눌러주세요</a>
                    </div>
                    */}
                    <div className="form_line">
                        <label>생일</label>
                        <select id="sel_birth_month" name="birth-month" defaultValue={this.state.sel_month} onChange={(event) => this.onMonthChange(event.currentTarget)}>
                            <option value='0'>없음</option>
                            <option value='1'>1</option>
                            <option value='2'>2</option>
                            <option value='3'>3</option>
                            <option value='4'>4</option>
                            <option value='5'>5</option>
                            <option value='6'>6</option>
                            <option value='7'>7</option>
                            <option value='8'>8</option>
                            <option value='9'>9</option>
                            <option value='10'>10</option>
                            <option value='11'>11</option>
                            <option value='12'>12</option>
                        </select>
                        <span>월</span>
                        <select name="birth-day" id="sel_birth_day">
                            {
                                this.state.days.map((day: number) =>
                                    <option
                                        selected={this.state.sel_day == day}
                                        value={day}>
                                        {day === 0 ? "없음" : day}
                                    </option>
                                )
                            }
                        </select>
                        <span>일</span>
                    </div>


                    <div id="personal_info">
                        <h3>개인정보 수집 항목</h3>
                        <p>
                            '원미디어'은 (이하 '회사'는) 고객님의 개인정보를 중요시하며,
                            "정보통신망 이용촉진 및 정보보호"에 관한 법률을 준수하고 있습니다.<br />

                            ■ 수집하는 개인정보 항목<br />
                            회사는 상담, 서비스 신청 등등을 위해 아래와 같은 개인정보를 수집하고 있습니다.<br />
                            ο 수집항목 : 이름, 생일<br /><br />

                            ■ 개인정보의 보유 및 이용기간<br />
                            회사는 개인정보 수집 및 이용목적이 달성된 후에도 보관하고 있으며<br />
                            수사기관등의 협조요청이 있을경우 개인정보가 제공될 수 있습니다<br /><br />

                            ■ 회원 탈퇴<br />
                            회원 탈퇴는 관리자 에게 직접 문의 하여야 하며, 회원 탈퇴시 귀하의 개인정보는 지체없이 파기 됩니다.<br />
                            회원 탈퇴시 모든 정보가 즉시 사라지며 이는 복구할 수 없습니다.<br />
                            또한 동일 아이디로 일주일간 회원 가입이 제한 됩니다.<br /><br />

                            보존 항목 : 이름, 생일<br />
                            보존 근거 : 질문에 대한 답변 및 서비스 제공에 관한 상담<br />
                            보존 기간 : 회원 탈퇴시까지<br /><br />
                        </p>
                    </div>
                    <div id="form_btn">
                        <button type="button" id="pre_page_btn" style={{ float: "left" }} onClick={() => this.props.updateShowForm(false)}> 이전</button>
                        <button id="btn_submit" onClick={this.Submit} style={{ float: "right" }}>회원가입</button>
                    </div>
                </div>
            </div>
        );
    }

    onMonthChange(sel_month: HTMLSelectElement, month: number = -1, day: number = -1)
    {
        let sel_day: HTMLSelectElement = document.getElementById("sel_birth_day") as HTMLSelectElement;
        if(month > -1) sel_month.selectedIndex = month;

        day = day > 0 ? day : sel_day.selectedIndex + 1;
        month = month > 0 ? month : sel_month.selectedIndex;
        if (month > 0)
        {
            let max = 29;
            if (month !== 2)
            {
                if (month < 8) max = month % 2 === 0 ? 30 : 31;
                else max = month % 2 === 0 ? 31 : 30;
            }

            let days = [max];
            for (let i = 0; i < max; i++) days[i] = i + 1;

            //31 이면 30일로...
            if(day > max) day = max;
            this.setState({ days: days, sel_month: month, sel_day: day });
        }
        else this.setState({ days: [0] });
    }

    async checkIDVerify()
    {
        let value = (document.getElementById("txt_id") as HTMLInputElement).value;
        if (HSUtils.isEmptyString(value)) alert("아이디를 입력하여 주십시오");
        else
        {
            let msg: string | null = null;
            try {
                this.setState({ state_id: "잠시만 기다려 주십시오..." });

                let response: AxiosResponse = await axios.get(URL_SIGNUP + "?mode=check&key=id&value=" + encodeURIComponent(value));
                let data = response.data as API.APIData;

                if (data.result === API.RESULT_SUCCESS)
                {
                    this.props.Data.id = value;
                    this.setState({ verify_id: true, state_id: "사용 가능한 아이디입니다" });
                }
                else if(data.code === "LOGIN_SHORT_ID" || data.code === "LOGIN_LONG_ID") msg = "아이디는 최소 5자리 이상 20자리 이하여야 합니다";
                else msg = data.message;
            }
            catch (ex: any) { msg = `서버와 통신 중 오류가 발생하였습니다\n(${msg})`; }

            if (msg != null) this.setState({ state_id: msg });
        }
    }
    async checkNameVerify()
    {
        let value = (document.getElementById("txt_name") as HTMLInputElement).value;
        if (HSUtils.isEmptyString(value)) alert("이름을 입력하여 주십시오");
        else
        {
            let msg: string | null = null;
            try
            {
                this.setState({ state_name: "잠시만 기다려 주십시오..." });

                let response: AxiosResponse = await axios.get(URL_SIGNUP + "?mode=check&key=name&value=" + encodeURIComponent(value));
                let data = response.data as API.APIData;

                if (data.result === API.RESULT_SUCCESS)
                {
                    this.props.Data.name = value;
                    this.setState({ txt_name: value, verify_name: true, state_name: "사용 가능한 이름입니다" });
                }
                else msg = `이름 유효성 확인이 실패하였습니다\n(${data.message})`;
            }
            catch (ex: any) { msg = `서버와 통신 중 오류가 발생하였습니다\n(${msg})`; }

            if (msg != null) this.setState({ state_name: msg});
        }
    }
    async checkPhoneVerify()
    {
        let txt_phone = (document.getElementById("txt_phone") as HTMLInputElement).value;
        if (HSUtils.isEmptyString(txt_phone)) alert("휴대폰 번호를 입력하여 주십시오");
        else
        {
            let msg: string | null = null;
            try
            {
                this.setState({ state_phone: "휴대폰 인증 준비 중 입니다..." });

                let response: AxiosResponse = await axios.get(Define.URL_API_DASHBOARD + "/verify/sms?phone=" + encodeURIComponent(txt_phone));
                let api = response.data as API.APIData;

                if (api.result === API.RESULT_SUCCESS)
                {
                    this.setState({ state_phone: "휴대폰으로 전송된 6자리 코드를 입력해 주세요", token_phone : api.data.token });
                    //phone_timer_id = setInterval(PhoneVerifyCountdown(), 1000);
                }
                else msg = `휴대폰 인증이 실패하였습니다\n(${api.message})`;
            }
            catch (ex: any) { msg = `서버와 통신 중 오류가 발생하였습니다\n(${msg})`; }

            if (msg != null) this.setState({ state_phone: msg });
        }
    }
    async checkPhoneVerifyFinal()
    {
        let txt_phone_code = (document.getElementById("txt_phone_code") as HTMLInputElement).value;
        if (HSUtils.isEmptyString(txt_phone_code) || txt_phone_code.length != 6) alert("6자리 코드를 입력해 주세요");
        else
        {
            let msg: string | null = null;
            try
            {
                this.setState({ state_phone: "코드 확인중 입니다..." });

                let response: AxiosResponse = await axios.get(Define.URL_API_DASHBOARD + `/verify/sms?mode=verify&code=${txt_phone_code}&token=${this.state.token_phone}`);
                let data = response.data as API.APIData;

                if (data.result === API.RESULT_SUCCESS)
                {
                    this.props.Data.phone = data.data.token;
                    this.setState({ txt_phone: data.data.phone, verify_phone: true, state_phone: "인증되었습니다" })
                }
                else msg = data.message;
            }
            catch (ex: any) { msg = `서버와 통신 중 오류가 발생하였습니다\n(${msg})`; }

            if (msg != null) this.setState({ state_phone: msg });
        }
    }
    async checkEmailVerify()
    {
        let txt_email = (document.getElementById("txt_email") as HTMLInputElement).value;
        if (HSUtils.isEmptyString(txt_email)) alert("이메일 주소를 입력하여 주십시오");
        else if (!HSUtils.checkEmail(txt_email)) alert("이메일 형식에 맞지 않습니다");
        else
        {
            let msg: string | null = null;
            try
            {
                this.setState({ state_email: "이메일 인증 준비 중 입니다..." });

                let response: AxiosResponse = await axios.get(Define.URL_API_DASHBOARD + "/verify/email?email=" + encodeURIComponent(txt_email));
                let api = response.data as API.APIData;

                if (api.result === API.RESULT_SUCCESS)
                {
                    //TODO: 토큰 가져오기
                    this.setState({ state_email: "이메일로 전송된 6자리 코드를 입력해 주세요", token_email : api.data.token });
                    //email_timer_id = setInterval(EmailVerifyCountdown(), 1000);
                }
                else msg = `이메일 인증이 실패하였습니다\n(${api.message})`;
            }
            catch (ex: any) { msg = `서버와 통신 중 오류가 발생하였습니다\n(${msg})`; }

            if (msg != null) this.setState({ state_email: msg });
        }
    }
    async checkEmailVerifyFinal()
    {
        let txt_email_code = (document.getElementById("txt_email_code") as HTMLInputElement).value;
        if (HSUtils.isEmptyString(txt_email_code) || txt_email_code.length != 6) alert("6자리 코드를 입력해 주세요");
        else
        {
            let msg: string | null = null;
            try
            {
                this.setState({ state_email: "코드 확인중 입니다..." });

                let response: AxiosResponse = await axios.get(Define.URL_API_DASHBOARD + `/verify/email?mode=verify&code=${txt_email_code}&token=${this.state.token_email}`);
                let data = response.data as API.APIData;

                if (data.result === API.RESULT_SUCCESS)
                {
                    this.props.Data.email = data.data.token;
                    this.setState({ txt_email: data.data.email, verify_email: true, state_email: "인증되었습니다" })
                }
                else msg = data.message;
            }
            catch (ex: any) { msg = `서버와 통신 중 오류가 발생하였습니다\n(${msg})`; }

            if (msg != null) this.setState({ state_email: msg });
        }
    }

    async Submit()
    {
        //일반 회원가입일때...
        if (this.props.Init == null)
        {
            let password = (document.getElementById("txt_pw") as HTMLInputElement).value;
            let password_confirm = (document.getElementById("txt_pw_confirm") as HTMLInputElement).value;

            if (!this.state.verify_id) alert("ID 확인을 해주십시오");

            else if (HSUtils.isEmptyString(password)) alert("비밀번호가 비어있습니다");
            else if (HSUtils.isEmptyString(password.match(Define.POLICY_PW))) alert("비밀번호는 최소 8자이상에 숫자, 문자, 특수문자가 모두 포함되야 합니다 ");
            else if (password !== password_confirm) alert("비밀번호와 비밀번호 확인이 서로 일치하지 않습니다");
            else this.props.Data.pw = password;
        }

        //각종 유효 값 검사
        if (!this.state.verify_name) alert("이름 유효성 확인을 해주세요");
        else if (!this.state.verify_phone) alert("휴대폰 인증을 해주세요");
        else
        {
            let email = (document.getElementById("txt_email") as HTMLInputElement).value;
            if (REGEX_EMAIL.test(email)) this.props.Data.email = email; 
            else { alert("이메일이 형식에 맞지 않습니다"); return; }

            let sel_month = (document.getElementById("sel_birth_month") as HTMLSelectElement)?.selectedIndex;
            let sel_day = (document.getElementById("sel_birth_day") as HTMLSelectElement)?.selectedIndex;
            if(sel_month > 0 && sel_day > 0) this.props.Data.birthday = `${sel_month < 10 ? "0" + sel_month : sel_month}${sel_day < 10 ? "0" + sel_day : sel_day}`;

            //회원가입 절차 진행
            this.props.showLoading(true, "회원가입 중 입니다...");
            try
            {
                let form = new FormData();
                let data = this.props.Data;
                if (!HSUtils.isEmptyString(data.token)) form.set("token", data.token!);
                if (!HSUtils.isEmptyString(data.recaptcha)) form.set("recaptcha", data.birthday!);
                if (!HSUtils.isEmptyString(data.id)) form.set("id", data.id!);
                if (!HSUtils.isEmptyString(data.pw)) form.set("pw", data.pw!);
                if (!HSUtils.isEmptyString(data.email)) form.set("email", data.email!);
                if (!HSUtils.isEmptyString(data.name)) form.set("name", data.name!);
                if (!HSUtils.isEmptyString(data.phone)) form.set("phone", data.phone!);
                if (!HSUtils.isEmptyString(data.birthday)) form.set("birthday", data.birthday!);
                if (!HSUtils.isEmptyString(data.picture)) form.set("picture", data.picture!);

                let response: AxiosResponse = await axios.post(URL_SIGNUP, form);
                let api = response.data as API.APIData;

                if (api.result === "success")
                {
                    alert("저희의 멤버가 되신것을 축하드립니다.");
                    //로그인 후 메인화면으로 이동
                    this.props.navigate!(Define.URL_FRONT_MAIN, {replace: true});
                }
                else
                {
                    if (api.code === "LOGIN_INVALID_EMAIL") alert("이메일 인증 오류입니다");
                    else alert(api.message);
                }
            }
            catch (ex: any) { alert(`서버와 통신 중 오류가 발생하였습니다\n(${ex.message})`); }

            this.props.showLoading(false);
        }
    }
}

export default (props: IJoinState) => (<Form {...props} navigate={useNavigate()}/>);