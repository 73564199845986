import React from "react";
import axios from "axios";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import "./Login.css";
import * as HSUtils from "../../Utils/HSUtils";
import * as Define from '../../Define';
import * as API from '../../API';
import * as PATH from '../../PATH';
import * as Environment from '../../Environment';
import * as Login from "../../store/Login/Login";
import Loading from "../Common/Loading";
import { LoadingSetting } from "../../store/Common/Loading";
import GoogleLogin from 'react-google-login';
import KakaoLogin from "react-kakao-login";
import NaverLogin from "./OAuth/NaverLogin";

const POPUP: boolean = true;

type LoginState = {
    Loading: LoadingSetting,
    Redirect: string | null,
}

type LoginProps = {
    Navigate: NavigateFunction
}

declare const window: any;
class LoginComponent extends React.Component<LoginProps, LoginState>
{
    constructor(props: LoginProps)
    {
        super(props)
        this.showLoading = this.showLoading.bind(this);

        let state: LoginState = {
            Loading: { show: false, message: null },
            Redirect: Login.getRedirect(),
        }

        //카카오 소셜로그인 초기화
        try
        {
            let Kakao = window.Kakao;
            if (HSUtils.isEmpty(Kakao)) console.error("카카오 소셜로그인 모듈을 불러오지 못했습니다");
            else
            {
                if (!Kakao.isInitialized()) Kakao.init(Environment.API_KEY_KAKAO_CLIENT);
            }
        }
        catch (ex: any) { console.error("카카오 소셜로그인 모듈 초기화 실패!!\n" + ex.message); }

        this.state = state;
    }

    async componentDidMount()
    {
        this.showLoading(true, "로그인 확인중입니다\n잠시만 기다려 주세요...");

        try
        {
            //유저가 로그인되있는지 확인
            if (await API.checkLogin()) await Login.LoginSuccess(this.props.Navigate, this.state.Redirect);
            else this.showLoading(false);
        }
        catch (ex: any) { Login.alertError(ex.message, "로그인 확인 중 "); }
    }

    render()
    {
        //console.log(this.state.Loading.message)
        return (
            <div id={Login.DIV_ROOT} >
                {Loading(this.state.Loading)}

                <div className="login_box">
                    <h1>원미디어 대시보드 로그인</h1>
                    <form id="form_login" onSubmit={(event) => this.LoginNormal(event)}>
                        <input type="hidden" name="user_method" value="basic" />
                        <input type="text" id="txt_id" name="id" placeholder="아이디" />
                        <input type="password" id="txt_pw" name="pw" placeholder="비밀번호" />

                        <div id="click_box">
                            <input type="submit" value="로그인" className="btn_img" />
                            <Link to={PATH.Join} id="join_button">회원가입</Link>
                        </div>

                        {/* 폼 내 어디에서나 엔터키치면 넘어감*/}
                        <input type="image" src="/resources/login/images/1px.png" />
                    </form>
                    <div id="find_btn_box">
                        <Link to={PATH.FindID} id="findid_button">아이디 찾기</Link>
                        <Link to={PATH.FindPW} id="findpw_button">비밀번호 찾기</Link>
                    </div>
                    <h3>소셜 계정으로 간편 로그인</h3>

                    <GoogleLogin
                        clientId={Environment.API_KEY_GOOGLE_CLIENT}
                        responseType={"id_token"}
                        className={"googleSignIn"}
                        onSuccess={async (response: any) => { await this.LoginOAuth(Login.KIND_GOOGLE, response.tokenId); }}
                        buttonText="구글 로그인 (추천)"
                        onFailure={(fail: any) =>
                        {
                            if (fail.error !== "popup_closed_by_user")
                            {
                                console.log(fail);
                                Login.alertError(fail.message, "구글계정으로 로그인 중 ");
                            }
                        }} />
                    {/*<button id="googleSignIn"><img src="/resources/images/google-logo.png"/><span></span></button>*/}

                    <div className="sns_login">
                        <KakaoLogin
                            token={Environment.API_KEY_KAKAO_CLIENT}
                            onSuccess={({ response }) => this.LoginOAuth(Login.KIND_KAKAO, response.access_token, response.token_type, response.refresh_token)}
                            onFail={({ error_description }) => console.log(error_description)}
                            render={({ onClick }) => {
                                return (
                                    <button id="kakaoSignIn" onClick={onClick}>
                                        <img src="/resources/login/images/kakao-icon.png" />
                                        <span>카카오</span>
                                    </button>
                                );
                            }}
                        />
                        {/* !this.state.InitKakao ? null : 
                            <button id="kakaoSignIn" onClick={this.kakaoLogin}>
                                <img src="/resources/login/images/kakao-icon.png" />
                                <span>카카오</span>
                            </button>
                        */}

                        <NaverLogin
                            Navigate={this.props.Navigate}
                            popup={POPUP}
                            onSuccess={async (naverLogin: any) =>
                            {
                                this.showLoading(true, "네이버 로그인 중입니다...");
                                await Login.checkNaver(this.props.Navigate!, naverLogin);
                                this.showLoading(false);
                            }}
                            onFail={() => console.error} />

                        {/* <button id="facebookSignIn">페이스북 로그인</button>*/}
                    </div>
                </div>
            </div>
        );
    }

    showLoading(isShow: boolean, message: string | null = null) { this.setState({ Loading: { show: isShow, message: message } }) }

    async LoginNormal(event: React.FormEvent<HTMLFormElement>)
    {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);

        if (HSUtils.isEmptyString(formData.get("id")?.toString())) alert("아이디를 입력해 주십시오");
        else if (HSUtils.isEmptyString(formData.get("pw")?.toString())) alert("비밀번호를 입력해 주십시오");
        else
        {
            this.showLoading(true, "로그인 중입니다...");

            try
            {
                let response = await axios({
                    method: 'post',
                    url: Define.URL_API_DASHBOARD + "/login/login",
                    data: formData,
                    headers: { 'Content-Type': 'multipart/form-data' }
                    //headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                });

                if (response.data.result === API.RESULT_SUCCESS) { await Login.LoginSuccess(this.props.Navigate, this.state.Redirect); return; }
                else if (response.data.code === "LOGIN_INVALID_ACCOUNT") alert("아이디 또는 비밀번호가 일치하지 않습니다");
                else Login.alertError(response.data.message, "로그인 중 ");
            }
            catch (ex: any) { Login.alertError(ex.message, "로그인 중 "); }

            this.showLoading(false);
        }
    }
    async LoginOAuth(Kind: string, Token: string, TokenType: string | null = null, RefreshToken: string | null = null)
    {
        this.showLoading(true, "로그인 중입니다...");
        if(await Login.LoginOAuth(this.props.Navigate!, Kind, Token, TokenType, RefreshToken)) return;
        this.showLoading(false);
    }
}

export default () => (<LoginComponent Navigate={useNavigate()}/>);