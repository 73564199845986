const Visitors = () => (
    <div className="container-xl">
        <div className="col-md-6">
            <div className="card">
                <div className="card-header">

                    <h3 className="card-title">방문자 통계</h3>

                </div>
                <div className="card-body" style={{ position: "relative" }}>
                    <div id="chart-completion-tasks-4" style={{ minHeight: "240px" }}>
                        <div id="apexchartsg9ikvu84"
                            className="apexcharts-canvas apexchartsg9ikvu84 apexcharts-theme-light"
                            style={{ width: "379px", height: "240px" }}>
                            <svg id="SvgjsSvg4084" width="379" height="240" xmlns="http://www.w3.org/2000/svg"
                                version="1.1"
                                xmlnsXlink="http://www.w3.org/1999/xlink" data-xmlnsSvgjs="http://svgjs.dev"
                                className="apexcharts-svg apexcharts-zoomable hovering-zoom"
                                data-xmlnsData="ApexChartsNS"
                                transform="translate(0, 0)" style={{ background: "transparent" }}>
                                <g id="SvgjsG4086" className="apexcharts-inner apexcharts-graphical"
                                    transform="translate(31.5625, 10)">
                                    <defs id="SvgjsDefs4085">
                                        <clipPath id="gridRectMaskg9ikvu84">
                                            <rect id="SvgjsRect4096" width="353.4375" height="203" x="-3" y="-1"
                                                rx="0"
                                                ry="0" opacity="1" stroke-width="0" stroke="none"
                                                stroke-dasharray="0"
                                                fill="#fff" />
                                        </clipPath>
                                        <clipPath id="forecastMaskg9ikvu84" />
                                        <clipPath id="nonForecastMaskg9ikvu84" />
                                        <clipPath id="gridRectMarkerMaskg9ikvu84">
                                            <rect id="SvgjsRect4097" width="351.4375" height="205" x="-2" y="-2"
                                                rx="0"
                                                ry="0" opacity="1" stroke-width="0" stroke="none"
                                                stroke-dasharray="0"
                                                fill="#fff" />
                                        </clipPath>
                                    </defs>
                                    <line id="SvgjsLine4092" x1="289.03125" y1="0" x2="289.03125" y2="201"
                                        stroke="#b6b6b6"
                                        stroke-dasharray="3" stroke-linecap="butt"
                                        className="apexcharts-xcrosshairs"
                                        x="289.03125" y="0" width="1" height="201" fill="#b1b9c4" filter="none"
                                        fill-opacity="0.9" stroke-width="1" />
                                    <g id="SvgjsG4103" className="apexcharts-xaxis" transform="translate(0, 0)">
                                        <g id="SvgjsG4104" className="apexcharts-xaxis-texts-g"
                                            transform="translate(0, -4)">
                                            <text id="SvgjsText4106" font-family="inherit" x="0" y="230"
                                                text-anchor="middle" dominant-baseline="auto" font-size="12px"
                                                font-weight="400" fill="#373d3f"
                                                className="apexcharts-text apexcharts-xaxis-label "
                                                style={{ fontFamily: "inherit" }}>
                                                <tspan id="SvgjsTspan4107">21 Jun</tspan>
                                                <title>21 Jun</title></text>
                                            <text id="SvgjsText4109" font-family="inherit" x="57.90625" y="230"
                                                text-anchor="middle" dominant-baseline="auto" font-size="12px"
                                                font-weight="400" fill="#373d3f"
                                                className="apexcharts-text apexcharts-xaxis-label "
                                                style={{ fontFamily: "inherit" }}>
                                                <tspan id="SvgjsTspan4110">22 Jun</tspan>
                                                <title>22 Jun</title></text>
                                            <text id="SvgjsText4112" font-family="inherit" x="115.8125" y="230"
                                                text-anchor="middle" dominant-baseline="auto" font-size="12px"
                                                font-weight="400" fill="#373d3f"
                                                className="apexcharts-text apexcharts-xaxis-label "
                                                style={{ fontFamily: "inherit" }}>
                                                <tspan id="SvgjsTspan4113">23 Jun</tspan>
                                                <title>23 Jun</title></text>
                                            <text id="SvgjsText4115" font-family="inherit" x="173.71875" y="230"
                                                text-anchor="middle" dominant-baseline="auto" font-size="12px"
                                                font-weight="400" fill="#373d3f"
                                                className="apexcharts-text apexcharts-xaxis-label "
                                                style={{ fontFamily: "inherit" }}>
                                                <tspan id="SvgjsTspan4116">24 Jun</tspan>
                                                <title>24 Jun</title></text>
                                            <text id="SvgjsText4118" font-family="inherit" x="231.625" y="230"
                                                text-anchor="middle" dominant-baseline="auto" font-size="12px"
                                                font-weight="400" fill="#373d3f"
                                                className="apexcharts-text apexcharts-xaxis-label "
                                                style={{ fontFamily: "inherit" }}>
                                                <tspan id="SvgjsTspan4119">25 Jun</tspan>
                                                <title>25 Jun</title></text>
                                            <text id="SvgjsText4121" font-family="inherit" x="289.53125" y="230"
                                                text-anchor="middle" dominant-baseline="auto" font-size="12px"
                                                font-weight="400" fill="#373d3f"
                                                className="apexcharts-text apexcharts-xaxis-label "
                                                style={{ fontFamily: "inherit" }}>
                                                <tspan id="SvgjsTspan4122">26 Jun</tspan>
                                                <title>26 Jun</title></text>
                                            <text id="SvgjsText4124" font-family="inherit" x="347.4375" y="230"
                                                text-anchor="middle" dominant-baseline="auto" font-size="12px"
                                                font-weight="400" fill="#373d3f"
                                                className="apexcharts-text apexcharts-xaxis-label "
                                                style={{ fontFamily: "inherit" }}>
                                                <tspan id="SvgjsTspan4125"></tspan>
                                                <title></title></text>
                                        </g>
                                        <line id="SvgjsLine4126" x1="0" y1="202" x2="347.4375" y2="202"
                                            stroke="#e0e0e0"
                                            stroke-dasharray="0" stroke-width="1" stroke-linecap="butt" />
                                    </g>
                                    <g id="SvgjsG4141" className="apexcharts-grid">
                                        <g id="SvgjsG4142" className="apexcharts-gridlines-horizontal">
                                            <line id="SvgjsLine4150" x1="0" y1="0" x2="347.4375" y2="0"
                                                stroke="#e0e0e0"
                                                stroke-dasharray="4" stroke-linecap="butt"
                                                className="apexcharts-gridline" />
                                            <line id="SvgjsLine4151" x1="0" y1="40.2" x2="347.4375" y2="40.2"
                                                stroke="#e0e0e0" stroke-dasharray="4" stroke-linecap="butt"
                                                className="apexcharts-gridline" />
                                            <line id="SvgjsLine4152" x1="0" y1="80.4" x2="347.4375" y2="80.4"
                                                stroke="#e0e0e0" stroke-dasharray="4" stroke-linecap="butt"
                                                className="apexcharts-gridline" />
                                            <line id="SvgjsLine4153" x1="0" y1="120.60000000000001" x2="347.4375"
                                                y2="120.60000000000001" stroke="#e0e0e0" stroke-dasharray="4"
                                                stroke-linecap="butt" className="apexcharts-gridline" />
                                            <line id="SvgjsLine4154" x1="0" y1="160.8" x2="347.4375" y2="160.8"
                                                stroke="#e0e0e0" stroke-dasharray="4" stroke-linecap="butt"
                                                className="apexcharts-gridline" />
                                            <line id="SvgjsLine4155" x1="0" y1="201" x2="347.4375" y2="201"
                                                stroke="#e0e0e0"
                                                stroke-dasharray="4" stroke-linecap="butt"
                                                className="apexcharts-gridline" />
                                        </g>
                                        <g id="SvgjsG4143" className="apexcharts-gridlines-vertical" />
                                        <line id="SvgjsLine4144" x1="0" y1="202" x2="0" y2="208" stroke="#e0e0e0"
                                            stroke-dasharray="0" stroke-linecap="butt"
                                            className="apexcharts-xaxis-tick" />
                                        <line id="SvgjsLine4145" x1="57.90625" y1="202" x2="57.90625" y2="208"
                                            stroke="#e0e0e0" stroke-dasharray="0" stroke-linecap="butt"
                                            className="apexcharts-xaxis-tick" />
                                        <line id="SvgjsLine4146" x1="115.8125" y1="202" x2="115.8125" y2="208"
                                            stroke="#e0e0e0" stroke-dasharray="0" stroke-linecap="butt"
                                            className="apexcharts-xaxis-tick" />
                                        <line id="SvgjsLine4147" x1="173.71875" y1="202" x2="173.71875" y2="208"
                                            stroke="#e0e0e0" stroke-dasharray="0" stroke-linecap="butt"
                                            className="apexcharts-xaxis-tick" />
                                        <line id="SvgjsLine4148" x1="231.625" y1="202" x2="231.625" y2="208"
                                            stroke="#e0e0e0" stroke-dasharray="0" stroke-linecap="butt"
                                            className="apexcharts-xaxis-tick" />
                                        <line id="SvgjsLine4149" x1="289.53125" y1="202" x2="289.53125" y2="208"
                                            stroke="#e0e0e0" stroke-dasharray="0" stroke-linecap="butt"
                                            className="apexcharts-xaxis-tick" />
                                        <line id="SvgjsLine4157" x1="0" y1="201" x2="347.4375" y2="201"
                                            stroke="transparent"
                                            stroke-dasharray="0" stroke-linecap="butt" />
                                        <line id="SvgjsLine4156" x1="0" y1="1" x2="0" y2="201" stroke="transparent"
                                            stroke-dasharray="0" stroke-linecap="butt" />
                                    </g>
                                    <g id="SvgjsG4098" className="apexcharts-line-series apexcharts-plot-series">
                                        <g id="SvgjsG4099" className="apexcharts-series"
                                            data-seriesName="Tasksxcompletion"
                                            data-dataLongestSeries="true" data-rel="1" data-dataRealIndex="0">
                                            <path id="SvgjsPath4102"
                                                d="M 0 138.69L 57.90625 174.87L 115.8125 14.069999999999993L 173.71875 94.46999999999998L 231.625 110.55L 289.53125 70.35L 347.4375 168.84"
                                                fill="none" fill-opacity="1" stroke="rgba(32,107,196,1)"
                                                stroke-opacity="1" stroke-linecap="round" stroke-width="2"
                                                stroke-dasharray="0" className="apexcharts-line" data-index="0"
                                                clip-path="url(#gridRectMaskg9ikvu84)"
                                                data-pathTo="M 0 138.69L 57.90625 174.87L 115.8125 14.069999999999993L 173.71875 94.46999999999998L 231.625 110.55L 289.53125 70.35L 347.4375 168.84"
                                                data-pathFrom="M -1 201L -1 201L 57.90625 201L 115.8125 201L 173.71875 201L 231.625 201L 289.53125 201L 347.4375 201" />
                                            <g id="SvgjsG4100" className="apexcharts-series-markers-wrap"
                                                data-dataRealIndex="0">
                                                <g className="apexcharts-series-markers">
                                                    <circle id="SvgjsCircle4163" r="0" cx="289.53125" cy="70.35"
                                                        className="apexcharts-marker wylmlv92e no-pointer-events"
                                                        stroke="#ffffff" fill="#206bc4" fill-opacity="1"
                                                        stroke-width="2" stroke-opacity="0.9"
                                                        default-marker-size="0" />
                                                </g>
                                            </g>
                                        </g>
                                        <g id="SvgjsG4101" className="apexcharts-datalabels" data-dataRealIndex="0" />
                                    </g>
                                    <line id="SvgjsLine4158" x1="0" y1="0" x2="347.4375" y2="0" stroke="#b6b6b6"
                                        stroke-dasharray="0" stroke-width="1" stroke-linecap="butt"
                                        className="apexcharts-ycrosshairs" />
                                    <line id="SvgjsLine4159" x1="0" y1="0" x2="347.4375" y2="0" stroke-dasharray="0"
                                        stroke-width="0" stroke-linecap="butt"
                                        className="apexcharts-ycrosshairs-hidden" />
                                    <g id="SvgjsG4160" className="apexcharts-yaxis-annotations" />
                                    <g id="SvgjsG4161" className="apexcharts-xaxis-annotations" />
                                    <g id="SvgjsG4162" className="apexcharts-point-annotations" />
                                    <rect id="SvgjsRect4164" width="0" height="0" x="0" y="0" rx="0" ry="0"
                                        opacity="1"
                                        stroke-width="0" stroke="none" stroke-dasharray="0" fill="#fefefe"
                                        className="apexcharts-zoom-rect" />
                                    <rect id="SvgjsRect4165" width="0" height="0" x="0" y="0" rx="0" ry="0"
                                        opacity="1"
                                        stroke-width="0" stroke="none" stroke-dasharray="0" fill="#fefefe"
                                        className="apexcharts-selection-rect" />
                                </g>
                                <rect id="SvgjsRect4091" width="0" height="0" x="0" y="0" rx="0" ry="0" opacity="1"
                                    stroke-width="0" stroke="none" stroke-dasharray="0" fill="#fefefe" />
                                <g id="SvgjsG4127" className="apexcharts-yaxis" aria-rel="0"
                                    transform="translate(17.5625, 0)">
                                    <g id="SvgjsG4128" className="apexcharts-yaxis-texts-g">
                                        <text id="SvgjsText4129" font-family="inherit" x="4" y="11.5"
                                            text-anchor="end"
                                            dominant-baseline="auto" font-size="11px" font-weight="400"
                                            fill="#373d3f"
                                            className="apexcharts-text apexcharts-yaxis-label "
                                            style={{ fontFamily: "inherit" }}>
                                            <tspan id="SvgjsTspan4130">500</tspan>
                                            <title>500</title></text>
                                        <text id="SvgjsText4131" font-family="inherit" x="4" y="51.7"
                                            text-anchor="end"
                                            dominant-baseline="auto" font-size="11px" font-weight="400"
                                            fill="#373d3f"
                                            className="apexcharts-text apexcharts-yaxis-label "
                                            style={{ fontFamily: "inherit" }}>
                                            <tspan id="SvgjsTspan4132">400</tspan>
                                            <title>400</title></text>
                                        <text id="SvgjsText4133" font-family="inherit" x="4" y="91.9"
                                            text-anchor="end"
                                            dominant-baseline="auto" font-size="11px" font-weight="400"
                                            fill="#373d3f"
                                            className="apexcharts-text apexcharts-yaxis-label "
                                            style={{ fontFamily: "inherit" }}>
                                            <tspan id="SvgjsTspan4134">300</tspan>
                                            <title>300</title></text>
                                        <text id="SvgjsText4135" font-family="inherit" x="4" y="132.10000000000002"
                                            text-anchor="end" dominant-baseline="auto" font-size="11px"
                                            font-weight="400"
                                            fill="#373d3f" className="apexcharts-text apexcharts-yaxis-label "
                                            style={{ fontFamily: "inherit" }}>
                                            <tspan id="SvgjsTspan4136">200</tspan>
                                            <title>200</title></text>
                                        <text id="SvgjsText4137" font-family="inherit" x="4" y="172.3"
                                            text-anchor="end"
                                            dominant-baseline="auto" font-size="11px" font-weight="400"
                                            fill="#373d3f"
                                            className="apexcharts-text apexcharts-yaxis-label "
                                            style={{ fontFamily: "inherit" }}>
                                            <tspan id="SvgjsTspan4138">100</tspan>
                                            <title>100</title></text>
                                        <text id="SvgjsText4139" font-family="inherit" x="4" y="212.5"
                                            text-anchor="end"
                                            dominant-baseline="auto" font-size="11px" font-weight="400"
                                            fill="#373d3f"
                                            className="apexcharts-text apexcharts-yaxis-label "
                                            style={{ fontFamily: "inherit" }}>
                                            <tspan id="SvgjsTspan4140">0</tspan>
                                            <title>0</title></text>
                                    </g>
                                </g>
                                <g id="SvgjsG4087" className="apexcharts-annotations" />
                            </svg>
                            <div className="apexcharts-legend" style={{ maxHeight: "120px" }} />

                            <div
                                className="apexcharts-yaxistooltip apexcharts-yaxistooltip-0 apexcharts-yaxistooltip-left apexcharts-theme-light">
                                <div className="apexcharts-yaxistooltip-text" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
);
export default Visitors;