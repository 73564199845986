import axios, { AxiosResponse } from "axios"
import {Link, useNavigate} from "react-router-dom";
import {useState, useRef} from "react";
import * as HSUtils from "../../../Utils/HSUtils"
import {confirm} from "../../../Utils/UI"
import * as Define from "../../../Define"
import * as PATH from "../../../PATH"
import * as API from "../../../API"
import {APIData, RESULT_SUCCESS} from "../../../API";
import WarningBadge from "./WarningBadge";
import Loading from "../../Common/Loading";

function FindID()
{
    let navigate = useNavigate();

    const [loading, showLoading] = useState(false);
    const [value, setValue] = useState({
        ids: [],
        isSocial: false,
        email: ""
    });

    const txt_email = useRef<HTMLInputElement>(null);

    async function queryID()
    {
        let email = txt_email.current?.value;
        if(HSUtils.isEmptyString(email)) alert("이메일주소를 입력해 주십시오")
        else
        {
            showLoading(true);
            try
            {
                let response: APIData = (await axios.get(Define.URL_API_DASHBOARD + "/login/find?email=" + email) as AxiosResponse).data;
                if(response.result === RESULT_SUCCESS)
                {
                    let isSocial = true;
                    for(let i = 0; i < response.data.length; i++)
                        if(response.data[i].includes('*')) {isSocial = false; break;}

                    setValue({
                        ids: response.data,
                        isSocial: isSocial,
                        email: email!
                    })
                }
                else alert(response.message);
            }
            catch(ex: any) {  API.checkError(navigate, ex); } //"오류가 발생하였습니다\n\n" + ex.message
            showLoading(false);
        }
    }

    async function findID()
    {
        if(HSUtils.isEmptyString(value.email)) alert("이메일주소를 다시 입력해 주십시오")
        else
        {
            showLoading(true);
            try
            {
                let response: APIData = (await axios.get(Define.URL_API_DASHBOARD + "/login/find?mode=id&email=" + value.email) as AxiosResponse).data;
                if(response.result === RESULT_SUCCESS)
                {
                    confirm("이메일 발송 성공", "이메일을 성공적으로 보냈습니다.\n비밀번호를 찾으러 가시겠습니까?",
                        () => navigate(Define.getAbsPath(PATH.FindPW), {replace: true}),
                        () => navigate(Define.getAbsPath(PATH.Login), {replace: true}));
                }
                else alert(response.message);
            }
            catch(ex: any) { API.checkError(navigate, ex); }
            showLoading(false);
        }
    }

    return (
        <Loading show={loading}>
            <div className="page page-center">
                <div className="container-tight py-4">
                    <div className="text-center mb-4">
                        <img src="/resources/common/images/one-logo.png" alt="원미디어"/>
                    </div>
                    <form className="card card-md" method="get">
                        <div className="card-body">
                            <h2 className="card-title text-center mb-4">원미디어 회원 아이디 찾기</h2>
                            {HSUtils.isEmptyString(value.email) ?
                                <>
                                    <p className="text-muted mb-4">회원가입 시 입력한 이메일 주소를 입력해 주시기 바랍니다.</p>
                                    <div className="mb-3">
                                        <label className="form-label">이메일 주소</label>
                                        <input type="email" id="txt_email" className="form-control" placeholder="이메일 입력" ref={txt_email}/>
                                    </div>
                                </>:
                                <>
                                    <p className="text-muted mb-4">회원님의 아이디는 다음과 같습니다<br/>{value.isSocial ? null : "(보안을 위해 일부는 가려져있습니다)" }</p>
                                    <div>
                                        { value.ids.map((id) => id) } <br/>
                                    </div>
                                </>

                            }
                            <div className="form-footer">
                                {HSUtils.isEmptyString(value.email) ?
                                    <a className="btn btn-primary w-100" onClick={queryID}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <path d="M7 12l5 5l10 -10"/>
                                            <path d="M2 12l5 5m5 -5l5 -5"/>
                                        </svg>
                                        확인
                                    </a>
                                    : (value.isSocial ? null :
                                        <a className="btn btn-success w-100" onClick={findID}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                                 viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                                 stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                <rect x="3" y="5" width="18" height="14" rx="2"/>
                                                <polyline points="3 7 12 13 21 7"/>
                                            </svg>
                                            이메일로 전체 아이디 받기
                                        </a>)
                                }
                            </div>
                        </div>
                        <div className="hr-text">or</div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col"><Link to={PATH.Login} className="btn btn-white w-100">
                                    로그인 창으로
                                </Link></div>
                                <div className="col"><Link to={PATH.FindPW} className="btn btn-white w-100">
                                    비밀번호 찾기
                                </Link></div>
                            </div>
                        </div>
                    </form>

                    <br/>
                    <div className="card">
                        <div className="card-body">
                            {value.ids?.length < 1 ?
                                <WarningBadge Content="확인 버튼을 누르시면 입력하신 정보와 일치하는 아이디를 안내드립니다."/> :
                                value.isSocial ? null : <WarningBadge Content="보안을 위해 아이디 일부만 노출되며, [이메일로 아이디 받기]를 누르시면 해당 이메일로 아이디 목록이 발송됩니다."/>
                            }
                            <WarningBadge Content="구글, 네이버, 카카오 등 소셜로그인 회원은 소셜로그인 플랫폼 이름이 노출됩니다"/>
                        </div>
                    </div>
                </div>
            </div>
        </Loading>
    );
}

export default FindID;