import React from "react";
import * as HSUtils from "../../Utils/HSUtils";
import { LoadingSetting } from "../../store/Common/Loading";
import Text from "./Text";

//TODO: 진행 메세지 아래로 내리기
//TODO: loading_back 스타일시트 여기 컴포넌트로 통합하기 (가능하면)
//TODO: children 이 undefined 가 아닐때에도 (할당됬을때에도) 투명도가 0보다 크면 투명도 조절가능하게 만들기
const Loading: React.FC<LoadingSetting> = ({ show, message, opacity, children }) =>
{
    return (
        children === undefined ?
            <div id="loading_back" style={{ display: show ? "block" : "none", opacity: opacity }}>
                {H4(message)}
            </div>
            :
            <div style={{opacity: opacity}}>
                {show ? H4(message) : children}
            </div>
    )
}

const H4 = (message?: string | null) => (
    <h4>
        <img src="/resources/common/images/loading.gif" alt="로딩중" />
        <Text value={message === undefined ? "잠시만 기다려 주십시오" : message} single={true} />
    </h4>
)

export default Loading;