import React from "react";
import {Link, useNavigate, useParams, useSearchParams, useLocation} from "react-router-dom";
import Title from "./Support/List/Common/Title";
import Header from "./Support/List/Common/Header";
import Pagination from "./Support/List/Common/Page";
import List from "./Support/List/Common/List";
import {PropsExport, Data, getQuery} from "../../store/Board/BoardList";
import * as HSUtils from "../../Utils/HSUtils";


export class Main extends List {
    render() {
        if (HSUtils.isEmpty(this.state.data.list)) {
            return (
                <div className="container-xl">
                    { /* 부모 props를 그대로 전달 (https://www.skovy.dev/blog/using-component-dot-notation-with-typescript-to-create-a-set-of-components?seed=2bpddq) */}
                    <Title {...this.props} />
                </div>
            );
        } else {
            let UPDATE_KEY = Math.random();
            return (
                <div className="page-wrapper" id="page-top-margin">
                    <div className="container-xl">
                        {Title(this.props, this.state)}
                        <div className="accordion card" style={{maxHeight: "17rem"}}>
                            <div className="card-header">
                                <p className="card-title">
                                    총 [N]개의 새소식이 도착했습니다.
                                </p>
                            </div>

                            <div className="card-body card-body-scrollable card-body-scrollable-shadow">
                                <div className="divide-y">
                                    <div className="row">
                                        <div className="col-auto">
                                            <span className="avatar">JL</span>
                                        </div>
                                        <div className="col">
                                            <div className="text-truncate">
                                                공지사항에 새로운 글이 등록되었습니다.
                                            </div>
                                            <div className="text-muted">2시간 전</div>
                                        </div>
                                        <div className="col-auto align-self-center">
                                            <div className="badge bg-primary"/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-auto">
                                            <span className="avatar">JL</span>
                                        </div>
                                        <div className="col">
                                            <div className="text-truncate">
                                                공지사항에 새로운 글이 등록되었습니다.
                                            </div>
                                            <div className="text-muted">2시간 전</div>
                                        </div>
                                        <div className="col-auto align-self-center">
                                            <div className="badge bg-primary"/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-auto">
                                            <span className="avatar">JL</span>
                                        </div>
                                        <div className="col">
                                            <div className="text-truncate">
                                                공지사항에 새로운 글이 등록되었습니다.
                                            </div>
                                            <div className="text-muted">2시간 전</div>
                                        </div>
                                        <div className="col-auto align-self-center">
                                            <div className="badge bg-primary"/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-auto">
                                            <span className="avatar">JL</span>
                                        </div>
                                        <div className="col">
                                            <div className="text-truncate">
                                                공지사항에 새로운 글이 등록되었습니다.
                                            </div>
                                            <div className="text-muted">2시간 전</div>
                                        </div>
                                        <div className="col-auto align-self-center">
                                            <div className="badge bg-primary"/>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-sm-6 col-lg-3">
                                <div className="card card-sm">
                                    <div className="card-body">
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                            <span className="bg-blue text-white avatar">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     className="icon icon-tabler icon-tabler-speakerphone" width="24" height="24"
                                     viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                     strokeLinecap="round" strokeLinejoin="round">
   <desc>Download more icon variants from https://tabler-icons.io/i/speakerphone</desc>
   <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
   <path d="M18 8a3 3 0 0 1 0 6"/>
   <path d="M10 8v11a1 1 0 0 1 -1 1h-1a1 1 0 0 1 -1 -1v-5"/>
   <path
       d="M12 8h0l4.524 -3.77a0.9 .9 0 0 1 1.476 .692v12.156a0.9 .9 0 0 1 -1.476 .692l-4.524 -3.77h-8a1 1 0 0 1 -1 -1v-4a1 1 0 0 1 1 -1h8"/>
</svg>
                            </span>
                                            </div>
                                            <div className="col">
                                                <div className="font-weight-medium">
                                                    공지사항
                                                </div>
                                                <div className="text-muted">
                                                    새로운 소식 12건
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-3">
                                <div className="card card-sm">
                                    <div className="card-body">
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                            <span className="bg-green text-white avatar">
                               <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-headset"
                                    width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                                    fill="none" strokeLinecap="round" strokeLinejoin="round">
   <desc>Download more icon variants from https://tabler-icons.io/i/headset</desc>
   <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
   <rect x="4" y="13" rx="2" width="4" height="6"/>
   <rect x="16" y="13" rx="2" width="4" height="6"/>
   <path d="M4 15v-3a8 8 0 0 1 16 0v3"/>
   <path d="M18 19a6 3 0 0 1 -6 3"/>
</svg>
                            </span>
                                            </div>
                                            <div className="col">
                                                <div className="font-weight-medium">
                                                    1:1 문의
                                                </div>
                                                <div className="text-muted">
                                                    새로운 소식 32건
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-3">
                                <div className="card card-sm">
                                    <div className="card-body">
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                            <span className="bg-orange text-white avatar">
                                 <svg xmlns="http://www.w3.org/2000/svg"
                                      className="icon icon-tabler icon-tabler-layout-navbar" width="24" height="24"
                                      viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                      strokeLinecap="round" strokeLinejoin="round">
   <desc>Download more icon variants from https://tabler-icons.io/i/layout-navbar</desc>
   <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
   <rect x="4" y="4" width="16" height="16" rx="2"/>
   <line x1="4" y1="9" x2="20" y2="9"/>
</svg>
                            </span>

                                            </div>
                                            <div className="col">
                                                <div className="font-weight-medium">
                                                    홈페이지 게시판 관리
                                                </div>
                                                <div className="text-muted">
                                                    새로운 소식 16건
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-3">
                                <div className="card card-sm">
                                    <div className="card-body">
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                            <span className="bg-azure text-white avatar">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                     viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                     strokeLinecap="round" strokeLinejoin="round"><desc>Download more icon variants from https://tabler-icons.io/i/currency-dollar</desc><path
                                    stroke="none" d="M0 0h24v24H0z" fill="none"/><path
                                    d="M16.7 8a3 3 0 0 0 -2.7 -2h-4a3 3 0 0 0 0 6h4a3 3 0 0 1 0 6h-4a3 3 0 0 1 -2.7 -2"/><path
                                    d="M12 3v3m0 12v3"/></svg>
                            </span>
                                            </div>
                                            <div className="col">
                                                <div className="font-weight-medium">
                                                    결제 및 기타사항
                                                </div>
                                                <div className="text-muted">
                                                    새로운 소식 4건
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">방문자 통계</h3>
                                    </div>
                                    <div className="card-body">

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="card" style={{maxHeight: "17rem"}}>
                                    <div className="card-header">
                                        <h3 className="card-title">견적관리</h3>
                                    </div>
                                    <div className="card-table card-body-scrollable">
                                        <table className="table table-vcenter">
                                            <thead>
                                            <tr className="text-center">
                                                <th>상태</th>
                                                <th>제목</th>
                                                <th>날짜</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td className="w-1 text-orange">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         className="icon icon-tabler icon-tabler-checks" width="24"
                                                         height="24" viewBox="0 0 24 24" strokeWidth="2"
                                                         stroke="currentColor" fill="none" strokeLinecap="round"
                                                         strokeLinejoin="round">
                                                        <desc>Download more icon variants from
                                                            https://tabler-icons.io/i/checks
                                                        </desc>
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                        <path d="M7 12l5 5l10 -10"/>
                                                        <path d="M2 12l5 5m5 -5l5 -5"/>
                                                    </svg>
                                                </td>
                                                <td className="td-truncate">
                                                    <div className="text-truncate">
                                                        견적문의 제목입니다
                                                    </div>
                                                </td>
                                                <td className="text-nowrap text-muted">2022년 5월 23일</td>
                                            </tr>
                                            <tr>
                                                <td className="w-1 text-muted">
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         className="icon icon-tabler icon-tabler-checks" width="24"
                                                         height="24" viewBox="0 0 24 24" strokeWidth="2"
                                                         stroke="currentColor" fill="none" strokeLinecap="round"
                                                         strokeLinejoin="round">
                                                        <desc>Download more icon variants from
                                                            https://tabler-icons.io/i/checks
                                                        </desc>
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                        <path d="M7 12l5 5l10 -10"/>
                                                        <path d="M2 12l5 5m5 -5l5 -5"/>
                                                    </svg>
                                                </td>
                                                <td className="td-truncate">
                                                    <div className="text-truncate">
                                                        견적문의 제목입니다
                                                    </div>
                                                </td>
                                                <td className="text-nowrap text-muted">2022년 5월 23일</td>
                                            </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            )
                ;
        }
    }
}

export default (props: PropsExport) => (<Main {...props}
                                              Navigate={useNavigate()}
                                              Param={useParams()}
                                              Location={useLocation()}
                                              Query={useSearchParams()[0]}/>);
