import * as HSUtils from "../Utils/HSUtils";

export default interface FileData {
    name: string,
    length: number,
    type: string,
    //ISO8601
    timestamp: string,
    //Base64
    data: string | ArrayBuffer,
}

// resolve/reject 이거 물건이네 물건이야...
export const getFileToData = (file: File) => new Promise<FileData | null>(((resolve, reject) =>
{
    if(file.size == 0 && file.name == "") resolve(null);
    else
    {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onerror = error => reject(error);
        reader.onload = () =>
        {
            if(reader.result == null) resolve(null);
            else
            {
                resolve(new class implements FileData
                {
                    data: string | ArrayBuffer = reader.result!;
                    name: string = file.name;
                    length: number = file.size;
                    type: string = file.type;
                    //RFC1123
                    timestamp: string = new Date(file.lastModified).toISOString();
                });
            }
        }
    }
}));

export async function processObjectFile(obj: any)
{
    const keys = Object.keys(obj);
    for (let i = 0; i < keys.length; i++)
    {
        const k = keys[i];
        const v = obj[k];
        if(HSUtils.getType(v) == "File") obj[k] = await getFileToData(v);
    }
}