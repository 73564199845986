import React from "react";
import {isEmpty} from "../../../../../Utils/HSUtils";
import {BoardFile} from "../../../../../store/Board/Board";
import * as Define from "../../../../../Define";
import * as PATH from "../../../../../PATH";

export type FileInit = {
    value?: BoardFile[] | null,
}

const ViewFile: React.FC<FileInit> = (init: FileInit) => {
    return (
        <>
            <h3 style={{marginBottom:"10px"}}>첨부파일 ({isEmpty(init.value) ? 0 : init.value!.length})</h3>
            <div>
                {init.value!.map((file) => (
                    <div className="file" key={Math.random()}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-bar-to-down"
                             width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                             fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <desc>Download more icon variants from https://tabler-icons.io/i/arrow-bar-to-down</desc>
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <line x1="4" y1="20" x2="20" y2="20"/>
                            <line x1="12" y1="14" x2="12" y2="4"/>
                            <line x1="12" y1="14" x2="16" y2="10"/>
                            <line x1="12" y1="14" x2="8" y2="10"/>
                        </svg>
                        <a href={`${Define.URL_API}${file.path}?key=${file.key}&error_401=${PATH.Login}`}>{file.name}</a>
                    </div>
                ))}
            </div>
        </>
    )
}

export default ViewFile;