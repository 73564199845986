import React from "react";
import { isEmpty } from "../../../../../Utils/HSUtils";
import {BoardInit} from "../../../../../store/Board/Board";
import {Submit} from "../../../../../store/Board/BoardWrite";
import { useNavigate } from "react-router";

export interface SubmitInit
{
    init: BoardInit,
    value?: HTMLFormElement,
    refValue?: React.RefObject<HTMLFormElement>
}

const FormSubmit: React.FC<SubmitInit> = ({init, value, refValue}) =>
{
    let Navigate = useNavigate();

    if(isEmpty(value) && isEmpty(refValue)) return null;
    else return(
        <div className="col text-end">
            <a className="btn btn-primary" onClick={()=>Submit(Navigate, init, isEmpty(value) ? refValue?.current! : value!)}>
                <svg xmlns="http://www.w3.org/2000/svg"
                     className="icon icon-tabler icon-tabler-send" width="24" height="24"
                     viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                     strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <line x1="10" y1="14" x2="21" y2="3"/>
                    <path
                        d="M21 3l-6.5 18a0.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a0.55 .55 0 0 1 0 -1l18 -6.5"/>
                </svg>
                저장</a>
        </div>
    )
}

export default FormSubmit;