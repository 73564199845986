import axios, {AxiosResponse} from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useState, useRef } from "react";
import * as PATH from "../../../PATH";
import * as HSUtils from "../../../Utils/HSUtils";
import * as Define from "../../../Define";
import * as API from "../../../API";
import {APIData, RESULT_SUCCESS} from "../../../API";
import WarningBadge from "./WarningBadge";
import Loading from "../../Common/Loading";

function FindPW()
{
    let navigate = useNavigate();
    const [loading, showLoading] = useState(false);

    const txt_id = useRef<HTMLInputElement>(null);

    async function findPW()
    {
        let id = txt_id.current?.value;
        if(HSUtils.isEmptyString(id)) alert("아이디를 입력해 주십시오")
        else
        {
            showLoading(true);
            try
            {
                let response: APIData = (await axios.get(Define.URL_API_DASHBOARD + "/login/find?mode=pw&id=" + id) as AxiosResponse).data;
                showLoading(false);
                if(response.result === RESULT_SUCCESS)
                {
                    alert("해당 아이디에 등록된 이메일로 비밀번호 변경 링크를 보냈습니다.");
                    navigate(Define.getAbsPath(PATH.Login), {replace: true});
                }
                else alert(response.message);
            }
            catch(ex: any) { API.checkError(navigate, ex); } //"오류가 발생하였습니다\n\n" + ex.message

            showLoading(false);
        }
    }

    return (
        <Loading show={loading}>
            <div className="page page-center">
                <div className="container-tight py-4">
                    <div className="text-center mb-4">
                        <img src={'/resources/common/images/one-logo.png'} alt="원미디어"/>
                    </div>
                    <form className="card card-md">
                        <div className="card-body">
                            <h2 className="card-title text-center mb-4">원미디어 회원 비밀번호 찾기</h2>
                            <p className="text-muted mb-4">회원가입 시 입력한 아이디를 입력해 주시기 바랍니다.</p>
                            <div className="mb-3">
                                <label className="form-label">아이디</label>
                                <input type="text" className="form-control" placeholder="아이디 입력" ref={txt_id}/>
                            </div>
                            <div className="form-footer">
                                <a className="btn btn-primary w-100" onClick={findPW}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                         viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                         strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <rect x="3" y="5" width="18" height="14" rx="2"/>
                                        <polyline points="3 7 12 13 21 7"/>
                                    </svg>
                                    확인
                                </a>
                            </div>
                        </div>
                        <div className="hr-text">or</div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col"><Link to={PATH.Login} className="btn btn-white w-100">
                                    로그인 창으로
                                </Link></div>
                                <div className="col"><Link to={PATH.FindID} className="btn btn-white w-100">
                                    아이디 찾기
                                </Link></div>
                            </div>
                        </div>
                    </form>
                    <br/>
                    <div className="card">
                        <div className="card-body">
                            <WarningBadge Content="입력하신 아이디에 등록된 이메일로 비밀번호 변경 링크를 안내해드립니다."/>
                        </div>
                    </div>
                </div>
            </div>
        </Loading>
    );
}

export default FindPW;