import * as HSUtils from "../Utils/HSUtils";

export class BrowserStorage<E> {
    private key: string;
    private mapper: BrowserStorageMapper<E>;

    constructor(
        key: string,
        mapper: BrowserStorageMapper<E>
    ) {
        this.key = key;
        this.mapper = mapper;
    }

    get(): E | null
    {
        return this.mapper.fromJson(
            BrowserStorageHelper.get(this.key)
        );
    };

    set(target: E | null, temporary: boolean = false) {
        BrowserStorageHelper.set(
            this.key,
            this.mapper.toJson(target),
            temporary
        );
    }

    clear() {
        BrowserStorageHelper.clear(this.key);
    }
}

export interface BrowserStorageMapper<E> {
    fromJson(json: string | null): E | null
    toJson(target: E | null): any
}

class BrowserStorageHelper
{
    static clear(Key: string): void {
        sessionStorage.removeItem(Key);
        localStorage.removeItem(Key);
    }

    static set(Key: string, Value: string, temporary: boolean): void
    {
        if (temporary) sessionStorage.setItem(Key, Value);
        else localStorage.setItem(Key, Value);
    }

    static get(Key: string): string | null
    {
        let value = sessionStorage.getItem(Key);
        return HSUtils.isEmpty(value) ? localStorage.getItem(Key) : value;
    }
}