import React, { useEffect, useRef } from "react";
import * as Define from "../../../../../../Define";
import * as PATH from "../../../../../../PATH";
import {getFileName, isEmpty} from "../../../../../../Utils/HSUtils";
import {Item} from "../FormFile";

const FileItem: React.FC<Item> = (item: Item) =>
{
    let element = useRef<HTMLLabelElement>(null);
    useEffect(()=>
    {
        if(item.file) element.current?.append(item.file);
    }, [])

    const Render = (item: Item) =>
    {
        let Style = {color:"#555",fontWeight:"500",cursor: "pointer"};
        if(isEmpty(item.fileBoard)) Style.cursor = "default";

        return (
            <label htmlFor="input-file" style={Style} ref={element}>
                {item.fileBoard ? item.fileBoard.name : getFileName(item.file?.value) }
            </label>
        )
    };

    return item.fileBoard ?
        <a style={{display:"inline-block"}} href={`${Define.URL_API}${item.fileBoard.path}?key=${item.fileBoard.key}&error_401=${PATH.Login}`}>{Render(item)}</a> :
        <a style={{display:"inline-block"}}>{Render(item)}</a>
}

export default FileItem;