import {isEmpty, isEmptyString} from "../Utils/HSUtils";
import {URL_API_DOWNLOAD} from "../Define";

export default interface DownloadData {
    key?: string;
    url?: string;
    //Base64
    data?: string;

    name?: string;
}

export function isDownloadable(download?: DownloadData): boolean
{
    if(isEmpty(download)) return false;
    else return !isEmptyString(download!.key) || !isEmptyString(download!.url) || !isEmptyString(download!.data);
}

export function getDownloadURL(download?: DownloadData): string
{
    if(isDownloadable(download))
    {
        if(!isEmptyString(download!.key)) return URL_API_DOWNLOAD + "?key=" + download!.key;
        else if(!isEmptyString(download!.url)) return download!.url!;
        else if(!isEmptyString(download!.data)) return "data:application/octet-stream;base64," + download!.data;
    }

    return "";
}