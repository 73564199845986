import { createStore, applyMiddleware, compose } from "redux";

import axios, { AxiosError, AxiosResponse } from "axios";
import * as Define from "./Define";
import * as API from "./API";

import { MemberData } from "./Struct/MemberData";

const initalState =
{
    /*
    member =
    {
        id:'',
        name: ''
    }
    */
};

export const rootReducer = (State: any) => createStore(async (state = State, action) =>
{
    return State;
},
    applyMiddleware(
        
    ))