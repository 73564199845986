import React from "react";
import PropTypes from 'prop-types';
import * as HSUtils from '../../Utils/HSUtils';

type TextData = {
    value: string | null,
    single: boolean | undefined,
};

const Text: React.FC<TextData> = ({ value, single }) =>
{
    value = HSUtils.isEmptyString(value) ? "잠시만 기다려 주십시오..." : value;
    return single ? <a style={{ whiteSpace: 'pre-wrap' }}>{value}</a> :
    (
        <div style={{ display: "grid" }}>
            {value?.split("\n").map((line) => <a>{line}</a>)}
        </div>
    );
}
export default Text;

Text.prototype = {
    value: PropTypes.string.isRequired,
    single: PropTypes.bool
}

Text.defaultProps = {
    single: true
}