import { IJoinState } from "../../../store/Login/Join";
import * as HSUtils from "../../../Utils/HSUtils";
import * as Define from "../../../Define";
import * as Enviroment from "../../../Environment";
import ReCAPTCHA from "reaptcha";

const JoinAgree = (state: IJoinState) =>
(
    <div id="page_first">
        <h2 className="join_h">원미디어 회원가입하기</h2>
        <form id="agreement_form">
            <div id="all_agree">
                <span>전체 약관 동의하기</span>
                <input type="checkbox" name="전체동의" id="all_agree_btn" onClick={(event) =>
                {
                    (document.getElementById("agree_onemedia") as HTMLInputElement).checked = event.currentTarget.checked;
                    (document.getElementById("agree_private") as HTMLInputElement).checked = event.currentTarget.checked;
                }} />
                <label id="all_agree_label" htmlFor="all_agree_btn" />
            </div>
            <div className="agree_box">
                <div className="agree_check">
                    <h3>원미디어 이용약관<span>&nbsp;&nbsp;(필수)</span></h3>
                    <div className="agree_checkbox">
                        <span>동의합니다</span>
                        <input type="checkbox" name="약관동의" id="agree_onemedia" onClick={checkAgree} />
                        <label className="agree_label" htmlFor="agree_onemedia" />
                    </div>
                </div>
                <div className="agree_content">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                    eiusmod tempor
                    incididunt ut labore et
                    dolore magna aliqua.<br /><br />

                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                    commodo
                    consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                    nulla
                    pariatur.<br /><br />

                    Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
                    mollit anim id est
                    laborum.<br /><br />

                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                    incididunt ut labore et
                    dolore magna aliqua.<br /><br />

                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                    commodo
                    consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                    nulla
                    pariatur.<br /><br />

                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                    incididunt ut labore et
                    dolore magna aliqua.<br /><br />

                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                    commodo
                    consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                    nulla pariatur.
                </div>
            </div>
            <div className="agree_box">
                <div className="agree_check">
                    <h3>개인정보 수집 및 이용 안내<span>&nbsp;&nbsp;(필수)</span></h3>
                    <div className="agree_checkbox">
                        <span>동의합니다</span>
                        <input type="checkbox" name="약관동의" id="agree_private" onClick={checkAgree} />
                        <label className="agree_label" htmlFor="agree_private" />
                    </div>
                </div>
                <div className="agree_content">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                    eiusmod tempor
                    incididunt ut labore et
                    dolore magna aliqua.<br /><br />

                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                    commodo
                    consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                    nulla
                    pariatur.<br /><br />

                    Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
                    mollit anim id est
                    laborum.<br /><br />

                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                    incididunt ut labore et
                    dolore magna aliqua.<br /><br />

                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                    commodo
                    consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                    nulla
                    pariatur.<br /><br />

                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                    incididunt ut labore et
                    dolore magna aliqua.<br /><br />

                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                    commodo
                    consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                    nulla pariatur.
                </div>
            </div>

            <div id="capcha_box">
                <p>※ 다음단계로 넘어가기 전에 인증해주세요</p>
                <div>
                    <h1 id="msg_social" style={{ display: state.Init == null ? "block" : "none" }}>소셜계정으로 회원가입 하실분은 이전 버튼을 누르신다음 원하는 소셜 로그인 버튼을 눌러주세요</h1>
                    <ReCAPTCHA
                        sitekey={Enviroment.API_KEY_GOOGLE_SITEKEY}
                        onVerify={(response) => state.Data.recaptcha = response }
                        onError={()=>alert("봇 확인절차 오류")} />
                </div>
            </div>
            <div id="btn">
                <a href={Define.URL_FRONT_LOGIN}>이전</a>
                <a id="next_page_btn" style={{ float: "right", background: "#1b67bd",color:"#fff" }} onClick={() => {
                    if (!Enviroment.DEBUG)
                    {
                        let agree_onemedia = (document.getElementById("agree_onemedia") as HTMLInputElement).checked;
                        let agree_private = (document.getElementById("agree_private") as HTMLInputElement).checked;
                        if (!agree_onemedia || !agree_private) { alert("필수 약관에 모두 동의해주셔야 다음으로 넘어갈 수 있습니다"); return; }
                        if (HSUtils.isEmptyString(state.Data.recaptcha)) { alert("자동 가입 방지를 체크해 주세요"); return; }
                    }

                    //다음페이지로 넘어가기
                    state.updateShowForm(true);
                }}>
                    다음
                </a>
            </div>
        </form>
    </div>
);
export default JoinAgree;

function checkAgree() {
    (document.getElementById("all_agree_btn") as HTMLInputElement).checked =
        (document.getElementById("agree_onemedia") as HTMLInputElement).checked &&
        (document.getElementById("agree_private") as HTMLInputElement).checked;
}