//Copyright ⓒ HSKernel All Right Reserved
//https://github.com/gpo04174

import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as Define from "../../../../../Define";
import { Go, getQuery } from "../../../../../store/Board/BoardList";
import { IBoardList } from "./List";

//한번에 보여줄 페이지 갯수
const page_max: number = 5;
//한번에 보여줄 목록 갯수
const count: number = Math.max(Define.BOARD_MAX, 2);

const Pagenation: React.FC<IBoardList> = (props: IBoardList) =>
{
    //현재 페이지
    let [state] = useState<IPagenation>(Refresh(props.data.page));

    //Download SVG icon from http://tabler-icons.io/i/chevron-left
    return (
        <div className="d-flex align-items-center" style={{paddingTop:"10px", paddingBottom:"10px"}}>

            <ul className="pagination m-0 ms-auto">
                <li className={`page-item ${state.previous ? null : "disabled"}`}>
                    <Link
                        to={{
                            pathname: window.location.pathname,
                            search: getQuery(props.data.keyword, state.previous_page)
                        }}
                        onClick={(event) => onClick(event, state.previous_page)}
                        className="page-link" aria-disabled="false" style={{ cursor: "pointer" }} >
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                             strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <polyline points="15 6 9 12 15 18" />
                        </svg>
                        이전
                    </Link>
                </li>
                {
                    getButton().map((i: number) =>
                    (
                        <li key={i} className={i === state.current ? "page-item active" : "page-item"}>
                            {/* <Link to={getGo(this.props.keyword, i)} className="page-link"  style={{ cursor: "pointer" }}>{i}</Link> */}
                            {/* <a onClick={() => this.MovePage(i)} className="page-link"  style={{ cursor: "pointer" }}>{i}</a> */}
                            {/* <a href={getGo(this.props.keyword, i) } className="page-link" style={{ cursor: "pointer" }}>{i}</a> */}
                            <Link
                                to={{
                                    pathname: window.location.pathname,
                                    search: getQuery(props.data.keyword, i)
                                }}
                                onClick={(event) => onClick(event, i)}
                                className="page-link" style={{ cursor: "pointer" }}>{i}
                            </Link>
                        </li>
                    ))
                }
                <li className={`page-item ${state.next ? null : "disabled"}`}>
                    <Link
                        to={{
                            pathname: window.location.pathname,
                            search: getQuery(props.data.keyword, state.next_page)
                        }}
                        onClick={(event) => onClick(event, state.next_page)}
                        className="page-link" style={{ cursor: "pointer" }} >
                        다음
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                             strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <polyline points="9 6 15 12 9 18" />
                        </svg>
                    </Link>
                </li>
            </ul>
        </div>
    );

    function Refresh(page: number): IPagenation
    {
        let next = false;
        let next_page = -1;
        let previous = false;
        let previous_page = -1;

        //총 갯수
        let total = props.data.count;

        //최대 페이지보다 큰 페이지는 없소이다...
        page = Math.min(page, Math.ceil(total / count));
        //1보다 작은 페이지는 없소이다....
        page = Math.max(page, 1);

        //총 페이지
        let block_total = Math.max(page, Math.ceil(total / count));
        //기준 페이지
        let block_now = Math.floor((page - 1) / page_max) * page_max;
        //현재 페이지에서의 최대 범위
        let block_max = 1;

        //처음 페이지면 다음 버튼 비활성화 아니라면 활성화
        if (block_now + page_max >= block_total) {
            block_max = block_total - block_now;
            next = false;
        }
        else {
            block_max = page_max;
            next_page = block_now + page_max + 1;
            next = true;
        }

        //처음 페이지면 이전 버튼 비활성화 아니라면 활성화
        if (block_now >= page_max) {
            previous = true;
            previous_page = block_now;
        }
        else {
            previous = false;
        }

        return ({
            //페이지의 끝이면 다음 버튼 비활성화 아니라면 활성화
            next: next,
            //다음 페이지
            next_page: next_page,

            //처음 페이지면 이전 버튼 비활성화 아니라면 활성화
            previous: previous,
            //이전 페이지
            previous_page: previous_page,

            //현재 페이지
            current: page,

            block_max: block_max,
            block_now: block_now
        });
    }

    function getButton(): number[]
    {
        let block_now: number = state.block_now;
        let items = new Array<number>();

        for (let i = block_now + 1; i <= state.block_max + state.block_now; i++) items.push(i);

        return items;
    }

    function onClick(Event: React.MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>, page: number)
    {
        //이벤트 취소
        Event.preventDefault();
        //let url = getQuery(props.data.keyword, page, true);
        //window.location.href = url;

        Go(props.Props.Navigate, props.data.keyword, page);
        props.onRefresh();
    }
}

export default Pagenation;

interface IPagenation {
    previous: boolean;
    previous_page: number;
    next: boolean;
    next_page: number;

    //현재 페이지
    current: number;

    block_max: number;
    block_now: number;
}