import axios, { AxiosResponse } from "axios";
import * as HSUtils from "../../Utils/HSUtils";
import * as UI from "../../Utils/UI";
import * as Define from "../../Define";
import * as API from "../../API";
import { NavigateFunction, Params } from "react-router-dom";
import {getQuery} from "./BoardList";
import {BoardInit, View} from "./Board";

export interface Props
{
    Init: BoardInit;
    Editable?: boolean;
}

export type IBoardView = {
    Contents: View
}

export type BoardViewState =
{
    data?: View | null;
    id: string;
    keyword: string;
    page: number;
}

export function getInitState(param: Readonly<Params<string>>): BoardViewState
{
    let searchParams = new URLSearchParams(window.location.search);
    return {
        id: param.id as string,
        keyword: HSUtils.convertToString(searchParams.get('keyword'), ""),
        page: Math.max(HSUtils.convertToInt(searchParams.get('page'), 1), 1),
    }
}

export async function Delete(Navigate: NavigateFunction, Init: BoardInit, {id, keyword, page}: BoardViewState)
{
    UI.confirm("정말 삭제하시겠습니까?", null, async () =>
    {
        try
        {
            let response: AxiosResponse = await axios.delete(Define.URL_API_DASHBOARD + `/board/kind/${Init.Type}/${Init.Kind}/${id}`);
            if(await API.checkAjax(Navigate!, response))
            {
                let url = HSUtils.parentPath(1) + getQuery(keyword, page);
                //window.location.href = url;
                Navigate(url, {replace: true})
            }
            else alert("삭제중 오류가 발생하였습니다");
        }
        catch (ex: any) { API.checkError(Navigate!, ex); }
    });

    return false;
}