import { BrowserStorageMapper } from "../lib/BrowserStorage";

export class MemberData
{
    constructor(ID: string, Name: string, Level: number, Image: string)
    {
        this.ID = ID;
        this.Name = Name;
        this.Level = Level;
        this.Image = Image;
    }

    ID: string;
    Name?: string;
    Level: number;
    Image: string;

    static Parse(data: any): MemberData | null
    {
        return new MemberData(
            data.id,
            data.name,
            data.level,
            data.picture,
        );
    }
}

//https://blog.banksalad.com/tech/typescript-local-storage/
export class MemberDataMapper implements BrowserStorageMapper<MemberData>
{
    fromJson(json: string | null): MemberData | null { return json == null ? null : MemberData.Parse(JSON.parse(json)); }
    toJson(target: MemberData): string {
        return JSON.stringify({
            id: target.ID,
            name: target.Name,
            level: target.Level,
            image: target.Image,
        });
    }
}