//https://summernote.org/getting-started/
import React, { useEffect, useRef } from "react";
import axios, { AxiosResponse } from "axios";
import * as HSUtils from "../../../../../../Utils/HSUtils";
import * as Define from "../../../../../../Define";
import { ContentsInit } from "../FormContents";
import * as API from "../../../../../../API";
import {NavigateFunction, useNavigate } from "react-router";

declare var $: any;

interface HTMLTextAreaElementEx extends HTMLTextAreaElement {
    summernote: any;
}

const PlaceHolder = "내용을 작성해 주세요...";
const TextAreaSummernote: React.FC<ContentsInit> = ({ name, value }) =>
{
    let Navigate = useNavigate();

    let rootElement = useRef<HTMLDivElement>(null);
    let writeElement = useRef<HTMLTextAreaElementEx>(null);
    let waitElement = useRef<HTMLHeadingElement>(null);

    //> $.fn.tooltip.Constructor.VERSION
    useEffect(() =>
    {
        const list: string[] = [
            //"https://netdna.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.css",
            //"https://netdna.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.js",

            "https://cdn.jsdelivr.net/npm/summernote@0.8.20/dist/summernote-lite.min.css",
            "https://cdn.jsdelivr.net/npm/summernote@0.8.20/dist/summernote-lite.min.js",
            "https://cdnjs.cloudflare.com/ajax/libs/summernote/0.8.20/lang/summernote-ko-KR.min.js"
        ]

        const onError = (isInternal: boolean) =>
        {
            waitElement.current?.remove();
            writeElement.current?.setAttribute("style", "display: block");
            alert(`편집기 초기화 중 ${isInternal ? "내부 " : ""}오류가 발생하여 일반 편집기로 전환됩니다`);
        }

        //외부 스크립트 로드
        if (rootElement.current)
            HSUtils.loadExternal(rootElement.current!, list,
                async () => { await Summernote(); waitElement.current?.remove(); },
                () => onError(false));
        else onError(true);
    }, []);

    return (
        <div ref={rootElement}>
            <h3 ref={waitElement}
                style={{
                    display: "block",
                    textAlign: "center",
                    height: "300px",
                    whiteSpace: "pre-line",
                    background: "#eeeeee",
                }}>
                편집기 초기화 중입니다<br />
                잠시만 기다려 주세요
            </h3>

            <textarea
                id="write_contents"
                className="form-control"
                placeholder={PlaceHolder}
                name={name}
                rows={20} cols={100}
                style={{ display: "none" }}
                defaultValue={value === null ? undefined : value}
                ref={writeElement} />
        </div>
    )

    async function Summernote()
    {
        let emojiUrls: any = [];
        let emojis: any = [];

        //2초간 딜레이
        //await HSUtils.sleep(2000);
        try
        {
            let data = await axios.get("https://api.github.com/emojis");
            emojiUrls = data.data;
            emojis = Object.keys(data.data);
        }
        catch (ex) { alert("오류가 발생하여 이모지를 사용할 수 없습니다"); }

        let write_area: any = $('#write_contents');
        write_area.summernote({
                height: 400,          // 기본 높이값
                minHeight: 300,      // 최소 높이값(null은 제한 없음)
                maxHeight: null,      // 최대 높이값(null은 제한 없음)
                width: null,
                maxWidth: null,
                focus: true,          // 페이지가 열릴때 포커스를 지정함
                lang: 'ko-KR',        // 한국어 지정(기본값은 en-US)
                placeholder: PlaceHolder, //기본 내용
                //글꼴 설정
                fontNames: ['맑은 고딕', '굴림', '굴림체', '돋움', '돋움체', '바탕', '바탕체', '궁서', '궁서체'],
                fontNamesIgnoreCheck: ['Helvetica', 'Arial'], //무시할 글꼴 설정

                codeviewFilter: true, //HTML 코드뷰 <script> 태그 방지
                codeviewIframeFilter: true, //HTML 코드뷰 <iframe> 태그 방지
                toolbar: [
                    //['undo', ['undo']], //되돌리기 버튼 여부
                    //['redo', ['redo']], //다시실행 버튼 여부
                    ['style', ['style']], //글 스타일
                    ['fontname', ['fontname']], //글꼴 목록 버튼 여부
                    ['fontsize', ['fontsize']], //글꼴 크기 버튼 여부
                    ['color', ['color']], //글꼴색 및 배경색 여부
                    ['font', ['bold', 'italic', 'underline', 'clear']], //글꼴 스타일 버튼 여부 (굵게, 기울이기, 밑줄, 스타일 지우기)
                    ['height', ['height']], //줄 간격 버튼 여부
                    ['table', ['table']], //테이블 작성 버튼 여부
                    ['insert', ['link', 'picture', 'video']], //첨부 버튼 여부 (링크, 사진, 동영상)
                    ['para', ['ul', 'ol', 'paragraph']], //문단 버튼 스타일 여부
                    ['view', [/*'fullscreen', */'codeview', 'help']], //전체화면, 코드뷰, 도움말 버튼 여부
                ],
                hint: {
                    match: /:([\-+\w]+)$/,
                    search: function (keyword: any, callback: any) {
                        callback($.grep(emojis, function (item: any) {
                            return item.indexOf(keyword)  === 0;
                        }));
                    },
                    template: function (item: any) {
                        var content = emojiUrls[item];
                        return '<img src="' + content + '" width="20" /> :' + item + ':';
                    },
                    content: function (item: any) {
                        var url = emojiUrls[item];
                        if (url) {
                            return $('<img />').attr('src', url).css('width', 20)[0];
                        }
                        return '';
                    }
                },
                callbacks: {
                    //이미지 첨부시 이벤트
                    onImageUpload: (files: FileList) => onImageUpload(Navigate, files, write_area),
                }
            });
        $('.note-editable').css('font-family', '맑은 고딕'); //기본 폰트
        $('.note-editable').css('font-size', '14px'); //기본 폰트 크기
    }

    async function onImageUpload(Navigate: NavigateFunction, files: FileList, editor: any)
    {
        for(let i = 0; i < files.length; i++)
        {
            let file: File = files[i];
            console.log(file)
            try
            {
                let response: AxiosResponse = await axios({
                    method: "post",
                    url: `${Define.URL_API_DASHBOARD}/board/io/upimage?name=${file.name}`,
                    data: file,
                    headers: { "Content-Type": file.type }
                });

                let data = await API.checkAjaxValue(Navigate, response);
                if (data != null)
                {
                    let url = `${Define.URL_API}${data.path}?token=${data.token}`;
                    editor.summernote('insertImage', url);
                }
            }
            catch (ex) { API.checkError(Navigate!, ex); }
        }

        let input: any = document.evaluate(".//input[contains(@class, 'note-image-input')] | input[@type='file']", document)?.iterateNext();
        if(input) input.value = null;
    }
}

export default TextAreaSummernote;