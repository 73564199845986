import { Link } from "react-router-dom";
import * as Define from "../../Define";

const Error = (showButton: boolean, redirect: boolean = false) =>
{
    return(
        <div>
            { redirect ? window.location.href = Define.URL_ERROR_404 : null }
            404 Not Found
            <br />
            {showButton ? <Link to={Define.getAbsPath("/")}>메인으로 가기</Link> : null}
        </div>
    )
};

export default Error;