//나중에 라이브러리화 시키기
import * as React from 'react';
import {NavigateFunction, useNavigate } from 'react-router-dom';
import * as Define from '../../../Define';
import * as Environment from '../../../Environment';
import * as Login from "../../../store/Login/Login";
import Loading from "../../Common/Loading";

declare const window: any;

const KEY_EVENT = "naver_callback";
const KEY_BUTTON = "naverSignin";

export type LoginNaverProps = {
    Navigate?: NavigateFunction,
    popup: boolean,
    onSuccess: Function,
    onFail: Function,
}

export default class NaverLogin extends React.Component<LoginNaverProps>
{
    componentDidMount()
    {
        try
        {
            let naverScript = getScriptNaver();
            document.head.appendChild(naverScript);

            naverScript.onload = () =>
            {
                const naverLogin = new window.naver.LoginWithNaverId({
                    clientId: Environment.API_KEY_NAVER_CLIENT,
                    callbackUrl: Login.CALLBACK_NAVER,
                    callbackHandle: false,
                    isPopup: this.props.popup,
                    loginButton: {
                        color: "green",
                        type: 3,
                        height: 55,
                    }
                });
                naverLogin.init();

                //naverIdLogin
                document.getElementById(KEY_BUTTON)?.addEventListener(KEY_EVENT, (event: any) => {
                    console.log(event);
                    naverLogin.getLoginStatus(async (status: any) =>
                    {
                        if (status) this.props.onSuccess(naverLogin);
                        else this.props.onFail();
                    });
                });

                naverLogin.logout(); //네이버 로그인이 계속 유지되는 경우가 있음, 초기화시 로그아웃

                /*
                let naver_id_login = new window.naver_id_login(Environment.API_KEY_NAVER_CLIENT, CALLBACK);
                var state = naver_id_login.getUniqState();
                naver_id_login.setButton("white", 2, 40);
                naver_id_login.setDomain(`${ORIGIN}${Define.URL_API_DASHBOARD}/login/callback/naver`);
                naver_id_login.setState(state);
                naver_id_login.setPopup();
                naver_id_login.init_naver_id_login();
                console.log(naver_id_login);
                 */
            }
        }
        catch (ex: any) { this.props.onFail(); }
    }


    render()
    {
        //TODO: 나중에 이렇게 라이브러리화 시키기
        /*
        <NaverLogin
            clientId={Environment.API_KEY_NAVER_CLIENT} //내 애플리케이션 정보에 cliendId를 입력해줍니다.
            callbackUrl={Define.URL_DASHBOARD + "/login/login_callback_naver"} // 내 애플리케이션 API설정의 Callback URL 을 입력해줍니다.
            isPopup={true} //새창으로 로그인 팝업창 생성 여부
            onSuccess={(result: NaverUser) => console.log(result)}
            onFailure={() => console.log()}
            render={(props: any) =>
                <div id="naverIdLogin" onClick={props.onClick}>
                    <img src="/resources/login/images/naver-icon-style.png" />
                    <span>네이버</span>
                </div>
            }
        />
         */

        return (
            <button id={KEY_BUTTON} onClick={() => (document.getElementById("naverIdLogin")?.children[0] as any).click()}>
                <img src="/resources/login/images/naver-icon-style.png" />
                <span>네이버</span>
                <div id="naverIdLogin" style={{ display: "none" }}></div>
            </button>
        )
    }
}
//export default (props: LoginNaverProps) => (<NaverLogin {...props} Navigate={useNavigate()}/>);

export const NaverCallback: React.FC = () =>
{
    let Navigate = useNavigate();
    React.useEffect(() => {
        const naverScript = getScriptNaver();
        document.head.appendChild(naverScript);

        naverScript.onload = () =>
        {
            try
            {
                const naverLogin = new window.naver.LoginWithNaverId({
                    clientId: Environment.API_KEY_NAVER_CLIENT,
                    callbackUrl: Login.CALLBACK_NAVER,
                    popup: false
                });
                naverLogin.init();

                //팝업 모드일때...
                if (window.opener != null)
                {
                    window.opener.document.getElementById(KEY_BUTTON).dispatchEvent(new CustomEvent(KEY_EVENT));
                    window.close();
                }
                else
                {
                    naverLogin.getLoginStatus(async (status: any) =>
                    {
                        if (status)
                        {
                            //취소시 로그인 메인으로 이동
                            if (!await Login.checkNaver(Navigate, naverLogin))
                                window.location.href = Define.URL_FRONT_LOGIN;
                        }
                        else {
                            alert("네이버 로그인을 취소하였습니다");
                            window.location.href = Define.URL_FRONT_LOGIN;
                        }
                    });
                }
            }
            catch (ex: any) {
                alert(ex.message);
                window.location.href = Define.URL_FRONT_LOGIN;
            }
        }
    });
    return <div><Loading show={true} message="잠시만 기다려 주십시오..." /></div>
}


export function getScriptNaver(): HTMLScriptElement {
    //네이버 로그인 초기화
    const naverScript = document.createElement("script");
    //naverScript.src = "https://static.nid.naver.com/js/naverLogin_implicit-1.0.3.js";
    naverScript.src = "https://static.nid.naver.com/js/naveridlogin_js_sdk_2.0.2-nopolyfill.js";
    naverScript.type = "text/javascript";

    return naverScript;
}