import React from "react";
import {Props} from "../../../../../store/Board/BoardList";
import {IBoardList} from "./List";
import Title from "../../Common/Title";
import {Link} from "react-router-dom";

const ListTitle: React.FC<Props> = ({Delete, Write, Init}: Props, state: IBoardList) =>
    (
        <div className="page-header" style={{marginBottom:"10px"}}>
            <div className="row g-2 align-items-center d-print-none">
                <Title {...Init}/>
                <div className="col-12 col-md-auto ms-auto d-print-none">
                    <div className="btn-list">
                        {!Delete ? null :

                            <span className="d-sm-inline">
                                <a className="btn btn-white" style={{cursor: "pointer"}} onClick={() => state.Delete()}>
                                    삭제
                                </a>
                            </span>

                        }

                        {!Write ? null :

                            <Link to="write" className="btn btn-primary d-sm-inline-block"
                                  style={{cursor: "pointer"}}>
                                {/* Download SVG icon from http://tabler-icons.io/i/plus */}
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                     viewBox="0 0 24 24"
                                     strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round"
                                     strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <line x1="12" y1="5" x2="12" y2="19"/>
                                    <line x1="5" y1="12" x2="19" y2="12"/>
                                </svg>
                                글쓰기
                            </Link>

                        }
                    </div>
                </div>
            </div>
        </div>
    );

export default ListTitle;