export const Main = "/";
export const Logout = "/logout";
export const Test = "/test";

export const RootLogin = "/login";
export const Login = RootLogin + "";
export const Join = RootLogin + "/join";
export const FindID = RootLogin + "/find/id";
export const FindPW = RootLogin + "/find/pw";
export const ResetPW = RootLogin + "/reset/pw";
export const CallbackNaver = RootLogin + "/callback/naver";

export const RootHistory = "/history";
export const HistoryLogin = RootHistory + "/login"

export const RootInfo = "/info";
export const InfoCompany = RootInfo + "/company";
export const InfoManager = RootInfo + "/manager";
export const InfoPassword = RootInfo + "/password";
export const InfoProfile = RootInfo + "/profile";

export const RootSupport = "/support";
export const SupportNotice = RootSupport + "/notice";
export const SupportFAQ = RootSupport + "/faq";
export const SupportQnA = RootSupport + "/qna";
export const SupportEstimate = RootSupport + "/estimate";
export const SupportDataroom = RootSupport + "/dataroom";
export const SupportRemote = RootSupport + "/remote";
export function getSupportPath(type: string, kind: number = 0)
{
    switch (type)
    {
        case "notice":
            if(kind === 1) return SupportDataroom;
            else return SupportNotice;
        case "faq": return SupportFAQ;
        case "qna": return SupportQnA;
        case "estimate": return SupportEstimate;
    }
}

export const ServiceHomepagePopup = "/service/homepage/popup";
export const ServiceHomepageStatsVisitors = "/service/homepage/stats/visitors";