import { BrowserRouter, Route, Routes } from 'react-router-dom';
import * as PATH from '../PATH';

import './Main/Main.css';
import Main from './Main/Main';
import Topmenu from './Main/Topmenu';

import Logout from './Logout';
import Test from "./Main/Chart/Test";
import Error404 from './Error/404';

import LoginPassword from './Main/Info/Password';
import LoginHistory from "./Main/History/LoginHistory";

import InfoCompany from './Main/Info/Company';
import InfoManager from './Main/Info/Manager';
import InfoProfile from './Main/Info/Profile';

import * as SupportType from "./Main/Support/TYPE";
import SupportView from "./Main/Support/View/View"
import SupportWrite from "./Main/Support/Write/Write";
import SupportFAQList from "./Main/Support/List/FAQ";
import SupportNoticeList from "./Main/Support/List/Notice";
import SupportQnAList from "./Main/Support/List/QnA";
import SupportDataRoomList from "./Main/Support/List/Dataroom";
import SupportEstimateList from "./Main/Support/List/Estimate";


import ServiceHomepageStatsVisitors from "./Main/Service/Homepage/Stats/Visitors";
import ServiceHomepagePopup from "./Main/Service/Homepage/Popup";

const Router = () => {
    return(
        <BrowserRouter>
            <Topmenu/>
            <Routes>
                <Route path={PATH.Main}>
                    <Route index={true} element={<Main Init={{ Type: SupportType.Notice, Kind: 0, Title: '메인 페이지', TitlePre: 'Main' }} />} />
                </Route>
                <Route path={PATH.Logout} element={<Logout />} />

                {/* <Route path="/api" component={fetch('/api')}/> */}

                <Route path={PATH.Test} element={<Test />} />

                <Route path={PATH.HistoryLogin} element={<LoginHistory />} />

                <Route path={PATH.InfoPassword} element={<LoginPassword />} />
                <Route path={PATH.InfoCompany} element={<InfoCompany />} />
                <Route path={PATH.InfoManager} element={<InfoManager />} />
                <Route path={PATH.InfoProfile} element={<InfoProfile />} />

                <Route path={PATH.SupportNotice}>
                    <Route index={true} element={<SupportNoticeList Init={{ Type: SupportType.Notice, Kind: 0, Title: '공지사항 목록', TitlePre: 'Notice' }} />} />
                    <Route path=":id" element={<SupportView Init={{ Type: SupportType.Notice, Kind: 0, Title: '공지사항 보기', TitlePre: 'Notice' }} />} />
                </Route>
                <Route path={PATH.SupportQnA}>
                    <Route index={true} element={<SupportQnAList Init={{ Type: SupportType.QnA, Kind: 0, Title: '1:1문의 목록', TitlePre: 'QnA' }} Write={true} />} />
                    <Route path="write/:id" element={<SupportWrite Init={{ Type: SupportType.QnA, Kind: 0, Title: '1:1문의 수정', TitlePre: 'QnA' }} />} />
                    <Route path="write" element={<SupportWrite Init={{ Type: SupportType.QnA, Kind: 0, Title: '1:1문의 작성', TitlePre: 'QnA' }} />} />
                    <Route path=":id" element={<SupportView Init={{ Type: SupportType.QnA, Kind: 0, Title: '1:1문의 보기', TitlePre: 'QnA' }} Editable={true} />} />
                </Route>
                <Route path={PATH.SupportFAQ}>
                    <Route index={true} element={<SupportFAQList Init={{ Type: SupportType.FAQ, Kind: 0, Title: '자주 묻는 질문', TitlePre: 'FAQ' }} />} />
                    <Route path=":category" element={<SupportFAQList Init={{ Type: SupportType.FAQ, Kind: 0, Title: '자주묻는 질문', TitlePre: 'FAQ' }}/>} />
                </Route>
                <Route path={PATH.SupportDataroom}>
                    <Route index={true} element={<SupportDataRoomList Init={{ Type: SupportType.Notice, Kind: 1, Title: '자료실 목록', TitlePre: 'Dataroom' }}/>} />
                    <Route path=":id" element={<SupportView Init={{ Type: SupportType.Notice, Kind: 1, Title: '자료실 목록', TitlePre: 'Dataroom' }} />} />
                </Route>
                {/*<Route path={PATH.SupportEstimate} element={<SupportEstimateList Init={{ Type: 'estimate', Kind: 0 }} Write={true} Delete={true} />} />*/}
                {/*<Route path={PATH.SupportDataroom} element={<SupportEstimateList Init={{ Type: 'notice', Kind: 1 }} Write={true} Delete={true} />} />*/}
                {/*<Route path={PATH.SupportRemote} element={<SupportRemote />} />*/}

                <Route path={PATH.ServiceHomepageStatsVisitors} element={<ServiceHomepageStatsVisitors />} />
                <Route path={PATH.ServiceHomepagePopup} element={<ServiceHomepageStatsVisitors />} />

                <Route path="*" element={Error404(false)} />
            </Routes>
        </BrowserRouter>
    );
}

export default Router;