import React from "react";
import {Link, useNavigate, useParams, useSearchParams, useLocation} from "react-router-dom";
import Pagination from "./Common/Page";
import Header from "./Common/Header";
import Title from "./Common/Title";
import List from "./Common/List";
import * as API from "../../../../API";
import * as PATH from "../../../../PATH";
import * as BoardList from "../../../../store/Board/BoardList";
import {Data, getQuery, Param, PropsExport} from "../../../../store/Board/BoardList";
import * as HSUtils from "../../../../Utils/HSUtils";
import skeletonList from "../Skeleton/Skeleton";

let category: any = null;

function getCategory (category?: string|null):string {
    switch (category) {
        case "payment": return "결제/요금";
        case "login": return "로그인/회원가입";
        case "error": return "사이트 오류문의";
        case "etc": return "기타";
        default: return "전체";
    }
}
export class FAQListComponent extends List {
    async componentDidUpdate() {
        if (category !== this.props.Param.category)
        {
            category = this.props.Param.category;

            let params: Param[] = [{key: "contents", value: true}];
            Object.keys(this.props.Param)?.map((key) => params.push({key: key, value: this.props.Param[key]}));
            await this.Refresh(params);
        }
    }

    render() {
        if (HSUtils.isEmpty(this.state.data.list) || category !== this.props.Param.category) {
            //기다리는 코드 작성 (플레이스 홀더)
            return (
                <div className="page-wrapper" id="page-top-margin">
                    <div className="container-xl placeholder-glow">
                        {/* 부모 props를 그대로 전달 (https://www.skovy.dev/blog/using-component-dot-notation-with-typescript-to-create-a-set-of-components?seed=2bpddq) */}
                        <Title {...this.props} />

                        <div className="card" style={{marginTop: "10pxw"}}>
                            <div className="card-body py-3" style={{height: "68px"}}/>
                            <div className="card-body" style={{height: "68px"}}>
                                <div className="btn-list placeholder" style={{height:"100%"}}/>
                            </div>
                        </div>
                        <div className="card" style={{marginTop: "10px"}}>
                            <div className="card-header" style={{height: "57px"}}>
                                <h3 className="card-title placeholder col-12" style={{height:"100%"}}/>
                            </div>
                            <div className="card-body" style={{height: "92px"}}>
                                <div className="accordion col-12" id="accordion-example">
                                    <div className="accordion-item placeholder" style={{width:"100%"}}>
                                        <h2 className="accordion-header" id="heading-1">
                                            <button className="accordion-button" style={{width:"100%"}}/>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            let UPDATE_KEY = Math.random();
            const category: String[] = ['/payment', '/login', '/error', '/etc'];
            return (
                <div className="page-wrapper" id="page-top-margin">
                    <div className="container-xl">
                        {Title(this.props, this.state)}
                        <div className="card" style={{marginTop: "10px"}}>
                            <Header {...this.state} key={UPDATE_KEY}/>
                            <div className="table-responsive">
                                <div className="card-body">
                                    <div className="btn-list">
                                        <Link to={PATH.SupportFAQ} className="btn btn-white">
                                            전체
                                        </Link>
                                        <Link to={PATH.SupportFAQ + category[0]} className="btn btn-white">
                                            결제/요금
                                        </Link>
                                        <Link to={PATH.SupportFAQ + category[1]} className="btn btn-white">
                                            로그인/회원가입
                                        </Link>
                                        <Link to={PATH.SupportFAQ + category[2]} className="btn btn-white">
                                            사이트 오류문의
                                        </Link>
                                        <Link to={PATH.SupportFAQ + category[3]} className="btn btn-white">
                                            기타
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card" style={{marginTop: "10px"}}>
                            <div className="card-header">
                                <h3 className="card-title">{getCategory(this.props.Param.category)}</h3>
                            </div>
                            <div className="card-body">
                                <div className="accordion" id="accordion-example">
                                    {this.state.data.list?.map((data: Data) =>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="heading-1">
                                                <button className="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#collapse-1" aria-expanded="false">
                                                        <span style={{fontSize: "18px"}}>Q. {data.title}</span>
                                                </button>
                                            </h2>
                                            <div id="collapse-1" className="accordion-collapse collapse"
                                                 data-bs-parent="#accordion-example">
                                                <div className="accordion-body pt-0">
                                                    {data.contents}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    }

                                </div>
                            </div>
                        </div>
                        <Pagination {...this.state} key={UPDATE_KEY}/>
                    </div>
                    <br/>
                </div>
            )
        }
    }
}

export default (props: PropsExport) => (<FAQListComponent {...props}
                                                          Navigate={useNavigate()}
                                                          Param={useParams()}
                                                          Location={useLocation()}
                                                          Query={useSearchParams()[0]}/>);

