import Address from "src/store/Common/Input/Address";
import * as Define from "../Define";
import * as API from "../API";
import { NavigateFunction } from "react-router-dom";
import axios, { AxiosResponse } from "axios";
import DownloadData from "./DownloadData";

export type CompanyData = {
    regist?: string;//사업자등록번호
    name?: string;
    ceo? : string;
    tel? : string[];
    fax? : string[];
    addressMain? : Address;
    addressSub? : Address[];
    registFile : DownloadData;
    logoFile : DownloadData;
}

export async function getCompanyData(Navigate: NavigateFunction): Promise<CompanyData | null>
{
    try
    {
        let response: AxiosResponse = await axios.get(Define.URL_API_DASHBOARD + '/info/company');
        return await API.checkAjaxValue(Navigate, response);
    }
    catch (ex) { API.checkError(Navigate, ex); }

    return null;
}