import * as HSUtils from "./HSUtils";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { NavigateFunction } from "react-router-dom";

export function confirm(Title: string | null = null, Message: string | null = null, Yes: Function | null = null, No: Function | null = null)
{
    confirmAlert({
        title: HSUtils.isEmptyString(Title) ? "" : Title!,
        message: HSUtils.isEmpty(Message) ? "" : Message!,
        buttons: [
            {
                label: '예',
                onClick: () => Yes ? Yes() : null
            },
            {
                label: '아니오',
                onClick: () => No ? No() : null
            }
        ]
    });
}

export function move(Navigate: NavigateFunction, url: string, replace: boolean = false) { Navigate(url, {replace: replace}); }