import React from "react";
import { CommonProps } from "../../../../../store/Board/BoardWrite";
import Summernote from "./TextArea/Summernote";
import TinyMCE from "./TextArea/TinyMCE";

export interface ContentsInit extends CommonProps {
    value?: string | null,
}

const FormContents: React.FC<ContentsInit> = (init: ContentsInit) =>
{
    //npm i react-email-editor //https://openbase.com/js/react-email-editor
    //npm i @ckeditor/ckeditor5-react
    return (
        <>
            <label className="form-label">내용</label>
            <Summernote {...init} />
        </>
    )
}

export default FormContents;