import React, {useState} from "react";

function Profile() {
    const [fileImage, setFileImage] = useState({
        url: "",
        value: ""
    });
    //파일저장
    const saveFileImage = (e: any) => {
        let imgUrl = URL.createObjectURL(e.target.files[0])
        setFileImage({
            url: imgUrl,
            value: e.target.value
        });
    };

    //파일삭제
    const deleteFileImage = () => {
        URL.revokeObjectURL(fileImage.url);
        setFileImage({
            url: "",
            value: ""
        });

    };
    return (
        <div className="page-wrapper">

            {/*<div className="col-md-12">
                <div className="card">
                    <div className="card-status bg-success"/>
                    <div className="card-body">
                        <p>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                 viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                 stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <circle cx="12" cy="12" r="9"/>
                                <line x1="12" y1="8" x2="12" y2="12"/>
                                <line x1="12" y1="16" x2="12.01" y2="16"/>
                            </svg>
                            &nbsp;
                            <span>고객님의 정보를 안전하게 보호하기 위해 비밀번호를 한 번 더 확인하는 절차입니다.</span>
                        </p>
                    </div>
                </div>
            </div>*/}
            <div className="container-narrow myinfo">
                <br/>
                <h4 className="card-title">프로필 관리</h4>
                <form action="https://httpbin.org/post" method="post">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">프로필</h4>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="mb-3">
                                    <label className="form-label">프로필 이미지</label>

                                    {fileImage.url &&
                                        <div className="card mb-3">
                                            <img src={fileImage.url} alt="profile image"/>
                                            <button className="btn btn-primary" onClick={() => deleteFileImage()}>
                                                삭제
                                            </button>
                                        </div>

                                    }
                                    <input type="file" name="imgUpload" accept="image/*" className="form-control" onChange={saveFileImage} value={fileImage.value}/>
                                    <small className="form-hint">※ jpg, png, gif(5MB이하)의 파일만 첨부 가능</small>
                                </div>

                                <div className="form-group mb-3 ">

                                    <label className="form-label">E-mail 주소</label>
                                    <div>
                                        <input type="email" className="form-control" aria-describedby="emailHelp"
                                               placeholder="이메일 입력"/>
                                    </div>
                                </div>

                                <div className="mb-3">

                                    <div className="mb-3">
                                        <label className="form-label">현재 휴대전화번호</label>
                                        <input type="text" className="form-control" name="example-disabled-input"
                                               value="010-0000-0000" disabled/>

                                    </div>
                                    <a href="#" className="btn" data-bs-toggle="modal" data-bs-target="#modal-simple">번호
                                        변경</a>
                                </div>

                                <div className="modal modal-blur fade" id="modal-simple"
                                     style={{display: "none"}} aria-modal="true" role="dialog">
                                    <div className="modal-dialog modal-dialog-centered" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title">휴대전화번호 변경</h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal"
                                                        aria-label="Close"/>
                                            </div>

                                            <div className="modal-body">
                                                <div className="mb-3">
                                                    <label className="form-label">전화번호 인증</label>
                                                    <div className="input-group mb-2">
                                                        <div className="row g-3" style={{marginBottom: "5px"}}>
                                                            <div className="col-3">
                                                                <select name="phone" className="form-select">
                                                                    <option value="010">010</option>
                                                                    <option value="011">011</option>
                                                                    <option value="016">016</option>
                                                                    <option value="017">017</option>
                                                                    <option value="019">019</option>
                                                                </select>
                                                            </div>
                                                            <div className="col-3"><input type="text"
                                                                                          className="form-control"
                                                                                          maxLength={4}/></div>
                                                            <div className="col-3"><input type="text"
                                                                                          className="form-control"
                                                                                          maxLength={4}/></div>
                                                            <div className="col-3">
                                                                <button className="btn form-control" type="button">인증
                                                                </button>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div className="input-group mb-2">
                                                        <input type="text" className="form-control"
                                                               placeholder="인증번호 6자리"/>
                                                        <button className="btn" type="button">확인</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <a href="#" className="btn"
                                                   data-bs-dismiss="modal">
                                                    취소
                                                </a>
                                                <a href="#" className="btn btn-primary ms-auto" data-bs-dismiss="modal">
                                                    저장
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <br/>
                            </div>

                        </div>
                        <div className="card-footer text-end">
                            <button type="submit" className="btn btn-success btn-pill">내용 저장</button>
                        </div>
                    </div>

                </form>
                <br/><br/>
            </div>
        </div>
    );
}

export default Profile;