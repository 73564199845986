import * as React from 'react';
import * as HSUtils from "../../../../Utils/HSUtils";
import {useNavigate, useParams} from "react-router-dom";
import Title from "../Common/Title";
import FooterGoto from "./Common/FooterGoto";
import FooterEdit from "./Common/FooterEdit";
import ViewTitle from "./Common/ViewTitle";
import {Props, BoardViewState, getInitState} from "../../../../store/Board/BoardView";
import {getBoardView} from '../../../../store/Board/Board';
import ViewFile from './Common/ViewFile';

//https://velog.io/@tlatjdgh3778/React-Hooks-useEffect%EC%97%90-%EB%8C%80%ED%95%9C-%EC%A0%95%EB%A6%AC
const ViewComponent: React.FC<Props> = (props: Props) => {
    let Navigate = useNavigate();
    let [state, setState] = React.useState<BoardViewState>(getInitState(useParams()));

    React.useEffect(() => {
        //setState: React.Dispatch<React.SetStateAction<BoardViewState>>
        async function Refresh() {
            let view = await getBoardView(Navigate, state.id, props.Init);
            if (view != null) setState({
                data: view,
                id: state.id,
                page: state.page,
                keyword: state.keyword
            });
            else alert("오류가 발생하였습니다");
        }

        Refresh().then();
    }, [])

    if (state.data == null) {
    //기다리는 코드 작성
    return (
        <div className="page-wrapper" id="page-top-margin">
            <div className="container-xl placeholder-glow">
                <div className="page-header">
                    <Title {...props.Init} />
                </div>
                { /* 여기에 플레이스 홀더 작성 */}
                <div className="card" style={{marginTop: "10px"}}>
                    <div className="card-header" style={{display: "block"}}>
                        <div className="card-title" style={{marginBottom: "13px"}}>
                            <div className="placeholder col-12">????</div>
                        </div>
                        <div className="card_subtitle">
                            <div className="col-12 placeholder" style={{fontSize: "13px"}}>???</div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="ratio ratio-21x9 card-img-top placeholder"/>
                    </div>

                    <div className="card-footer">
                    <div className="row placeholder" style={{width: "100%", marginLeft: "0px"}}>
                        <FooterGoto {...state} />
                    </div>
                </div>
                </div>

            </div>
        </div>
    );

    } else {
        return (
            <div className="page-wrapper" id="page-top-margin">
                <div className="container-xl">
                    <div className="page-header">
                        {Title(props.Init)}
                    </div>
                    <div className="card" style={{marginTop: "10px"}}>
                        <ViewTitle {...{
                            priority: state.data?.priority,
                            id: state.data?.id.toString(),
                            title: state.data?.title,
                            timestamp: state.data?.timestamp,
                            view: state.data?.view.toString(),
                            user_name: state.data?.user_name
                        }} />


                        <div className="card-body" style={{minHeight: "500px"}}>
                            <div dangerouslySetInnerHTML={{__html: state.data?.contents}}/>


                        </div>
                        <div className="card-body">
                            <ViewFile value={state.data?.files} key={Math.random()}/>
                        </div>

                        <div className="card-footer">
                            <div className="row" style={{width: "100%", marginLeft: "0px"}}>
                                <FooterGoto {...state} />
                                {!props.Editable ? null :
                                    <FooterEdit {...{
                                        init: props.Init,
                                        state: state,
                                    }} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
            </div>
        );
    }

}

export default ViewComponent;