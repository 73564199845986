import '../Login.css';
import React from "react";
import { useNavigate, useSearchParams, NavigateFunction } from "react-router-dom";
import * as HSUtils from "../../../Utils/HSUtils";

import { JoinInitData, JoinFormData, IJoinState } from "../../../store/Login/Join";
import Agree from "./JoinAgree";
import Form from "./JoinForm";
import Loading from "../../Common/Loading";
import * as Define from "../../../Define";
import * as PATH from "../../../PATH";

type Props = {
    navigate: NavigateFunction,
    param:  URLSearchParams
}

let Data: JoinFormData = {
    recaptcha: null,
    token: null,
    id: null,
    pw: null,
    email: null,
    name: null,
    //nick: null,
    phone: null,
    birthday: null,
    picture: null,
}

class Join extends React.Component<Props, IJoinState>
{
    constructor(props: Props)
    {
        super(props)
        this.updateShowForm = this.updateShowForm.bind(this);
        this.showLoading = this.showLoading.bind(this);

        let DataInit: JoinInitData | null = null;

        let Query = new URLSearchParams(window.location.search);
        let data_base64 = Query.get('data');
        if (data_base64 != null)
        {
            try
            {
                DataInit = JSON.parse(decodeURIComponent(atob(data_base64))) as JoinInitData;
                //DataInit.profile.birthday = ["05","31"];
                if (!HSUtils.isEmpty(DataInit))
                {
                    console.log(DataInit);
                    //값 할당
                    Data.token = DataInit.token;
                    Data.picture = DataInit.profile.picture;
                    //Data.nick = data.profile.nick;
                }
            }
            catch
            {
                //alert("잘못된 데이터 입니다. 일반 회원가입으로 진행합니다");
                //props.navigate(Define.getAbsPath(PATH.Login), {replace: true});
            }
        }

        this.state =
        {
            showForm: false,
            Init: DataInit,
            Data: Data,
            Loading: { show: false, message: null },
            showLoading: this.showLoading,
            updateShowForm: this.updateShowForm,
        }
    }

    render()
    {
        return (
            <div>
                <Loading show={this.state.Loading.show} message={this.state.Loading.message} />

                {this.state.showForm ? <Form {...this.state} /> : <Agree {...this.state}/> }
            </div>
        )
    }

    updateShowForm(showForm: boolean)
    {
        //리캡챠 무효화
        if (!showForm) this.state.Data.recaptcha = null;

        this.setState({ showForm: showForm })
    }

    showLoading(show: boolean, message: string | null = null) { this.setState({ Loading: { show: show, message: message } }) }
}

export default () => (<Join navigate={useNavigate()} param={useSearchParams()[0]}/>);