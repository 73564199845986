import React from "react";
import { Link } from "react-router-dom";
import {getQuery} from "../../../../../store/Board/BoardList";
import * as HSUtils from "../../../../../Utils/HSUtils";
import {BoardViewState} from "../../../../../store/Board/BoardView";

const FooterGoto: React.FC<BoardViewState> = ({keyword, page}) => {
    let url = HSUtils.parentPath(1) + getQuery(keyword, page);

    return (
        <div className="col-3">
            <div>
                <Link to={url} className="btn btn-ghost-secondary">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-list" width="24"
                         height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                         strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <line x1="9" y1="6" x2="20" y2="6"/>
                        <line x1="9" y1="12" x2="20" y2="12"/>
                        <line x1="9" y1="18" x2="20" y2="18"/>
                        <line x1="5" y1="6" x2="5" y2="6.01"/>
                        <line x1="5" y1="12" x2="5" y2="12.01"/>
                        <line x1="5" y1="18" x2="5" y2="18.01"/>
                    </svg>
                    목록으로
                </Link>
            </div>
        </div>
    );
}

export default FooterGoto;