import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as Define from "./Define";
import * as HSUtils from "./Utils/HSUtils";
import { Provider } from 'react-redux';

import './index.css';
import { rootReducer } from "./Reducer"
import Router from "./component/Router";
import RouterLogin from './component/Login/RouterLogin';
import RouterError from './component/Error/ErrorRouter';

//import RouterMain from './component/Main/RouterMain';
//import RouterBoard from './component/Main/Board/RouterBoard';

declare global {
    interface Window {
        kakao: any;
        daum: any;
    }
}

if (window.location.href.includes(":26851"))
    window.location.href = window.location.href.replace(":26851", ":26850");

let root = document.getElementById('root');
let path = window.location.pathname;
console.log(__dirname)

let isLogin = !HSUtils.isEmptyString(Define.getStorageMember());
if(isLogin)
{
    if (window.location.pathname.startsWith("/login")) window.location.href = Define.getAbsPath(Define.URL_FRONT_MAIN);
}
else
{
    if (!window.location.pathname.startsWith("/login")) window.location.href = Define.getAbsPath(Define.URL_FRONT_LOGIN);
}

ReactDOM.render(
    <Provider store={rootReducer("")}>
        <RouterError />
        { isLogin ? <Router /> : <RouterLogin />}
    </Provider>,
    root
);