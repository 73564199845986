import React from "react";
import {Go} from "../../../../../store/Board/BoardList";
import {IBoardList} from "./List";

const Header: React.FC<IBoardList> = (props: IBoardList) =>
{
    let Keyword = props.data.keyword;

    return (
        <div className="card-body border-bottom py-3">
            <div className="d-flex" id="list_header">
                <div className="text-muted" id="list_count">
                    게시물 수 총
                    <div className="mx-2 d-inline-block">
                        {props.data.count}
                    </div>
                    건
                </div>
                <div className="ms-auto text-muted">

                    <div className="d-inline-block" style={{width:"100%"}}>
                        <div className="g-2 row">
                            <div className="col">
                                <input placeholder="검색어를 입력하시오" type="text" id="search_keyword"
                                       className="form-control"
                                       aria-label="Search invoice"
                                       defaultValue={Keyword === null ? "" : Keyword}
                                       onChange={(event) => Keyword = event.target.value}
                                       onKeyPress={(event) => {
                                           if (event.charCode == 13) {
                                               Keyword = event.currentTarget.value;
                                               Search();
                                           }
                                       }}/>

                            </div>
                            <div className="col-auto">
                                <a className="btn btn-icon btn-ghost-dark" onClick={Search} aria-label="Button" style={{border:'1px solid #ccc'}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24"
                                         height="24"
                                         viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                         strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <circle cx="10" cy="10" r="7"/>
                                        <line x1="21" y1="21" x2="15" y2="15"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    function Search()
    {
        Go(props.Props.Navigate, Keyword, 1);

        //let url = getQuery(Keyword, 1, true);
        //window.location.href = url;
        //window.history.pushState(null, "", url);

        props.onRefresh();

    }
}

export default Header;