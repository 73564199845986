import axios, { AxiosResponse } from "axios";
import * as HSUtils from "../../Utils/HSUtils";
import * as Define from "../../Define";
import * as API from "../../API";
import { BoardInit } from "./Board";
import { NavigateFunction, Params, Location } from "react-router-dom";


export type Param = {
    key: string,
    value: any
}
export interface PropsExport
{
    Init: BoardInit;
    Write?: boolean;
    Delete?: boolean;
}

export interface Props extends PropsExport
{
    Navigate: NavigateFunction;
    Param: Params;
    Location: Location;
    Query: URLSearchParams;
}

export type Data = {
    no: number;
    id: number;
    contents:string;
    title: string;
    timestamp: string;
    view: number;
    user_id: string;
    user_name: string;
    reply_count: number;
    priority: number;
};

export type ListInit = {
    keyword: string | null,
    page: number,
    count: number,
    list?: Data[]
}

export type Count = {
    count: number;
    count_today: number;
};

export function Go(Navigate: NavigateFunction, Keyword: any, Page: number)
{
    Navigate({
        pathname: window.location.pathname,
        search: getQuery(Keyword, Page),
    })
}

export function getQuery(Keyword: any, Page: number, addPath: boolean = false): string
{
    let add = "";
    if (!HSUtils.isEmptyString(Keyword)) add = `?keyword=${Keyword}`;
    if (Page > 1) add += (HSUtils.isEmptyString(add) ? "?" : "&") + `page=${Page}`;

    return addPath ? window.location.pathname + add : add;
}

/*
export function getLink(Keyword: any, Page: number, addPath: boolean = false)
{
    let query = getQuery(Keyword, Page, addPath);
    return {
        pathname: query,
        key: Math.random(), // we could use Math.random, but that's not guaranteed unique.
        state: {
            applied: true
        }
    };
}
*/

export async function getBoardList({Init, Navigate}: Props, Keyword: string | null = null, Page: number = 1, Params?: Param[]): Promise<Data[]>
{
    try
    {
        if (HSUtils.isEmptyString(Keyword)) Keyword = "";
        Keyword = encodeURI(Keyword!);

        let param_str = Define.URL_API_DASHBOARD + `/board/kind/${Init.Type}/${Init.Kind}/list?page=${Page}&count=${Define.BOARD_MAX}&keyword=${Keyword}`;
        //추가 파라미터 할당
        Params?.map((param) => param_str += `&${param.key}=${param.value}`);

        let response: AxiosResponse = await axios.get(param_str);
        let data = await API.checkAjaxValue(Navigate!, response);
        if(data?.length > 0) return data;
    }
    catch (ex) { API.checkError(Navigate!, ex); }

    return [];
}

export async function getBoardCount({Init, Navigate}: Props, Keyword: string | null = null, Params?: Param[]): Promise<Count>
{
    if (HSUtils.isEmptyString(Keyword)) Keyword = "";
    try
    {
        let param_str = Define.URL_API_DASHBOARD + `/board/kind/${Init.Type}/${Init.Kind}/count?keyword=${Keyword}`;
        //추가 파라미터 할당
        Params?.map((param) => param_str += `&${param.key}=${param.value}`);

        let response: AxiosResponse = await axios.get(param_str);
        return await API.checkAjaxValue(Navigate!, response);
    }
    catch (ex) { API.checkError(Navigate!, ex); }

    return { count: 0, count_today: 0 };
}
 
export async function deleteItems({Init, Navigate}: Props, ids: number[]): Promise<boolean>
{
    try
    {
        let response: AxiosResponse = await axios.post(Define.URL_API_DASHBOARD + `/board/kind/${Init.Type}/${Init.Kind}/delete?id=${ids.join(',')}`);
        return await API.checkAjax(Navigate!, response);
    }
    catch (ex) { API.checkError(Navigate!, ex); }

    return false;
}