import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { BoardInit } from "src/store/Board/Board";
import {BoardViewState, Delete} from "../../../../../store/Board/BoardView"

export type IFooterEdit =
{
    init: BoardInit,
    state: BoardViewState,
}

const FooterEdit: React.FC<IFooterEdit> = (props: IFooterEdit) =>
{
    let Navigate = useNavigate();

    return (
        <div className="col-9 text-end">
            <div className="col">
                {!props.state.data?.deletable ? null :
                    <a className="btn btn-ghost-secondary" onClick={() => Delete(Navigate, props.init, props.state)}
                       style={{cursor: "pointer"}}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash"
                             width="24"
                             height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                             strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <line x1="4" y1="7" x2="20" y2="7"/>
                            <line x1="10" y1="11" x2="10" y2="17"/>
                            <line x1="14" y1="11" x2="14" y2="17"/>
                            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"/>
                            <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"/>
                        </svg>
                        삭제</a>}&nbsp;&nbsp;
                {!props.state.data?.editable ? null :
                    <Link to={`../write/${props.state.id}`} className="btn btn-ghost-secondary"
                       style={{cursor: "pointer"}}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-color-picker"
                             width="24"
                             height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                             strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M11 7l6 6"/>
                            <path d="M4 16l11.7 -11.7a1 1 0 0 1 1.4 0l2.6 2.6a1 1 0 0 1 0 1.4l-11.7 11.7h-4v-4z"/>
                        </svg>
                    수정</Link>
                }
            </div>
        </div>
    )
}

export default FooterEdit;