import axios, {AxiosResponse} from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import * as PATH from "../../../PATH";
import * as HSUtils from "../../../Utils/HSUtils";
import * as Define from "../../../Define";
import * as API from "../../../API";
import {APIData, RESULT_SUCCESS} from "../../../API";
import WarningBadge from "./WarningBadge";
import Loading from "../../Common/Loading";

function ResetPW()
{
    let navigate = useNavigate();
    const [params] = useSearchParams();

    const [loading, showLoading] = useState(false);

    const txt_pw = useRef<HTMLInputElement>(null);
    const txt_pw_confirm = useRef<HTMLInputElement>(null);

    let token = params.get("token");

    useEffect(()=>
    {
        if(HSUtils.isEmptyString(token))
        {
            alert("잘못된 접근입니다");
            navigate(Define.getAbsPath(PATH.Login), {replace: true});
        }
    });

    if(HSUtils.isEmptyString(token)) return <></>;
    else
    {
        async function findPW()
        {
            let password = txt_pw.current?.value;
            let password_confirm = txt_pw_confirm.current?.value;
            
            if (HSUtils.isEmptyString(password)) alert("비밀번호가 비어있습니다");
            else if (HSUtils.isEmptyString(password?.match(Define.POLICY_PW))) alert("비밀번호는 최소 8자이상에 숫자, 문자, 특수문자가 모두 포함되어야 합니다 ");
            else if (password !== password_confirm) alert("비밀번호와 비밀번호 확인이 서로 일치하지 않습니다");
            else
            {
                showLoading(true);
                try
                {
                    let form = new FormData();
                    form.set("pw", password!);
                    form.set("token", token!);

                    let response: APIData = (await axios.post(Define.URL_API_DASHBOARD + "/login/find?mode=reset", form) as AxiosResponse).data;
                    showLoading(false);
                    if(response.result === RESULT_SUCCESS)
                    {
                        alert("비밀번호가 성공적으로 변경되었습니다");
                        navigate(Define.getAbsPath(PATH.Login), {replace: true});
                    }
                    else alert(response.message);
                }
                catch(ex: any) { showLoading(false); API.checkError(navigate, ex); }

            }
        }

        return (
            <Loading show={loading}>
                <div className="page page-center">
                    <div className="container-tight py-4">
                        <div className="text-center mb-4">
                            <img src={'/resources/common/images/one-logo.png'} alt="원미디어"/>
                        </div>
                        <form className="card card-md">
                            <div className="card-body">
                                <h2 className="card-title text-center mb-4">원미디어 회원 비밀번호 변경</h2>
                                <div className="mb-3">
                                    <label className="form-label">비밀번호 변경</label>
                                    <input type="hidden" value={token!}/>
                                    <input type="password" className="form-control" placeholder="변경할 비밀번호" ref={txt_pw}/>
                                    <br/>
                                    <input type="password" className="form-control" placeholder="변경할 비밀번호 확인" ref={txt_pw_confirm}/>
                                </div>
                                <div className="form-footer">
                                    <a className="btn btn-primary w-100" onClick={findPW}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <rect x="3" y="5" width="18" height="14" rx="2"/>
                                            <polyline points="3 7 12 13 21 7"/>
                                        </svg>
                                        변경
                                    </a>
                                </div>
                            </div>
                        </form>
                        <br/>
                        <div className="card">
                            <div className="card-body">
                                <WarningBadge Content="비밀번호는 8자리 이상이어야 합니다"/>
                                <WarningBadge Content="비밀번호는 숫자, 문자, 특수문자가 모두 포함되어야 합니다 "/>
                            </div>
                        </div>
                    </div>
                </div>
            </Loading>
        );
    }
}

export default ResetPW;