import React, {useEffect, useState } from "react";
import Address, {AddressParam} from "src/store/Common/Input/Address";
import {setStorageMember} from "../../../Define";
import {isEmpty, isEmptyString} from "../../../Utils/HSUtils";

type AddressData = {
    //주소
    address: string,
    //영문주소
    addressEnglish?: string,
    //우편번호
    zonecode: string,
}
type AddressInputState = AddressData & {
    detail: string;
}

class AddressInput extends React.Component<AddressParam, AddressInputState>
{
    private post: HTMLInputElement | null;
    private main: HTMLInputElement | null;
    private detail: HTMLInputElement | null;

    private static GetAddress(address?: Address | null): AddressInputState
    {
        return {
            //input 에 null, undefined 방지
            address: isEmptyString(address?.main) ? "" : address!.main!,
            zonecode: isEmptyString(address?.post) ? "" : address!.post!,
            addressEnglish: undefined,
            detail: isEmptyString(address?.detail) ? "" : address!.detail!,
        }
    }
    constructor(props: AddressParam) {
        super(props);

        this.setAddress = this.setAddress.bind(this);
        this.onComplete = this.onComplete.bind(this);

        let address = this.props.address;
        this.state = AddressInput.GetAddress(address);
    }

    setAddress(address?: Address | null)
    {
        let state = AddressInput.GetAddress(address);
        this.setState(state);

        if(this.post != null) this.post.value = state.zonecode;
        if(this.main != null) this.main.value = state.address;
        if(this.detail != null) this.detail.value = state.detail;
    }


    private onComplete(data: AddressData)
    {
        // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분입니다.
        // 예제를 참고하여 다양한 활용법을 확인해 보세요.
        let address = this.props.address;
        //console.log(data)

        if(address != undefined)
        {
            address.main = data.address;
            address.post = data.zonecode;
        }
        this.setAddress({
            post: data.zonecode,
            main: data.address,
            detail: address?.detail
        });

        this.setState(data);
    }

    render()
    {
        let address = this.props.address;
        const openWindow = () => new window.daum.Postcode({ oncomplete: this.onComplete }).open();
        return (
            <div className="mb-3">
                <div className="input-group mb-2">
                    {/* Readonly */}
                    <input ref={(ref) => this.post = ref}
                           type="text" className="form-control" readOnly
                           placeholder="우편번호 (주소 찾기를 눌러주세요)"
                           value={this.state?.zonecode}
                           onClick={openWindow}/>
                    <button className="btn" type="button" onClick={openWindow}>주소 찾기</button>
                </div>

                <input ref={(ref) => this.main = ref}
                       type="text" className="form-control" autoComplete="off" readOnly
                       style={{marginBottom: "8px"}}
                       placeholder="주소"
                       value={this.state.address}
                       onClick={openWindow}/>

                {/*
                <input ref={(ref) => this. = ref}
                       type="text" className="form-control" autoComplete="off" readOnly
                       style={{marginBottom: "8px"}}
                       name="main"
                       placeholder="영문 주소"
                       value={this.state.addressEnglish}
                       onClick={openWindow}/>
                */}

                <input ref={(ref) => this.detail = ref}
                       type="text" className="form-control" autoComplete="off"
                       placeholder="상세주소"
                       defaultValue={this.state.detail}
                       onChange={(e) => { if(address != null) address.detail = e.currentTarget.value}}/>
            </div>
        )
    }
}
export default AddressInput;

//{ post, main, detail }
const AddressInput1: React.FC<AddressParam> = ({address}) =>
{
    let post: HTMLInputElement | null;
    let main: HTMLInputElement | null;
    let detail: HTMLInputElement | null;

    let [addr, setAddr] = useState<AddressData>({
        //input 에 null, undefined 방지
        address: isEmptyString(address?.main) ? "" : address!.main!,
        zonecode: isEmptyString(address?.post) ? "" : address!.post!,
        addressEnglish: undefined
    });

    return (
        <div className="mb-3">
            <div className="input-group mb-2">
                {/* Readonly */}
                <input ref={(ref) => post = ref}
                       type="text" className="form-control" readOnly
                       placeholder="우편번호 (주소 찾기를 눌러주세요)"
                       value={addr?.zonecode}/>
                <button className="btn" type="button" onClick={()=>{
                    new window.daum.Postcode({
                        oncomplete: function(data: AddressData)
                        {
                            // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분입니다.
                            // 예제를 참고하여 다양한 활용법을 확인해 보세요.
                            if(address != undefined)
                            {
                                address.main = data.address;
                                address.post = data.zonecode;
                            }
                            setAddr(data);
                        }
                    }).open();
                }}>주소 찾기</button>
            </div>

            <input ref={(ref) => main = ref}
                   type="text" className="form-control" autoComplete="off" readOnly
                   style={{marginBottom: "8px"}}
                   placeholder="주소 (주소 찾기를 눌러주세요)"
                   value={addr.address}/>

            <input ref={(ref) => detail = ref}
                   type="text" className="form-control" autoComplete="off"
                   placeholder="상세주소"
                   defaultValue={address?.detail}
                   onChange={(e) => { if(address != null) address.detail = e.currentTarget.value}}/>
        </div>
    )
}

const AddressMapFind: React.FC = () =>
{
    let container: any;

    useEffect(() =>
    {
        const onLoadKakaoMap = () =>
        {
            const container = document.getElementById("map");
            const markerPosition = new window.kakao.maps.LatLng(33.450701, 126.570667);
            const options = {
                center: markerPosition,
                level: 3
            };
            const map = new window.kakao.maps.Map(container, options);
            const marker = new window.kakao.maps.Marker({
                position: markerPosition,
            });
            marker.setMap(map);
        };

        /*
        const mapScript = document.createElement("script");
        mapScript.async = true;
        mapScript.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.NEXT_PUBLIC_KAKAOMAP_APPKEY}&autoload=false`;
        document.head.appendChild(mapScript);
        mapScript.addEventListener("load", onLoadKakaoMap);
        */
    }, []);

    return(
        <div ref={(ref) => container = ref} />
    )
}