import {Link} from 'react-router-dom';
import React, {useEffect, useState} from "react";
import * as Define from "../../Define";
import * as PATH from "../../PATH";
import {MemberData} from "../../Struct/MemberData";
import {isEmptyString} from "../../Utils/HSUtils";

const Topmenu: React.FC = () => {
    let member: MemberData = Define.getStorageMember()!;

    return (
        /* eslint-disable*/
        <aside className="navbar navbar-vertical navbar-expand-lg navbar-dark nav-top">
            <div className="container-fluid">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbar-menu">
                    <span className="navbar-toggler-icon"/>
                </button>
                <h1 className="navbar-brand navbar-brand-autodark">
                        <span style={{fontSize: "20px"}}>
                            ONEMEDIA
                        </span>
                </h1>


                <div className="nav-item" id="pc-profile">
                    <a href="#" className="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown"
                       aria-label="Open user menu">
                            <span className="avatar avatar-sm"
                                  style={{
                                      backgroundImage: `url(${isEmptyString(member.Image) ? "/resources/common/images/profile-basic.png" : member.Image})`
                                  }}/>
                        <div className="d-xl-block ps-2" style={{textAlign: "left", fontWeight: "500"}}>
                            <div>{member.Name}</div>
                            <div className="mt-1 small text-muted">회사명</div>
                        </div>
                    </a>

                </div>


                <div className="navbar-nav flex-row d-lg-none">

                    <div className="nav-item dropdown">
                        <a href="#" className="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown"
                           aria-label="Open user menu">
                            <span className="avatar avatar-sm"
                                  style={{backgroundImage: `url(${isEmptyString(member.Image) ? "/resources/common/images/profile-basic.png" : member.Image})`}}/>
                            <div className="d-none d-xl-block ps-2">
                                <div>{member.Name}</div>
                                <div className="mt-1 small text-muted">회사명</div>
                            </div>
                        </a>
                        <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                            <Link to={PATH.Main} className="dropdown-item">메인 홈으로</Link>
                            <Link to={PATH.Logout} className="dropdown-item">로그아웃</Link>
                        </div>
                    </div>
                </div>

                <div className="collapse navbar-collapse" id="navbar-menu">
                    <ul className="navbar-nav pt-lg-3">
                        <li className="nav-item">
                            <Link to={PATH.Main}
                                  className="nav-link btn-ghost-primary">
                                    <span className="nav-link-icon d-md-none d-lg-inline-block">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             className="icon icon-tabler icon-tabler-home"
                                             width="24" height="24" viewBox="0 0 24 24" strokeWidth="2"
                                             stroke="currentColor" fill="none" strokeLinecap="round"
                                             strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <polyline points="5 12 3 12 12 3 21 12 19 12"/>
                                            <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7"/>
                                            <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6"/>
                                        </svg>
                                    </span>
                                <span className="nav-link-title">메인홈으로</span>
                            </Link>

                        </li>
                        <li className="nav-item">
                            <Link to={PATH.InfoPassword} className="nav-link dropdown-toggle btn-ghost-primary"
                                  data-bs-toggle="dropdown"
                                  data-bs-auto-close="outside" role="button" aria-expanded="false">
                                    <span className="nav-link-icon d-md-none d-lg-inline-block">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             className="icon icon-tabler icon-tabler-id-badge-2" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                               <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                               <path d="M7 12h3v4h-3z"/>
                                               <path
                                                   d="M10 6h-6a1 1 0 0 0 -1 1v12a1 1 0 0 0 1 1h16a1 1 0 0 0 1 -1v-12a1 1 0 0 0 -1 -1h-6"/>
                                               <rect x="10" y="3" width="4" height="5" rx="1"/>
                                               <path d="M14 16h2"/>
                                               <path d="M14 12h4"/>
                                        </svg>
                                    </span>
                                <span className="nav-link-title">내 정보관리</span>
                            </Link>
                            <div className="dropdown-menu">
                                <div className="dropdown-menu-columns">
                                    <div className="dropdown-menu-column">
                                        <Link className="dropdown-item btn-ghost-secondary" to={PATH.InfoPassword}>
                                            - 비밀번호 변경
                                        </Link>
                                        <Link className="dropdown-item btn-ghost-secondary" to={PATH.InfoCompany}>
                                            - 업체정보
                                        </Link>
                                        <Link className="dropdown-item btn-ghost-secondary" to={PATH.InfoProfile}>
                                            - 프로필 관리
                                        </Link>
                                        <Link className="dropdown-item btn-ghost-secondary" to={PATH.InfoManager}>
                                            - 담당자정보
                                        </Link>
                                        <Link className="dropdown-item btn-ghost-secondary" to={PATH.HistoryLogin}>
                                            - 접속기록
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="nav-item">
                            <Link to={PATH.ServiceHomepagePopup}
                                  className="nav-link dropdown-toggle btn-ghost-primary"
                                  data-bs-toggle="dropdown"
                                  data-bs-auto-close="outside" role="button" aria-expanded="false">
                                    <span className="nav-link-icon d-md-none d-lg-inline-block">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             className="icon icon-tabler icon-tabler-layout-navbar" width="24"
                                             height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                            <rect x="4" y="4" width="16" height="16" rx="2"/>
                                            <line x1="4" y1="9" x2="20" y2="9"/>
                                        </svg>
                                    </span>
                                <span className="nav-link-title">홈페이지 관리</span>
                            </Link>
                            <div className="dropdown-menu">
                                <div className="dropdown-menu-columns">
                                    <div className="dropdown-menu-column">
                                        <Link to={PATH.ServiceHomepagePopup}
                                              className="dropdown-item btn-ghost-secondary">
                                            - 팝업관리
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle btn-ghost-primary" data-bs-toggle="dropdown"
                               data-bs-auto-close="outside" role="button" aria-expanded="false">
                                    <span className="nav-link-icon d-md-none d-lg-inline-block">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             className="icon icon-tabler icon-tabler-device-analytics" width="24"
                                             height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                                             fill="none" strokeLinecap="round" strokeLinejoin="round">
                                       <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                       <rect x="3" y="4" width="18" height="12" rx="1"/>
                                       <line x1="7" y1="20" x2="17" y2="20"/>
                                       <line x1="9" y1="16" x2="9" y2="20"/>
                                       <line x1="15" y1="16" x2="15" y2="20"/>
                                       <path d="M8 12l3 -3l2 2l3 -3"/>
                                    </svg>
                                    </span>
                                <span className="nav-link-title">통계 (준비중)</span>
                            </a>
                            <div className="dropdown-menu">
                                <div className="dropdown-menu-columns">
                                    <div className="dropdown-menu-column">
                                        <a className="dropdown-item btn-ghost-secondary" href="./activity.html">
                                            - 방문자 통계
                                        </a>
                                        <a className="dropdown-item btn-ghost-secondary" href="./gallery.html">
                                            - 브라우저 통계
                                        </a>
                                        <a className="dropdown-item btn-ghost-secondary" href="./invoice.html">
                                            - 검색어 통계
                                        </a>
                                        <a className="dropdown-item btn-ghost-secondary"
                                           href="./search-results.html">
                                            - 검색엔진 통계
                                        </a>
                                        <a className="dropdown-item btn-ghost-secondary" href="./pricing.html">
                                            - 방문자 유형
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle btn-ghost-primary" data-bs-toggle="dropdown"
                               data-bs-auto-close="outside" role="button" aria-expanded="false">
                                    <span className="nav-link-icon d-md-none d-lg-inline-block">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         className="icon icon-tabler icon-tabler-premium-rights" width="24" height="24"
                                         viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                         strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <circle cx="12" cy="12" r="9">/</circle>
                                        <path
                                            d="M13.867 9.75c-.246 -.48 -.708 -.769 -1.2 -.75h-1.334c-.736 0 -1.333 .67 -1.333 1.5c0 .827 .597 1.499 1.333 1.499h1.334c.736 0 1.333 .671 1.333 1.5c0 .828 -.597 1.499 -1.333 1.499h-1.334c-.492 .019 -.954 -.27 -1.2 -.75"/>
                                        <path d="M12 7v2"/>
                                        <path d="M12 15v2"/>
                                    </svg>
                                </span>
                                <span className="nav-link-title">요금관리 (준비중)</span>
                            </a>
                            <div className="dropdown-menu">
                                <div className="dropdown-menu-columns">
                                    <div className="dropdown-menu-column">
                                        <a className="dropdown-item btn-ghost-secondary"
                                           href="./layout-horizontal.html">
                                            - 청구요금조회
                                        </a>
                                        <a className="dropdown-item btn-ghost-secondary" href="./layout-boxed.html">
                                            - 납입요금조회
                                            <span className="badge badge-sm bg-green text-uppercase ms-2">New</span>
                                        </a>
                                        <a className="dropdown-item btn-ghost-secondary"
                                           href="./layout-vertical.html">
                                            - 세금계산서조회
                                        </a>
                                        <a className="dropdown-item btn-ghost-secondary"
                                           href="./layout-vertical-transparent.html">
                                            - 카드/현금영수증 조회
                                        </a>
                                        <a className="dropdown-item btn-ghost-secondary"
                                           href="./layout-vertical-right.html">
                                            - 알림(문자) 충전
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li className="nav-item dropdown">
                            <Link to={PATH.SupportNotice} className="nav-link dropdown-toggle btn-ghost-primary"
                                  data-bs-toggle="dropdown"
                                  data-bs-auto-close="outside" role="button" aria-expanded="false">
                                    <span className="nav-link-icon d-md-none d-lg-inline-block">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         className="icon icon-tabler icon-tabler-headset"
                                         width="24" height="24" viewBox="0 0 24 24" strokeWidth="2"
                                         stroke="currentColor" fill="none" strokeLinecap="round"
                                         strokeLinejoin="round">
                                       <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                       <rect x="4" y="13" rx="2" width="4" height="6"/>
                                       <rect x="16" y="13" rx="2" width="4" height="6"/>
                                       <path d="M4 15v-3a8 8 0 0 1 16 0v3"/>
                                       <path d="M18 19a6 3 0 0 1 -6 3"/>
                                    </svg>
                                </span>
                                <span className="nav-link-title">고객센터</span>
                            </Link>
                            <div className="dropdown-menu" data-bs-popper="none">
                                <Link className="dropdown-item btn-ghost-secondary" to={PATH.SupportNotice}>
                                    - 공지사항
                                </Link>
                                <Link className="dropdown-item btn-ghost-secondary" to={PATH.SupportFAQ}>
                                    - 자주묻는 질문
                                </Link>
                                <Link className="dropdown-item btn-ghost-secondary" to={PATH.SupportQnA}>
                                    - 1:1문의
                                </Link>
                                {/*<Link className="dropdown-item btn-ghost-secondary" to={PATH.SupportEstimate}>
                                    - 견적 문의
                                </Link>*/}
                                <Link className="dropdown-item btn-ghost-secondary" to={PATH.SupportDataroom}>
                                    - 자료실
                                </Link>
                                {/*<Link className="dropdown-item btn-ghost-secondary" to={PATH.SupportRemote}>
                                    - 원격지원
                                </Link>*/}
                            </div>
                        </li>
                    </ul>
                </div>
                <div style={{marginTop: "20px", marginBottom: "20px"}} className="report text-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-logout"
                         width="24"
                         height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                         strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path
                            d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2"/>
                        <path d="M7 12h14l-3 -3m0 6l3 -3"/>
                    </svg>
                    <a href={PATH.Logout} style={{color: "#eee"}}>로그아웃</a>

                </div>

            </div>
        </aside>

    );
}

export default Topmenu;