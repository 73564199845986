import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as API from "../API";

const Logout = () =>
{
    let Navigate = useNavigate();
    useEffect(()=>
    {
        API.Logout(Navigate, true).then();
    })
    return (<div>잠시만 기다려 주세요...</div>);
}
export default Logout;