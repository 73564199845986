import {NavigateFunction, Params } from "react-router";
import {BoardInit, View} from "./Board";
import * as Define from "../../Define";
import axios from "axios";
import * as PATH from "../../PATH";
import * as API from "../../API";

export interface CommonProps {
    name: string;
    value?: any;
}

export interface Props{
    Contents?: View;
    Init: BoardInit;
}

export interface State {
    isLoading: boolean;
    Contents?: View;
}

export async function Submit(Navigate: NavigateFunction, init: BoardInit, form: HTMLFormElement)
{
    let formdata = new FormData(form);
    //data.forEach(((value1, key, parent) => console.log(`${key}=${value1}`)));

    try
    {
        let response = await axios.post(`${Define.URL_API_DASHBOARD}/board/kind/${init.Type}/${init.Kind}/write`, formdata);
        let data = await API.checkAjaxValue(Navigate, response);
        if (data === null) alert("저장하지 못했습니다");
        else
        {
            alert("저장 하였습니다");
            //data.id.toString() + getQuery(this.state.data.keyword, this.state.data.page)
            Navigate(`${PATH.getSupportPath(init.Type, init.Kind)}/${data.id}`);
        }
    }
    catch (ex: any){ alert(`저장중 오류가 발생하였습니다\n\n${ex.message}`); }
}