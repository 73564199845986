import React, {useState} from "react";
import {getFileName, isEmpty} from "../../../../../Utils/HSUtils";
import {CommonProps} from "../../../../../store/Board/BoardWrite";
import {BoardFile} from "../../../../../store/Board/Board";
import * as Define from "../../../../../Define";
import FileItem from "./File/FileItem";

export interface FileInit extends CommonProps {
    value?: BoardFile[] | null,
}

export type Item = {
    fileBoard?: BoardFile,
    file?: HTMLInputElement,
}

const FormFile: React.FC<FileInit> = ({name, value}) => {
    let [deletes, setDelete] = useState<string[]>([]);
    let [files, setFiles] = useState<Item[]>(() => {
        let items: Item[] = [];
        value?.map((item) => items.push({
            fileBoard: item,
        }));

        return items;
    });


    return (
        <div className="col-xl">
            <div className="mb-3">
                <div className="mb-3">
                    <label className="form-label">첨부파일</label>
                    <small className="form-hint">문서, 사진, 음악, 비디오, 압축파일만 10MB까지 올릴 수 있습니다.</small>
                </div>

                <div className="mb-3">
                    <div className="row">
                        <div className="col">
                            <a className="btn btn-white" onClick={Add}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24"
                                     height="24"
                                     viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                     strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M14 3v4a1 1 0 0 0 1 1h4"/>
                                    <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"/>
                                    <line x1="12" y1="11" x2="12" y2="17"/>
                                    <line x1="9" y1="14" x2="15" y2="14"/>
                                </svg>
                                첨부파일 추가
                            </a>&nbsp;&nbsp;&nbsp;
                            {/*<a className="btn btn-white" onClick={RemoveCheck}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24"
                                     height="24"
                                     viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                     strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M14 3v4a1 1 0 0 0 1 1h4"/>
                                    <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"/>
                                    <line x1="9" y1="14" x2="15" y2="14"/>
                                </svg>
                                첨부파일 삭제
                            </a>*/}
                        </div>
                    </div>
                </div>

                <div className="mb-3">
                    <input type="hidden" name="filesdel" value={deletes?.join(',')}/>
                    {files.map((value, index) =>
                        <div className="col mb-1" key={index}>
                            <div className="file-list">
                                {/*<span className="input-group-text">
                                <input className="form-check-input m-0" type="checkbox" onChange={()=>Check(index, value)}/>
                            </span> */}
                                <div style={{display:"inline-block",height:"100%"}} className="hover-red" onClick={() => Remove(index, value)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                         viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                         strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <line x1="18" y1="6" x2="6" y2="18"/>
                                        <line x1="6" y1="6" x2="18" y2="18"/>
                                    </svg>
                                </div>
                                <FileItem {...value}/>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )

    function Add() {
        let input = document.createElement("input");
        input.type = "file";
        input.name = name;
        input.setAttribute("style", "display: none");
        input.onchange = (event: Event) =>
        {
            let file = event.currentTarget as HTMLInputElement;
            //TODO: 파일 크기 및 형식 제한코드 넣기
            console.log(file.files);
            setFiles(old => [...old, {
                file: event.currentTarget as HTMLInputElement,
            }]);
        }
        input.click();
    }

    //개별 삭제 클릭했을 때
    function Remove(id: number, init: Item) {
        setFiles(files.filter((value, index) => index !== id));
        if (!isEmpty(init.fileBoard)) setDelete(old => [...old, init.fileBoard?.key!]);
    }
}


export default FormFile;