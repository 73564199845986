import React from "react";
import {BoardInit} from "../../../../store/Board/Board";


const Title: React.FC<BoardInit> = ({Title, TitlePre}) => {
    return (
        <div className="col">
            {/* Page pre-title */}
            <div className="page-pretitle">{TitlePre}</div>
            <h2 className="page-title">{Title}</h2>
        </div>

    )
}

export default Title;