import axios, { AxiosResponse, AxiosError } from "axios";
import * as Define from "./Define";
import * as UI from "./Utils/UI";
import * as API from "./API";
import * as PATH from "./PATH";
import { MemberData } from "./Struct/MemberData";
import { NavigateFunction } from "react-router-dom";

export type APIData = {
    result: string,
    message: string,
    code: string,
    data: any | null
}

export const RESULT_SUCCESS = "success";
export const RESULT_FAIL = "fail";

export function checkError(navigate: NavigateFunction, ex: any)
{
    if (ex.isAxiosError)
    {
        const axiosError: AxiosError = ex;
        if (axiosError.response?.status == 404) UI.move(navigate, Define.URL_ERROR_404);
        else if (axiosError.response?.status == 403) UI.move(navigate, Define.URL_ERROR_403);
        else if (axiosError.response?.status == 500) UI.move(navigate, Define.URL_ERROR_500);
    }
}

export function checkAuth(navigate: NavigateFunction, json: APIData)
{
    if (json.code.startsWith('AUTH_')) {
        navigate(PATH.Login);
    }
    else return json;
}


export async function checkAjaxResponse(Navigate: NavigateFunction, Response: AxiosResponse) : Promise<APIData | null>
{
    return await checkAjax(Navigate, Response) ? Response.data : null;
}

export async function checkAjax(Navigate: NavigateFunction, Response: AxiosResponse) : Promise<boolean>
{
    try
    {
        if (Response.data.result === RESULT_FAIL)
        {
            if (Response.data.code.startsWith("AUTH_")) await Logout(Navigate, true);
        }
        return true;
    }
    catch (ex: any) { checkError(Navigate, ex); }

    return false;
}

export async function checkAjaxValue(Navigate: NavigateFunction, Response: AxiosResponse) : Promise<any | null>
{
    return await checkAjax(Navigate, Response) ? Response.data.data : null;
}

export async function checkLogin(): Promise<boolean>
{
    let response: AxiosResponse = await axios.get(Define.URL_API_DASHBOARD + "/login/login");
    let data = response.data as APIData;

    return data.result === API.RESULT_SUCCESS;
}


export async function Logout(Navigate: NavigateFunction, Redirect: boolean = true)
{
    let BackURL = window.location.pathname;

    let response: AxiosResponse = await axios.get(Define.URL_API_DASHBOARD + "/login/logout");
    if (response.data.result !== API.RESULT_SUCCESS) return;

    //카카오 로그아웃
    kakaoLogout();
    //네이버 로그아웃 (모든 네이버 서비스에서 로그아웃됨!!)
    //http://nid.naver.com/nidlogin.logout

    Define.setStorageMember(null);
    //clearStorage();

    if (Redirect)
    {
        Navigate(Define.URL_FRONT_LOGIN + (BackURL === PATH.Logout ? "" : `?redirect=${encodeURIComponent(BackURL!)}`));
        window.location.reload();
    }
}

export async function ApplyMember(navigate: NavigateFunction, Redirect: boolean = true): Promise<boolean>
{
    let response: AxiosResponse = await axios.get(Define.URL_API_DASHBOARD + "/info/my");

    if (response.data.result === API.RESULT_SUCCESS)
    {
        Define.setStorageMember(MemberData.Parse(response.data.data));
        return true;
    }
    else await Logout(navigate, Redirect);

    return false;
}


declare const window: any;
function kakaoLogout() //카카오로그아웃
{
    try
    {
        let Kakao = window.Kakao;
        if (Kakao.Auth.getAccessToken()) {
            Kakao.API.request({
                url: '/v1/user/unlink',
                success: (response: any) => {
                    console.log(response)
                },
                fail: (error: any) => {
                    console.log(error)
                },
            })
            Kakao.Auth.setAccessToken(undefined);
        }
    }
    catch (ex) { console.error(ex); }
}