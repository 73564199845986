const Myinfo = () =>
    (
        <div className="page-wrapper">
            <div className="container myinfo">
                <br/>
                <h4 className="card-title">담당자 정보 관리</h4>
                <form action="https://httpbin.org/post" method="post">
                    <div className="row">
                        <div className="col-md-6" style={{marginBottom: "10px"}}>
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">결제(세금계산서) 담당자 정보</h4>
                                </div>
                                <div className="card-body">


                                    <div className="mb-3">
                                        <label className="form-label">성명</label>
                                        <input type="text" className="form-control" autoComplete="off"/>
                                    </div>
                                    <div className="form-group mb-3 ">
                                        <label className="form-label">이메일 주소</label>
                                        <div>
                                            <input type="email" className="form-control" aria-describedby="emailHelp"
                                                   placeholder="예) help@onemedia.org" autoComplete="off"/>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">휴대폰번호</label>


                                        <div className="form-group mb-2">
                                            <div className="g-3 row" style={{marginBottom: "5px"}}>
                                                <div className="col-12"><input type="text" className="form-control"
                                                                              maxLength={11}/></div>
                                            </div>
                                        </div>



                                    </div>
                                </div>
                                <div className="card-footer text-end">
                                    <button type="submit" className="btn btn-success btn-pill">내용 저장</button>
                                </div>
                            </div>

                        </div>
                        {/*<div className="col-md-4" style={{marginBottom: "10px"}}>
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">세금계산서 담당자 정보</h4>
                                </div>
                                <div className="card-body">

                                    <div className="mb-3">
                                        <div>
                                            <label className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="tax-form"/>
                                                <span className="form-check-label">대표와 동일</span>
                                            </label>
                                            <label className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="tax-form"/>
                                                <span className="form-check-label">별도 등록</span>
                                            </label>
                                            <label className="form-check form-check-inline">
                                                <input className="form-check-input" type="radio" name="tax-form"/>
                                                <span className="form-check-label">결제담당자와 동일</span>
                                            </label>
                                        </div>
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label">성명</label>
                                        <input type="text" className="form-control" autoComplete="off"/>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">직위</label>
                                        <input type="text" className="form-control" autoComplete="off"/>
                                    </div>
                                    <div className="form-group mb-3 ">
                                        <label className="form-label">이메일 주소</label>
                                        <div>
                                            <input type="email" className="form-control" aria-describedby="emailHelp"
                                                   placeholder="예) help@onemedia.org" autoComplete="off"/>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">휴대전화번호</label>


                                        <div className="form-group mb-2">
                                            <div className="g-3 row" style={{marginBottom: "5px"}}>
                                                <div className="col-12"><input type="text" className="form-control"
                                                                              maxLength={11}/></div>
                                            </div>
                                        </div>

                                        <div className="input-group mb-2">
                                            <input type="text" className="form-control" placeholder="인증번호 6자리"/>
                                            <button className="btn" type="button">확인</button>
                                        </div>


                                    </div>
                                </div>
                                <div className="card-footer text-end">
                                    <button type="submit" className="btn btn-success btn-pill">내용 저장</button>
                                </div>

                            </div>
                        </div>*/}
                        <div className="col-md-6" style={{marginBottom: "10px"}}>
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">개인정보 취급 책임자</h4>
                                </div>
                                <div className="card-body">



                                    <div className="mb-3">
                                        <label className="form-label">성명</label>
                                        <input type="text" className="form-control" autoComplete="off"/>
                                    </div>
                                    <div className="form-group mb-3 ">
                                        <label className="form-label">이메일 주소</label>
                                        <div>
                                            <input type="email" className="form-control" aria-describedby="emailHelp"
                                                   placeholder="예) help@onemedia.org" autoComplete="off"/>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">전화번호</label>


                                        <div className="form-group mb-2">
                                            <div className="g-3 row" style={{marginBottom: "5px"}}>
                                                <div className="col-12"><input type="text" className="form-control"
                                                                              maxLength={11}/></div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                                <div className="card-footer text-end">
                                    <button type="submit" className="btn btn-success btn-pill">내용 저장</button>
                                </div>

                            </div>
                        </div>
                    </div>

                </form>
                <br/><br/>

            </div>
        </div>
    );

export default Myinfo;