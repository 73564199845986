import React from "react";
import {Link, useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import Pagination from "./Common/Page";
import Header from "./Common/Header";
import Title from "./Common/Title";
import List from "./Common/List";
import { Data, getQuery, PropsExport} from "../../../../store/Board/BoardList";
import * as HSUtils from "../../../../Utils/HSUtils";
import {NoticeListComponent} from "./Notice";
import skeletonList from "../Skeleton/Skeleton";

export class QnAListComponent extends List {
//TODO: 체크박스 클릭하면 삭제버튼 활성화/바활성화
    render() {
        if (HSUtils.isEmpty(this.state.data.list))
        {
            //기다리는 코드 작성 (플레이스 홀더)
            return (
                <div className="page-wrapper" id="page-top-margin">
                    <div className="container-xl placeholder-glow">
                        {/* 부모 props를 그대로 전달 (https://www.skovy.dev/blog/using-component-dot-notation-with-typescript-to-create-a-set-of-components?seed=2bpddq) */}
                        <Title {...this.props} />
                        <div className="card" style={{marginTop: "10px"}}>
                            <div className="card-body border-bottom py-3" style={{height: "68px"}}/>
                            <div className="table-responsive">
                                <table className="table card-table table-vcenter text-nowrap datatable"
                                       id="notice_table">
                                    <thead>
                                    <tr style={{height:"40.5px"}}>
                                       <th/><th/><th/><th/><th/>
                                    </tr>
                                    </thead>

                                    <tbody>

                                    {skeletonList()}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            );
        }
        else {
            return (
                <div className="page-wrapper" id="page-top-margin">
                    <div className="container-xl">
                        {Title(this.props, this.state)}
                        <div className="card" style={{marginTop: "10px"}}>
                            <Header {...this.state} />
                            <div className="table-responsive">
                                <table className="table card-table table-vcenter text-nowrap datatable">
                                    <thead>
                                    <tr>
                                        <th className="w-1"> {this.RenderCheckAll()} </th>
                                        <th className="w-1">No. {/* Download SVG icon from http://tabler-icons.io/i/chevron-up */}
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 className="icon icon-sm text-dark icon-thick"
                                                 width="24" height="24" viewBox="0 0 24 24" strokeWidth="2"
                                                 stroke="currentColor"
                                                 fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                <polyline points="6 15 12 9 18 15"/>
                                            </svg>
                                        </th>
                                        <th style={{fontSize: "15px", textAlign: "center"}}>제목</th>
                                        <th style={{fontSize: "15px", textAlign: "center"}} className="w-3">등록일</th>
                                        <th className="w-3" style={{fontSize: "15px", textAlign: "center"}}>처리상태</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.data.list?.map((data: Data) =>
                                            <tr key={data.id}>
                                                <td>{this.RenderCheck(data.id)}</td>
                                                <td style={{textAlign: "center"}}><span
                                                    className="text-muted">{data.no}</span>
                                                </td>
                                                <td>
                                                    <Link
                                                        to={data.id.toString() + getQuery(this.state.data.keyword, this.state.data.page)}
                                                        className="text-reset board_title" tabIndex={-1}>{data.title}
                                                    </Link>
                                                </td>
                                                <td style={{textAlign: "center"}}>{HSUtils.getDateFromDate(new Date(data.timestamp))}</td>

                                                <td style={{textAlign: "center"}}>
                                                    {data.reply_count == 0 ? "답변중" : "답변완료"}
                                                </td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </table>

                            </div>
                        </div>
                        <Pagination {...this.state} />
                    </div>
                    <br/>
                </div>
            );
        }
    }
}

export default (props: PropsExport) => (<QnAListComponent {...props}
                                                             Navigate={useNavigate()}
                                                             Param={useParams()}
                                                             Location={useLocation()}
                                                             Query={useSearchParams()[0]} />);
