import {CompanyData} from "../Struct/CompanyData";

declare var Document: any;
declare var String: any;

//https://stackoverflow.com/a/55348259/7080663
export const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

export function StringFormat(...args: any[])
{
    // The string containing the format items (e.g. "{0}")
    // will and always has to be the first argument.
    var theString = args[0];

    // start with the second argument (i = 1)
    for (var i = 1; i < args.length; i++) {
        // "gm" = RegEx options for Global search (more than one instance)
        // and for Multiline search
        var regEx = new RegExp("\\{" + (i - 1) + "\\}", "gm");
        theString = theString.replace(regEx, args[i]);
    }

    return theString;
};
String.format = () => StringFormat;

//https://stackoverflow.com/a/24398129
String.pad = function (str: string, pad: string, padLeft: boolean) {
    if (typeof str === 'undefined')
        return pad;
    if (padLeft) {
        return (pad + str).slice(-pad.length);
    } else {
        return (str + pad).substring(0, pad.length);
    }
}

//window.location, URL에서 값 가져오기 -> https://hsmtree.wordpress.com/2015/04/16/window-location-url%EC%97%90%EC%84%9C-%EA%B0%92-%EA%B0%80%EC%A0%B8%EC%98%A4%EA%B8%B0/
Document.getPath = function() { return _getPath(); }
Document.getPathWindow = function() { return _getPath(); }
Document.getName = function() { return _getName(); }
Document.getNameWindow = function() { return _getName(); }
Document.getDirectory = function() { return _getDirectory(); }
Document.getDirectoryWindow = function() { return _getDirectory(); }
Document.getRoot = function() { return _getURLSubString(false); }
Document.getRootWindow = function() { return _getURLSubString(false); }
Document.getDomain = function() { return _getURLSubString(true); }
Document.getDomainWindow = function() { return _getURLSubString(true); }

function _getPath() { return window.location.pathname }
function _getName()
{
    var path = _getPath();
    return path.substring(path.lastIndexOf('/') + 1);
}
function _getDirectory()
{
    var path = _getPath();
    return path.substring(0, path.lastIndexOf('/'));
}
function _getURLSubString(isdomain: boolean)
{
    var url = _getPath();
    var index_start = url.indexOf('://') + 3;
    var index_end = url.indexOf('/', index_start);
    return url.substring(isdomain ? index_start : 0, index_end);
}

export function isEmpty(data: any): boolean { return data === undefined || data === null; }
export function isEmptyString(str: any): boolean { return isEmpty(str) || str === ""; }
export function isNullorWhiteSpace(str: any): boolean { return isEmptyString(str.trim()); }

export function checkEmail(email: string): boolean { return isEmptyString(email) ? false : /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email); }

export function getDateFromISO8601(timestamp: string): string {
    let index = timestamp.indexOf("T");
    return timestamp.substring(0, (timestamp.length - index) + 1);
}
export function getDateFromDate(date: Date): string {
    return date.toISOString().slice(0, 10);
}

export function convertToInt(value: any, defaultValue: number): number
{
    let Value = parseInt(value);
    return Value.toString() === "NaN" ? defaultValue : Value;
}

export function convertToString(value: any, defaultValue: any): any
{
    return isEmpty(value) ? defaultValue : value;
}


/**
 * 해당 uppercount 만큼 현재 경로에서 상위 경로로 갑니다
 * @param uppercount
 */
export function parentPath(uppercount: number) { return upperPath(window.location.pathname, uppercount); }

/**
 * 해당 uppercount 만큼 path 상위 경로로 갑니다
 * @param path
 * @param uppercount
 */
export function upperPath(path: string, uppercount: number)
{
    if (isEmptyString(path)) return path;

    while (uppercount-- > 0)
    {
        let index = path.lastIndexOf('/');

        if (index > 0) path = path.substring(0, index);
        else break;
    }

    return path;
}

export function PathMaker(A: string, B: string, PathChar = '/')
{
    if (isNullorWhiteSpace(A) || isNullorWhiteSpace(B)) return A + B;
    else if (Same(A[A.length - 1]) && Same(B[0])) return A + B.substring(1, B.length - 1);
    else if (Same(B[0]) || Same(A[A.length - 1])) return A + B;
    else return A + PathChar + B;
}
function Same(p: string) { return p === '/' || p === '\\'; }

export function getFileName(filePath: string|null|undefined): string | null
{
    if(isEmpty(filePath)) return null;

    let index = filePath!.lastIndexOf('/');
    if(index < 0) index = filePath!.lastIndexOf('\\');
    return index > 0 ? filePath!.substring(index + 1) : filePath!;
}


/**
 * 스트립트 로드 (재귀 사용)
 * @param element
 * @param URL
 * @param onComplete
 * @param onError
 */
export function loadExternal(element: Element, URL: string[], onComplete?: Function, onError?: Function)
{
    if (element != null)
    {
        let path = URL.splice(0, 1)[0];
        let onLoad = () => {
            if (URL.length > 0) loadExternal(element, URL, onComplete, onError);
            else if (onComplete) onComplete();
        };

        let ref: any;
        if (path!.endsWith(".css")) {
            ref = document.createElement("link");
            ref.href = path!;
            ref.rel = "stylesheet";
        }
        else {
            ref = document.createElement("script");
            ref.src = path!;
            ref.type = "text/javascript";
        }
        ref.onerror = (event: Event) => { console.log(event); if (onError) onError(); }
        ref.onload = onLoad;
        element.append(ref);
    }
}

/**
 * Remove the functions from the state. They can't been pushed into the history.
 * https://localcoder.org/uncaught-domexception-failed-to-execute-pushstate-on-history-function
 */
export function cleanStateForHistory(state: any)
{
    const stateWithNoFunctions: any = {};
    for (const key of Object.keys(state)) {
        if (typeof key !== "function") {
            stateWithNoFunctions[key] = state[key];
        }
    }
    return stateWithNoFunctions;
}

/**
 * like this (value || "")
 * @param value
 */
export function toInput(value: any): any { return isEmpty(value) ? "" : value; }

/**
 * FormData to Object (with Array)
 * @param form
 */
export function serializeForm(form: FormData): any
{
// @ts-ignore
    const f = Array.from(form);
// @ts-ignore
    return  f.reduce((o: any, [k, v]) => {
        let a = v,
            b, i,
            m = k.split('['),
            n = m[0],
            l = m.length;
        if (l > 1) {
            a = b = o[n] || [];
            for (i = 1; i < l; i++) {
                m[i] = (m[i].split(']')[0] || b.length) * 1;
                b = b[m[i]] = ((i + 1) == l) ? v : b[m[i]] || [];
            }
        }
        return { ...o, [n]: a };
    }, {});
}

/**
 * Know type
 * @param x
 */
export function getType(x: any): string
{
    if (x == null) {
        return 'null';
    }

    let t = typeof x;
    if (t != "object") {
        return t;
    }

    let c = Object.prototype.toString.apply(x);
    c = c.substring(8, c.length - 1); // [object ?]의 특성을 이용함

    if (c != "Object") {
        return c;
    }

    // @ts-ignore
    if (c.constructor == "Object") {
        return c;
    }
    else {
        let s = x.constructor.toString();
        let i = s.indexOf("(");
        return s.substring(9, i); // function ?( ... 의 특성을 이용함
    }
}