import React, { ReactNode } from "react";

export enum PopupCloseResult {
    Cancel,
    OK,
    YES,
    NO
}
export type PopupData = {
    isShow?: boolean,
    title?: string
    openCallback?: Function,
    //true 면 창 닫히고 false 면 닫히지 않음
    closingCallback?: (code: PopupCloseResult) => boolean,
    closedCallback?: (code: PopupCloseResult) => void,
    children?: ReactNode,
}

export default class Popup extends React.Component<PopupData>
{
    private dialog: HTMLDivElement | null;
    private btn_open: HTMLButtonElement | null;
    private btn_close: HTMLButtonElement | null;
    private btn_id: number = Math.floor(Math.random() * 1000000);

    constructor(props: PopupData)
    {
        super(props);

        this.Show = this.Show.bind(this);
        this.IsOpen = this.IsOpen.bind(this);
        this.ClickClose = this.ClickClose.bind(this);
        this.onClosed = this.onClosed.bind(this);
    }

    componentDidMount()
    {
        if(this.IsOpen() != this.props.isShow)
        {
            this.Show(this.props.isShow == undefined ? false : this.props.isShow);
        }
    }

    Show(isShow: boolean)
    {
        if(this.IsOpen() != isShow && this.btn_open != undefined)
        {
            this.btn_open?.click();
            if(this.IsOpen() && this.props.openCallback != undefined) this.props.openCallback();
        }
    }

    private IsOpen() { return this.dialog?.style.display == "block"; }
    private ClickClose(code: PopupCloseResult)
    {
        if(this.props.closingCallback != undefined && !this.props.closingCallback(code)) return;

        this.btn_close?.click();
        this.onClosed(code);
    }
    private onClosed(code: PopupCloseResult) { if(this.props.closedCallback != undefined) this.props.closedCallback(code) }

    render()
    {
        return(
            <>
                <button ref={(ref) => this.btn_open = ref}
                        style={{display: 'none'}}
                        data-bs-toggle="modal"
                        data-bs-target={"#modal-" + this.btn_id}>&nbsp;</button>

                <div ref={(ref) => this.dialog = ref} id={"modal-" + this.btn_id} style={{display: "none"}}
                     className="modal fade" role="dialog" aria-modal="true"
                     onClick={() => false}>

                    <button ref={(ref) => this.btn_close = ref} style={{display: 'none'}} data-bs-dismiss="modal"/>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{this.props.title}</h5>
                                <button type="button" className="btn-close" aria-label="Close"
                                        onClick={()=>this.ClickClose(PopupCloseResult.Cancel)}/>
                            </div>
                            <div className="modal-body">
                                {this.props.children}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn me-auto"
                                        onClick={()=>this.ClickClose(PopupCloseResult.Cancel)}>닫기</button>
                                <button type="button" className="btn btn-primary"
                                        onClick={()=>this.ClickClose(PopupCloseResult.OK)}>저장</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}