import * as React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';

import * as PATH from "../../PATH";
import FindID from "./Find/FindID";
import FindPW from "./Find/FindPW";
import ResetPW from "./Find/ResetPW";
import Join from "./Join/Join";
import Login from "./Login";
import { NaverCallback } from "./OAuth/NaverLogin";

import Error404 from '../Error/404';

const RouterLogin = () =>
(
    <BrowserRouter>
        <Routes>
            <Route path={PATH.Login} element={<Login/>} />
            <Route path={PATH.FindID} element={<FindID/>} />
            <Route path={PATH.FindPW} element={<FindPW/>} />
            <Route path={PATH.ResetPW} element={<ResetPW/>} />
            <Route path={PATH.Join} element={<Join/>} />
            <Route path={PATH.CallbackNaver} element={<NaverCallback/>} />
            <Route path="*" element={Error404(false)} />
        </Routes>
    </BrowserRouter>
);

export default RouterLogin;