import * as React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';

import Error404 from "./404";
import Error403 from "./403";
import Error500 from "./500";

const RouterLogin = () =>
(
    <BrowserRouter basename="/error">
        <Routes>
            <Route path="/403" element={<Error403 />} />
            <Route path="/500" element={<Error500 />} />
            <Route path="/404" element={ Error404(true) } />
        </Routes>
    </BrowserRouter>
);

export default RouterLogin;