import React from "react";
import { useNavigate } from "react-router";
import { confirm } from "../../../../../Utils/UI";

const FormGoBack: React.FC = () =>
{
    let Navigate = useNavigate();
    return(
        <a className="btn btn-ghost-secondary" onClick={() => confirm("경고", "정말 작성을 취소하시겠습니까?", () => Navigate(-1))}>
            <svg xmlns="http://www.w3.org/2000/svg"
                 className="icon icon-tabler icon-tabler-list" width="24"
                 height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                 fill="none"
                 strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M9 13l-4 -4l4 -4m-4 4h11a4 4 0 0 1 0 8h-1" />
            </svg>
            뒤로가기
        </a>
    )
}

export default FormGoBack;