import React from "react";
import axios from "axios";
import * as Define from '../../../Define';
import * as API from '../../../API';
import * as HSUtils from "../../../Utils/HSUtils";
import * as Login from "../../../store/Login/Login";
import * as CODE from "../../../API/Code/APICodeLogin";
import { useNavigate } from "react-router-dom";


type Password = {
    pw?: string,
    pw_new?: string,
    pw_new_confirm?: string
}

//100% useState 로 구현
const PasswordUI = () =>
{
    let Navigate = useNavigate();
    let [password, setPassword] = React.useState<Password>();

    //함수형 컴포넌트에선 Bind 불필요!!
    const Confirm = async () =>
    {
        console.log(password)
        if (HSUtils.isEmptyString(password?.pw)) alert("현재 비밀번호를 입력해주십시오");
        else if (HSUtils.isEmptyString(password?.pw_new)) alert("변경할 비밀번호를 입력해주십시오");
        else if (HSUtils.isEmptyString(password?.pw_new_confirm)) alert("비밀번호 확인을 입력해주십시오");
        else if (password?.pw_new != password?.pw_new_confirm) alert("변경할 비밀번호와 확인 비밀번호가 일치하지 않습니다");
        else
        {
            try
            {
                let response = await axios({
                    method: 'post',
                    url: Define.URL_API_DASHBOARD + "/info/password",
                    data: password,
                    //headers: {'Content-Type': 'multipart/form-data'}
                    //headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                });

                //API 응답 점검
                let api = await API.checkAjaxResponse(Navigate, response);
                if(api != null)
                {
                    if (api.result === API.RESULT_SUCCESS)
                    {
                        //formData.set("pw", );
                        alert("비밀번호가 변경되었습니다");
                        return;
                    }
                    else if (response.data.code ===  CODE.ERR_LOGIN_INVALID_ACCOUNT) alert("비밀번호가 일치하지 않습니다");
                    else if (response.data.code === CODE.ERR_LOGIN_INVALID_PW) alert("입력하신 비밀번호 조합이 유효하지 않습니다.");
                    else Login.alertError(response.data.message, "변경 중 오류가 발생하였습니다");
                }
            }
            catch (ex: any) {
                Login.alertError(ex.message, "오류가 발생하였습니다");
            }
        }

    }

    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
        setPassword({
            ...password,
            [e.currentTarget.name]: e.currentTarget.value
        });
    }

    return (
        <div className="page-wrapper">
            <div className="container-narrow myinfo">
                <br/>
                <h4 className="card-title">비밀번호 변경</h4>
                <div className="col-md-12">
                    <div className="card">

                        <div className="card-header">
                            <h3 className="card-title">비밀번호 변경</h3>
                        </div>
                        <div className="card-body">


                            <div className="form-group mb-3 ">
                                <label className="form-label">현재 비밀번호</label>
                                <div>
                                    <input type="password" className="form-control" placeholder="현재 비밀번호" name="pw"
                                           value={ password?.pw || "" }
                                           onChange={handleChange}/>
                                </div>
                            </div>
                            <div className="form-group mb-3 ">
                                <label className="form-label">변경할 비밀번호</label>
                                <div>
                                    <input type="password" className="form-control" placeholder="변경할 비밀번호" name="pw_new"
                                           value={ password?.pw_new || "" }
                                           onChange={handleChange}/>
                                    <input type="password" className="form-control" placeholder="비밀번호 확인" name="pw_new_confirm"
                                           value={ password?.pw_new_confirm || "" }
                                           onChange={handleChange}/>
                                    <small className="form-hint">※ 비밀번호는 8~20자 영문 대문자 소문자, 숫자, 특수문자
                                        를 조합 사용하세요.
                                        아이디와 동일한 비밀번호 사용할 수 없습니다.
                                        주민등록번호, 생일, 전화번호 등 타인이 알아내기 쉬운
                                        비밀번호는 유출위험이 있으니 피하세요.</small>
                                </div>
                            </div>
                            <div className="form-footer">
                                <button type="submit" className="btn btn-primary" onClick={Confirm}>확인</button>
                            </div>
                        </div>
                    </div>
                </div>
                <br/><br/>
            </div>
        </div>
    );
};

export default PasswordUI;