import React, {useEffect, useRef} from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import * as Define from '../../../Define';
import {isEmpty, isEmptyString, serializeForm, toInput} from "../../../Utils/HSUtils";
import {CompanyData, getCompanyData} from "../../../Struct/CompanyData";
import { processObjectFile } from "../../../Struct/FileData"
import Address from "../../../store/Common/Input/Address";
import AddressUI from "../../Common/Input/Address";
import AddressInput from "../../Common/Input/Address";
import Popup, {PopupCloseResult} from "../../Common/Popup";
import * as API from "../../../API";
import {DEBUG} from "../../../Environment";
import {getDownloadURL, isDownloadable} from "../../../Struct/DownloadData";

type CompanyDataSend = CompanyData & {
    registFile: File
}

//전송시 FormData -> JSON 으로 변환 후 전송
//(tel: ref 로 참조 후 직접 추가, AddressSub: state 로 구현)
const CompanyInfo = () => {
    //모달: https://github.com/reactjs/react-moda
    //https://react-bootstrap.github.io/components/modal/
    //[Typescript] FunctionComponent (FC) 사용 줄이기 -> https://woobiblog.com/Javascript/Typescript_FunctionComponent_FC_%EC%82%AC%EC%9A%A9_%EC%A4%84%EC%9D%B4%EA%B8%B0

    let Navigate = useNavigate();
    let [company, setCompany] = React.useState<CompanyData>();
    let [addressSub, setAddressSub] = React.useState<Address[]>(new Array(0));

    const popup = useRef<AddressPopup>(null);
    const form_tel = useRef<HTMLDivElement>(null);

    useEffect(() =>
    {
        //서버에서 데이터 가져오기
        async function Refresh()
        {
            let data = await getCompanyData(Navigate);
            if (data == null) alert("오류가 발생하였습니다");
            else
            {
                //console.log(data);
                setCompany(data);
                //사업장 주소 목록 할당
                if(data.addressSub != null) setAddressSub(data.addressSub);
            }
        }
        Refresh().then();
    }, [])

    const Submit = async (form: HTMLFormElement | null) =>
    {
        if(form != null)
        {
            let data: CompanyDataSend = serializeForm(new FormData(form));
            data.addressMain = company?.addressMain;
            data.addressSub = addressSub;

            await processObjectFile(data);
            console.log(data);
            //데이터 출력
            if(DEBUG) console.log(data);

            let response = await axios({
                method: 'POST',
                url: Define.URL_API_DASHBOARD + "/info/company",
                data: data
            });

            //API 응답 점검
            let api = await API.checkAjaxResponse(Navigate, response);
            if (api != null)
            {
                if(api.result == API.RESULT_SUCCESS) alert("저장되었습니다");
                else alert("실패하였습니다");
            }
        }
    }

    const input_tel = (value: any) => {
        const input = document.createElement("input");
        input.type = "text";
        input.className = "form-control";
        input.placeholder = "'-'를 포함하여 입력해주세요";
        input.value = value;
        return input;
    }

    const form = useRef<HTMLFormElement | null>(null);
    return (
        <div className="page-wrapper">
            <div className="container-xl myinfo">
                <br/>
                <h4 className="card-title">업체정보</h4>
                {
                    company == null
                        ?
                        //TODO: 스켈레톤 삽입 요망
                        <h1>불러오는 중입니다...</h1>
                        :
                        <form ref={form} onSubmit={(event: React.FormEvent<HTMLFormElement>) => event.preventDefault()}>
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">업체정보</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="mb-3" style={{display: "none"}}>
                                                <label className="form-label">회사 ID</label>
                                                <input type="text" className="form-control" disabled/>
                                            </div>

                                            <div className="mb-3">
                                                <label className="form-label">상호</label>
                                                <input type="text" className="form-control"
                                                       name="name"
                                                       placeholder="예) 주식회사 원미디어"
                                                       defaultValue={toInput(company?.name)}
                                                />
                                            </div>

                                            <div className="mb-3">
                                                <label className="form-label">대표자 명</label>
                                                <input type="text" className="form-control"
                                                       name="ceo"
                                                       placeholder="이름"
                                                       defaultValue={toInput(company?.ceo)}
                                                />
                                            </div>

                                            <div className="mb-3">
                                                <label className="form-label">사업자등록번호</label>
                                                <input type="text" className="form-control" maxLength={13}
                                                       name="regist"
                                                       defaultValue={toInput(company?.regist)}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <div className="form-label">사업장등록증사본 첨부</div>
                                                <input type="file" name="registFile" className="form-control"/>
                                                <small className="form-hint">※ jpg, png(5MB이하)의 파일만 첨부 가능</small>
                                                {
                                                    !isDownloadable(company?.registFile) ? null :
                                                        <a href={getDownloadURL(company!.registFile)} download={company!.name}>다운로드</a>
                                                }
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="mb-3">
                                                <label className="form-label">팩스번호</label>
                                                <div className="row">
                                                    <div className="col-12">
                                                        {/* fax 는 string[] 인데 string 여서 오류 */}
                                                        <input type="text" className="form-control"
                                                               placeholder="'-'를 포함하여 입력해주세요"
                                                               name="fax[]"
                                                               defaultValue={toInput(company?.fax?.at(0))}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mb-3">
                                                <div className="row g-2">
                                                    <div className="col-6">
                                                        <label className="form-label" style={{lineHeight: "36px"}}>회사전화</label>
                                                    </div>

                                                    <div className="col-6 ms-auto d-print-none text-end">
                                                        <button className="btn btn_primary bg-primary"
                                                                onClick={()=>
                                                                {
                                                                    form_tel.current?.appendChild<HTMLInputElement>(input_tel(""));
                                                                }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24"
                                                                 height="24" viewBox="0 0 24 24" strokeWidth="2"
                                                                 stroke="currentColor"
                                                                 fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                                <line x1="12" y1="5" x2="12" y2="19"/>ㅑ,ㅏ.
                                                                <line x1="5" y1="12" x2="19" y2="12"/>
                                                            </svg>
                                                            추가
                                                        </button>
                                                    </div>

                                                </div>
                                                <div className="row">
                                                    <div ref={form_tel} className="col-12">
                                                        <input type="text" className="form-control"
                                                               name="tel[]"
                                                               placeholder="'-'를 포함하여 입력해주세요"
                                                               defaultValue={company?.tel?.at(0)}
                                                        />
                                                        {
                                                            company?.tel?.map((item, i) =>
                                                            {
                                                                return i == 0 ? null :
                                                                <input type="text" className="form-control" key={Math.random()}
                                                                       name="tel[]"
                                                                       placeholder="'-'를 포함하여 입력해주세요"
                                                                       defaultValue={item}
                                                                />;
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <label className="form-label">본사 주소</label>
                                            <AddressUI address={company!.addressMain} />
                                            <div className="row">
                                                <div className="col-xl mb-3">
                                                    <button className="btn btn-primary btn-square w-100" onClick={()=>popup.current?.Show(true)}>
                                                        사업장 주소 추가
                                                    </button>
                                                </div>
                                            </div>

                                            <AddressPopup ref={popup}
                                                okCallback={(address, i: number) =>
                                                {
                                                    if(!isEmpty(address))
                                                    {
                                                        // 1. Make a shallow copy of the items
                                                        let items = [...addressSub!];

                                                        if(isEmpty(i)) items.push(address!);
                                                        else items[i] = address!;

                                                        // 5. Set the state to our new copy
                                                        setAddressSub(items);
                                                    }
                                                }}
                                            />

                                            {addressSub?.map((addr, i)=> {
                                                return(
                                                <div className="card" key={Math.random()}>
                                                    <div className="card-body">
                                                        <label className="form-label">{addr.title}</label>
                                                        <a onClick={()=>popup.current?.Show(true, addr, i)}>
                                                            <span className="form-check-label">[{addr.post}] {addr.main}, {addr.detail}</span>
                                                        </a>
                                                    </div>
                                                </div>)
                                            })}
                                        </div>
                                    </div>

                                </div>
                                <div className="card-footer text-end">
                                    <button type="submit" className="btn btn-success btn-pill" onClick={()=>Submit(form?.current)}>내용 저장</button>
                                </div>
                            </div>
                        </form>
                }
                <br/><br/>
            </div>
        </div>
    )
};
export default CompanyInfo;

/*
const AddressSubUI: React.FC<Address> = (addr: Address) =>
{
    return(
        <div className="card" key={Math.random()}>
            <div className="card-body">
                <label className="form-label">{addr.title}</label>
                <a onClick={()=>popup.current?.Show(true, addr, i)}>
                    <span className="form-check-label">[{addr.post}] {addr.main}, {addr.detail}</span>
                </a>
            </div>
        </div>
    )
}
 */

type AddressPopupProps = {
    okCallback?: (address: Address | null, tag: any) => void
}
class AddressPopup extends React.Component<AddressPopupProps>
{
    private title: HTMLInputElement | null;
    private popup: Popup | null;
    private input: AddressInput | null;
    private address = new class implements Address {
        detail?: string;
        main?: string;
        post?: string;
        title?: string;
        tag?: any;
    };

    constructor(props: AddressPopupProps) {
        super(props);

        this.Show = this.Show.bind(this);
        this.SetAddress = this.SetAddress.bind(this);
    }

    private SetAddress(address?: Address | null, tag?: any)
    {
        this.address.tag = tag;
        this.address.detail = address?.detail;
        this.address.main = address?.main;
        this.address.post = address?.post;
        this.address.title = address?.title;

        //텍스트 상자에 할당
        if(this.title != null) this.title.value = isEmptyString(address?.title) ? "" : address!.title!;
        if(this.input != null) this.input.setAddress(address);
    }

    Show(isShow: boolean, address?: Address, tag?: any)
    {
        this.SetAddress(address, tag);
        this.popup?.Show(isShow);
    }

    render()
    {
        return(
            <Popup
                ref = {(ref) => this.popup = ref}
                title={"주소" + (isEmpty(this.address) ? "추가" : "편집")}
                closingCallback={(code: PopupCloseResult) =>
                {
                    if(code == PopupCloseResult.OK || code == PopupCloseResult.YES)
                    {
                        //필수 값 검사
                        if(isEmptyString(this.address.title))
                        {
                            //나중에는 빨간 텍스트로 바꾸기
                            alert("사업장 주소 이름은 필수사항입니다");
                            return false;
                        }
                        if(isEmptyString(this.address.post) || isEmptyString(this.address.main))
                        {
                            alert("주소찾기를 해주십시오");
                            return false;
                        }

                        //새 인스턴스로 복제
                        if(this.props.okCallback != null) this.props.okCallback({
                            post: this.address.post,
                            main: this.address.main,
                            detail: this.address.detail,
                            title: this.address.title,
                        }, this.address.tag);
                    }

                    return true;
                }}
                closedCallback={(code) => console.log(code)}>
                <div className="mb-2">
                    <label className="form-label">사업장 주소 이름</label>
                    <input ref={(ref) => this.title = ref}
                           type="text" className="form-control"
                           defaultValue={this.address.title}
                           onChange={(e) => this.address.title = e.currentTarget.value}/>
                </div>
                <AddressUI ref={(ref) => this.input = ref}
                           address={this.address} />
            </Popup>
        )
    }
}