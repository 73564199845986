import { NavigateFunction, Params, Location } from "react-router-dom";
import * as UI from "../../Utils/UI";
import * as Define from "../../Define";
import * as API from "../../API";
import * as HSUtils from "../../Utils/HSUtils";
import axios, { AxiosResponse } from "axios";

export type BoardInit = {
    Type: string,
    Kind: number,
    Title: string,
    TitlePre: string,
}

export type BoardFile = {
    name: string,
    size: number,
    path: string,
    key: string,
}

export type View = {
    id: number;
    title: string;
    timestamp: string;
    view: number;
    user_id: string;
    user_name: string;
    contents: string;
    reply_count: number;
    priority: number;
    deletable: boolean;
    editable: boolean;
    files: BoardFile[];
};


export async function getBoardView(Navigate: NavigateFunction, ID: string, Init: BoardInit): Promise<View | null>
{
    if (HSUtils.isEmptyString(ID)) UI.move(Navigate!, Define.URL_ERROR_404);
    else
    {
        try
        {
            let response: AxiosResponse = await axios.get(Define.URL_API_DASHBOARD + `/board/kind/${Init.Type}/${Init.Kind}/${ID}`);
            return await API.checkAjaxValue(Navigate!, response);
        }
        catch (ex) { API.checkError(Navigate!, ex); }
    }

    return null;
}