import React, {useEffect, useRef} from "react";
import {useNavigate, useParams} from "react-router";
import * as HSUtils from "../../../../Utils/HSUtils";
import * as Define from "../../../../Define";
import * as UI from "../../../../Utils/UI";
import {getBoardView} from "../../../../store/Board/Board";
import {Props, State} from "../../../../store/Board/BoardWrite";
import Loading from "../../../Common/Loading";
import Title from "../Common/Title";
import FormTitle from "./Form/FormTitle";
import FormFile from "./Form/FormFile";
import FormContents from "./Form/FormContents";
import FormSubmit from "./Form/FormSubmit";
import FormGoBack from "./Form/FormGoBack";

const WriteComponent: React.FC<Props> = (props: Props) => {
    let ID: string = useParams().id as string;
    let Form = useRef<HTMLFormElement>(null);

    let [state, setState] = React.useState<State>({
        isLoading: !HSUtils.isEmpty(ID)
    });
    let Navigate = useNavigate();

    useEffect(() =>
    {
        async function Refresh() {
            let view = await getBoardView(Navigate, ID, props.Init);
            if (view != null) setState({
                isLoading: false,
                Contents: view
            });
            else
            {
                Navigate(Define.URL_ERROR_404, {replace: true});
            }

            return() =>
            {
              //TODO: 컴포넌트 언마운트 되기 전 저장할지말지 메세지 띄우기
                alert("UNMOUNT")
            };
        }

        if (!HSUtils.isEmptyString(ID)) Refresh().then();
    }, []);

    return (
        <div className="page-wrapper" id="page-top-margin">
            <div className="container-xl">
                <div className="page-header">
                    <Title {...props.Init}/>
                </div>

                <Loading show={state.isLoading}>
                    <div className="card" style={{marginTop: "10px"}}>
                        <form className="card-body" ref={Form}>
                            <input type="hidden" name="id" value={ID}/>
                            <div className="mb-3"><FormTitle name="title" value={state.Contents?.title}/></div>
                            <div className="mb-3"><FormContents name="contents" value={state.Contents?.contents}/></div>
                            <div className="mb-3"><FormFile name="files[]" value={state.Contents?.files}/></div>
                        </form>

                        <div className="card-footer">
                            <div className="row align-items-center">
                                <div className="col-3"><FormGoBack/></div>
                                <div className="col-9"><FormSubmit refValue={Form} init={props.Init}/></div>
                            </div>
                        </div>
                    </div>
                </Loading>
            </div>
            <br/>
        </div>
    );
}

export default WriteComponent;