import React from "react";
import * as HSUtils from "../../../../../Utils/HSUtils";
import * as API from "../../../../../API";
import * as UI from "../../../../../Utils/UI";
import * as BoardList from "../../../../../store/Board/BoardList";
import * as TYPE from "../../TYPE";
import {Props, Data, deleteItems, ListInit, Param} from "../../../../../store/Board/BoardList";
import {BoardInit} from "../../../../../store/Board/Board";

export default abstract class List extends React.Component<Props, IBoardList>
{
    constructor(props: Props)
    {
        super(props);

        //이거 없으면 this 가 null 이 됨
        this.Refresh = this.Refresh.bind(this);
        this.Delete = this.Delete.bind(this);
        this.RenderCheckAll = this.RenderCheckAll.bind(this);
        this.RenderCheck = this.RenderCheck.bind(this);

        this.state =
        {
            Props: this.props,
            data: {
                count: 0,
                keyword: null,
                page: -1
            },

            init: this.props.Init,
            checked: new Array<number>(),
            onRefresh: this.Refresh,
            Delete: this.Delete,
        }

        window.onpopstate = async (event) => await this.Refresh();
    }

    async componentDidMount() { await this.Refresh(); }

    async Refresh(param?: Param[], keyword: string|null = null, page: number = 0, force: boolean = false): Promise<ListInit | undefined>
    {
        return await this.RefreshParam(param, param, keyword, page, force);
    }
    async RefreshParam(paramCount?: Param[], paramList?: Param[], keyword: string|null = null, page: number = 0, force: boolean = false): Promise<ListInit | undefined>
    {
        try
        {
            let searchParams = new URLSearchParams(window.location.search);

            let Keyword = HSUtils.isEmptyString(keyword) ? HSUtils.convertToString(searchParams.get('keyword'), "") : keyword;
            let Page = page > 0 ? page : Math.max(HSUtils.convertToInt(searchParams.get('page'), 1), 1);

            let params_count: Param[] = HSUtils.isEmpty(paramCount) ? [] : paramCount!;
            let params_list: Param[] = HSUtils.isEmpty(paramList) ? [] : paramList!;
            let count: number;
            let list: Data[];
            //공지사항 일때
            if(this.props.Init.Type === TYPE.Notice)
            {
                //공지사항까지도 검색대상에 포함하는지의 여부
                const SEARCH_NOTICE = false;

                let param_priority = {key: "priority", value: 0};
                params_count.push(param_priority);
                params_list.push(param_priority);
                count = (await BoardList.getBoardCount(this.props, Keyword, params_count)).count;
                list = await BoardList.getBoardList(this.props, SEARCH_NOTICE ? Keyword : null, 1, [{key: "priority", value: 1}]);

                let data = await BoardList.getBoardList(this.props, Keyword, Page, params_list);
                for (let i = 0; i < data?.length; i++) list.push(data[i]);
            }
            else
            {
                count = (await BoardList.getBoardCount(this.props, Keyword, params_count)).count;
                list = await BoardList.getBoardList(this.props, Keyword, Page, params_list);
            }

            let data: ListInit = {
                keyword: Keyword,
                page: Page,
                list: list,
                count: count
            }

            this.setState({data: data}); // data 의 값이 업데이트 되도록 요청을 한다.

            if(force)
            {
                window.location.reload();
            }

            return data;
        }
        catch (ex) { API.checkError(this.props.Navigate!, ex); }
    }

    //전체체크 설정/해제 생성
    RenderCheckAll()
    {
        return !this.props.Delete ? null : (
            <input type="checkbox" id="area_check_all" className="form-check-input m-0 align-middle" aria-label="모두 선택" onChange={
                (event) =>
                {
                    let items: number[] = [];
                    if (event.target.checked) this.state.data.list?.forEach((data: Data) => items.push(data.id));
                    this.setState({ checked: items });
                }}
            />
        );
    }

    //체크박스 생성
    RenderCheck(id: number)
    {
        let items = this.state.checked;

        return !this.props.Delete ? null : (
            <input type="checkbox" className="form-check-input m-0 align-middle" aria-label="선택" checked={items.includes(id)} onChange={(event) => {
                if (event.target.checked) items.push(id);
                else this.state.checked.splice(items.indexOf(id, 0), 1);

                this.setState({ checked: items });
            }} />
        );
    }

    Delete()
    {
        UI.confirm("정말 삭제하시겠습니까?", "선택한 글 ID: " + this.state.checked.join(', '), async () =>
        {
            try
            {
                if (!await deleteItems(this.props, this.state.checked)) alert("삭제하지 못했습니다");
                window.location.reload();
            }
            catch (ex: any) { API.checkError(this.props.Navigate!, ex); }
        });
    }
}


//Error: Property 'data' is missing in type '{}' but required in type
//https://stackoverflow.com/questions/54735561/property-profilestore-is-missing-in-type-but-required-in-type-readonlya
export interface IBoardList
{
    Props: Props;
    init: BoardInit;
    data: ListInit;
    onRefresh: Function;
    Delete: Function;
    checked: number[];
}