import React from "react";

function skeletonList() {
    let arrayList = [];
    for (let i = 0; i < 10; i++) {
        arrayList.push(
            <tr>
                <td className="w-1"/>
                <td className="w-1" style={{textAlign: "center"}}>
                    <div className="text-muted placeholder col-12">???</div>
                </td>
                <td>
                    <div className="placeholder col-12">?????????????????????</div>
                </td>
                <td style={{textAlign: "center", width: "120px"}}>
                    <div className="placeholder col-12">????</div>
                </td>
                <td className="w-3" style={{textAlign: "center"}}>
                    <div className="placeholder col-12">????-??-??</div>
                </td>
            </tr>
        );
    }
    return arrayList;
}

export default skeletonList;