//require('dotenv').config();
import { BrowserStorage } from "./lib/BrowserStorage";
import { MemberData, MemberDataMapper } from "./Struct/MemberData";

export const BOARD_MAX = 10;

//export const URL_DASHBOARD_ = process.env.ENV === "development" ? "http://localhost:26840" : "https://dashboard.ihongju.com";
//alert(URL_DASHBOARD_);
export const URL_DASHBOARD = "";
export const URL_API = URL_DASHBOARD + "/api";
export const URL_API_DASHBOARD = URL_API + "/dashboard/v1";
export const URL_API_DOWNLOAD = URL_API_DASHBOARD + "/io/download";

export const URL_FRONT_MAIN = "/";
export const URL_FRONT_LOGIN = "/login";
export const URL_FRONT_LOGOUT = "/logout";

export const URL_ERROR_404 = "/error/404";
export const URL_ERROR_403 = "/error/403";
export const URL_ERROR_500 = "/error/500";

export function printAjaxFailMessage(jqXHR: XMLHttpRequest, exception_code: string)
{
    if (jqXHR.status === 0) return '네트워크에 연결되어있지 않습니다';
    else if (jqXHR.status === 404) return '페이지를 찾을 수 없습니다';
    else if (jqXHR.status === 500) return "서버 오류가 발생했습니다";
    else if (exception_code === 'parsererror') return "서버에서 잘못된 값을 반환했습니다";
    else if (exception_code === 'timeout') return "요청시간을 초과하였습니다";
    else if (exception_code === 'abort')  return "서버에 요청 중 중단되었습니다";
    else return "알 수 없는 오류가 발생하였습니다. \n\n" + jqXHR.responseText;
}

export function getAbsPath(URL: string): string
{
    //PathMaker
    /*
    URL = URL.startsWith('/', 0) ? `${window.location.pathname}#${URL}` : window.location.href + URL;

    console.log(process.env)
    console.log(window.location.pathname);
    console.log(window.location.href);
    console.log(URL);
    */
    //console.log(path.join(__dirname, URL));
    //URL = path.join(__dirname, URL);
    return URL;
}

export const POLICY_PW = "^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,}$";



export const STORAGE_KEY_MEMBER = "STORAGE_KEY_MEMBER";

const STORAGE_MEMBER = new BrowserStorage(STORAGE_KEY_MEMBER, new MemberDataMapper());
export function getStorageMember(): MemberData | null { return STORAGE_MEMBER.get(); }
export function setStorageMember(Member: MemberData | null): void { if (Member === null) STORAGE_MEMBER.clear(); else STORAGE_MEMBER.set(Member, false); }

export function clearStorage()
{
    localStorage.clear();
    sessionStorage.clear();
}
