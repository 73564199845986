import * as HSUtils from "../../../../../Utils/HSUtils";
import { CommonProps } from "../../../../../store/Board/BoardWrite";

export interface TitleInit extends CommonProps {
    value?: string | null,
}

const FormTitle: React.FC<TitleInit> = ({ name, value }) => {
    return (
        <>
            <label className="form-label">제목</label>
            <input type="text" name={name} className="form-control" placeholder="제목을 입력하시오." defaultValue={HSUtils.isEmpty(value) ? "" : value!}
                style={{
                    backgroundImage: "url(&quot;data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABHklEQVQ4EaVTO26DQBD1ohQWaS2lg9JybZ+AK7hNwx2oIoVf4UPQ0Lj1FdKktevIpel8AKNUkDcWMxpgSaIEaTVv3sx7uztiTdu2s/98DywOw3Dued4Who/M2aIx5lZV1aEsy0+qiwHELyi+Ytl0PQ69SxAxkWIA4RMRTdNsKE59juMcuZd6xIAFeZ6fGCdJ8kY4y7KAuTRNGd7jyEBXsdOPE3a0QGPsniOnnYMO67LgSQN9T41F2QGrQRRFCwyzoIF2qyBuKKbcOgPXdVeY9rMWgNsjf9ccYesJhk3f5dYT1HX9gR0LLQR30TnjkUEcx2uIuS4RnI+aj6sJR0AM8AaumPaM/rRehyWhXqbFAA9kh3/8/NvHxAYGAsZ/il8IalkCLBfNVAAAAABJRU5ErkJggg==&quot;)",
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "scroll",
                    backgroundSize: "16px 18px",
                    backgroundPosition: "98% 50%",
                    cursor: "auto"
                }} />
        </>
    )
}

export default FormTitle;