function Loginhistory() {
    return (
        <div className="page-wrapper">
            <div className="container-xl myinfo">
                <br/>
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">접속기록</h3>
                    </div>
                    <div className="card-table table-responsive">
                        <table className="table table-vcenter">
                            <thead>
                            <tr>
                                <th style={{fontSize: "15px"}}>접속 IP</th>
                                <th style={{fontSize: "15px"}}>접속시간</th>
                                <th style={{fontSize: "15px"}}>접속여부</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                    000.00.0.00

                                </td>
                                <td className="text-muted">2021-12-01 오후 1:34:30</td>
                                <td><span className="badge bg-success me-1"></span>성공</td>
                            </tr>
                            <tr>
                                <td>
                                    000.00.0.00
                                </td>
                                <td className="text-muted">2021-12-01 오후 1:34:30</td>
                                <td><span className="badge bg-success me-1"></span>성공</td>
                            </tr>
                            <tr>
                                <td>
                                    000.00.0.00
                                </td>
                                <td className="text-muted">2021-12-01 오후 1:34:30</td>
                                <td><span className="badge bg-success me-1"></span>성공</td>
                            </tr>
                            <tr>
                                <td>000.00.0.00
                                </td>
                                <td className="text-muted">2021-12-01 오후 1:34:30</td>
                                <td><span className="badge bg-success me-1"></span>성공</td>
                            </tr>
                            <tr>
                                <td>000.00.0.00
                                </td>
                                <td className="text-muted">2021-12-01 오후 1:34:30</td>
                                <td><span className="badge bg-success me-1"></span>성공</td>
                            </tr>
                            <tr>
                                <td>000.00.0.00
                                </td>
                                <td className="text-muted">2021-12-01 오후 1:34:30</td>
                                <td><span className="badge bg-warning me-1"></span>실패</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Loginhistory