import React from "react";
import * as HSUtils from "../../../../../Utils/HSUtils";
import {isEmpty, isEmptyString} from "../../../../../Utils/HSUtils";

export type BoardInfo =
{
    priority?: number | null,
    id?: string | null;
    title?: string | null;
    timestamp?: string | null;
    view?: string | null;
    user_name?: string | null;
}

const ViewTitle: React.FC<BoardInfo> = (props: BoardInfo) => {
    if (isEmpty(props)) props = {
        priority: 0,
        id: "?",
        title: "?",
        timestamp: "?",
        view: "?",
        user_name: "?"
    }

    return (
        <div className="card-header" style={{display: "block"}}>
            <div className="card-title" style={{marginBottom: "13px"}}>
                <h2><span style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    marginRight: "10px"
                }}>
                    {HSUtils.isEmpty(props.priority) || props.priority! < 1 ? `No.${props.id}` : <img src='/resources/board/images/notice_badge.png' alt="공지사항"/>}
                </span>{props.title}</h2>
            </div>

            <div className="card_subtitle row">
                {isEmptyString(props.user_name) ? null :
                    <div className="col-auto" style={{fontSize: "13px"}}>

                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                             viewBox="0 0 24 24"
                             strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round"
                             strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M18 8a3 3 0 0 1 0 6"/>
                            <path d="M10 8v11a1 1 0 0 1 -1 1h-1a1 1 0 0 1 -1 -1v-5"/>
                            <path
                                d="M12 8h0l4.524 -3.77a0.9 .9 0 0 1 1.476 .692v12.156a0.9 .9 0 0 1 -1.476 .692l-4.524 -3.77h-8a1 1 0 0 1 -1 -1v-4a1 1 0 0 1 1 -1h8"/>
                        </svg>
                        &nbsp;
                        {props.user_name}
                        <span style={{width: "20px", display: "inline-block"}}/>
                    </div>
                }
                <div className="col-auto" style={{fontSize: "13px"}}>
                    <svg xmlns="http://www.w3.org/2000/svg"
                         className="icon icon-tabler icon-tabler-calendar" width="24" height="24"
                         viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                         strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <rect x="4" y="5" width="16" height="16" rx="2"/>
                        <line x1="16" y1="3" x2="16" y2="7"/>
                        <line x1="8" y1="3" x2="8" y2="7"/>
                        <line x1="4" y1="11" x2="20" y2="11"/>
                        <line x1="11" y1="15" x2="12" y2="15"/>
                        <line x1="12" y1="15" x2="12" y2="18"/>
                    </svg>
                    &nbsp;
                    {props.timestamp?.substring(0, 4)}년&nbsp;
                    {props.timestamp?.substring(5, 7)}월&nbsp;
                    {props.timestamp?.substring(8, 10)}일
                    <span style={{width: "20px", display: "inline-block"}}/>
                </div>

                {isEmptyString(props.view) ? null :
                    <div className="col-auto" style={{fontSize: "13px"}}>
                        <svg xmlns="http://www.w3.org/2000/svg"
                             className="icon icon-tabler icon-tabler-eye"
                             width="24" height="24" viewBox="0 0 24 24" strokeWidth="2"
                             stroke="currentColor" fill="none" strokeLinecap="round"
                             strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <circle cx="12" cy="12" r="2"/>
                            <path
                                d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"/>
                        </svg>
                        &nbsp;
                        {props.view}
                    </div>
                }
            </div>
        </div>
    )
}


export default ViewTitle;