import * as HSUtils from "../../Utils/HSUtils";
import * as API from "../../API";
import * as Define from "../../Define";
import * as CODE from "../../API/Code/APICodeAuth";
import axios from "axios";
import { NavigateFunction } from "react-router-dom";
declare const window: any;


export const ORIGIN = window.location.origin;
export const CALLBACK_NAVER = `${ORIGIN}${Define.URL_DASHBOARD}/login/callback/naver`;
export const DIV_ROOT = "login_root";

export const KIND_GOOGLE = "google";
export const KIND_KAKAO = "kakao";
export const KIND_NAVER = "naver";


export function alertError(msg: string | null, prefix: string = "") { alert(prefix + "오류가 발생하였습니다" + (HSUtils.isEmptyString(msg) ? "" : "\n\n" + msg)) }
export function getRedirect(): string | null
{
    let searchParams = new URLSearchParams(window.location.search);
    let redirect = searchParams.get('redirect');
    return redirect === null ? null : decodeURIComponent(redirect);
}

export async function LoginSuccess(Navigate: NavigateFunction, Redirect?: string | null)
{
    //적용
    await API.ApplyMember(Navigate);

    //메인으로 이동
    if (HSUtils.isEmpty(Redirect)) Redirect = getRedirect();
    Redirect = HSUtils.isEmptyString(Redirect) ? Define.URL_FRONT_MAIN : decodeURI(Redirect!);

    //Navigate(Redirect);
    //window.location.reload();
    window.location.href = Redirect;
}

export async function LoginOAuth(Navigate: NavigateFunction, Kind: string, Token: string, TokenType: string | null = null, RefreshToken: string | null = null): Promise<boolean>
{
    const param = HSUtils.StringFormat("Token={0}&TokenType={1}&RefreshToken={2}",
        encodeURIComponent(Token),
        encodeURIComponent(HSUtils.isEmptyString(TokenType) ? "" : TokenType!),
        encodeURIComponent(HSUtils.isEmptyString(RefreshToken) ? "" : RefreshToken!));

    try
    {
        const response = await axios.post(Define.URL_API_DASHBOARD + "/login/oauth/" + Kind, param);
        const json = response.data as API.APIData;

        if (json.result === API.RESULT_SUCCESS) { await LoginSuccess(Navigate); return true; }
        else if (json.code === CODE.ERR_AUTH_NOT_MEMBER)
        {
            if (window.confirm("저희 회원이 아닙니다. 회원가입을 하시겠습니까?"))
            {
                Navigate(Define.URL_FRONT_LOGIN + "/join?data=" + btoa(encodeURIComponent(JSON.stringify(json.data))), {replace: true});
                return true;
            }
        }
        else alertError(response.data.message, "서버에 로그인 중 ");
    }
    catch (ex: any) { alertError(ex.message, "서버에 로그인 중 ") }

    return false;
}

export function kakaoLogin(Navigate: NavigateFunction)
{
    let Kakao = window.Kakao;
    if (HSUtils.isEmpty(Kakao)) alert("카카오 소셜로그인 모듈을 불러오지 못했습니다")
    else {
        Kakao.Auth.login({
            success: (response: any) => LoginOAuth(Navigate, KIND_KAKAO, response.access_token, response.token_type, response.refresh_token),
            /*
                Kakao.API.request({
                    url: '/v2/user/me',
                    success: function (response){  },
                    fail: function (error) {
                        console.log(error)
                    },
                })
             */
            fail: function (error: any) {
                console.log(error)
            },
        })
    }
}

export async function checkNaver(Navigate: NavigateFunction, naverLogin: any): Promise<boolean>
{
    var accessToken = naverLogin.loginStatus.accessToken.accessToken;
    var token_type = "Bearer";//naverLogin.loginStatus.token_type;

    var email = naverLogin.user.getEmail();
    if (HSUtils.isEmptyString(email))
    {
        alert("이메일은 필수정보입니다. 정보제공을 동의해주세요.");
        /* (5-1) 사용자 정보 재동의를 위하여 다시 네아로 동의페이지로 이동함 */
        naverLogin.reprompt();

        return true;
    }
    else return await LoginOAuth(Navigate, KIND_NAVER, accessToken, token_type);
}